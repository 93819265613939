import React from 'react';
import { Paper, Typography, List, ListItem, ListItemAvatar, Avatar, ListItemText } from '@mui/material';
import { green } from '@mui/material/colors';

// Sample data with different user names and avatars
const users = [
    { name: 'Sandeep', orders: 120, revenue: 20000.00, avatar: 'https://randomuser.me/api/portraits/men/1.jpg' },
    { name: 'Meghana', orders: 115, revenue: 18500.25, avatar: 'https://randomuser.me/api/portraits/women/2.jpg' },
    { name: 'Tamil Selvan', orders: 110, revenue: 17050.75, avatar: 'https://randomuser.me/api/portraits/men/3.jpg' },
    { name: 'Gowsalya', orders: 108, revenue: 16000.40, avatar: 'https://randomuser.me/api/portraits/women/4.jpg' },
    { name: 'Shifan', orders: 102, revenue: 15000.00, avatar: 'https://randomuser.me/api/portraits/men/5.jpg' },
];

const DashBoardTopUsers = () => {
    return (
        <Paper sx={{ maxWidth: 400, borderRadius: 2, padding: 2 }} elevation={0}>
            <Typography variant="h6" gutterBottom>
                Top Users
            </Typography>
            <List>
                {users.map((user, index) => (
                    <ListItem key={index} disableGutters>
                        <ListItemAvatar>
                            <Avatar src={user.avatar} alt={user.name} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={user.name}
                            secondary={`Orders: ${user.orders}`}
                        />
                        <Typography variant="body2" sx={{ color: green[500], fontWeight: 'bold' }}>
                            ₹ {user.revenue.toFixed(2)}
                        </Typography>
                    </ListItem>
                ))}
            </List>
        </Paper>
    );
};

export default DashBoardTopUsers;
