// catererSupportApis.js
import baseUrl from "../../config";

export const fetchCatererSupportDetails = async () => {
    const url = `${baseUrl}catsupport`; // Update this URL if necessary.

    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            throw new Error(`Error fetching data: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error in fetchCatererSupportDetails:", error);
        return null; // or handle error appropriately
    }
};
