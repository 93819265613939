// Import necessary modules
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, Tooltip, Typography, Paper, Button, Container } from '@mui/material';
import DataTable from '../../../components/DataTables/DataTable';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import ConfirmDialog from '../../../components/ResuableComponents/ConfirmDialog';
import CustomSnackbar from '../../../components/CustomeSnackBar/CustomSnackbar';
import { deleteSubPackage } from '../../../api/catererPackages/catererSubPackages';
import baseUrl from '../../../config';

const SubPackagesPage = () => {
    const [tableData, setTableData] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedSubPackageId, setSelectedSubPackageId] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${baseUrl}menu/subPackages`);
                if (response.ok) {
                    const data = await response.json();

                    // Adding index to each subPackageItem
                    const transformedData = data.map((subPackageItem, index) => ({
                        id: subPackageItem._id,
                        siNo: index + 1, // Adding index (starting from 1)
                        subPackageName: subPackageItem.subPackageName,
                        subPackageImage: subPackageItem.subPackageImages[0] || 'N/A',
                        packageAllocatedTo: subPackageItem.allocatedTo,
                        packageType: subPackageItem.type[0] || 'N/A',
                        createdAt: new Date(subPackageItem.createdAt).toLocaleDateString(),
                    }));

                    setTableData(transformedData);
                } else {
                    console.error('Failed to fetch data:', response.statusText);
                    setTableData([]);
                }
            } catch (error) {
                console.error('Failed to fetch data:', error);
                setTableData([]);
            }
        };

        fetchData();
    }, []);


    const handleAddClick = () => {
        navigate('/add-caterer-sub-packages');
    };

    const handleEditClick = (id) => {
        navigate(`/edit-subpackages/${id}`);
    };

    const handleViewClick = (id) => {
        navigate(`/view-subpackage-details/${id}`);
    };

    const handleDeleteClick = (id) => {
        setSelectedSubPackageId(id);
        setDialogOpen(true);
    };

    const handleConfirmDelete = async () => {
        try {
            await deleteSubPackage(selectedSubPackageId);
            setTableData(prevData => prevData.filter(item => item.id !== selectedSubPackageId));
            setSnackbarMessage('SubPackage deleted successfully');
            setSnackbarSeverity('success');
        } catch (error) {
            setSnackbarMessage('Failed to delete subpackage');
            setSnackbarSeverity('error');
            console.error('Failed to delete subpackage:', error);
        } finally {
            setDialogOpen(false);
            setSelectedSubPackageId(null);
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <div style={{ backgroundColor: '#f2f2f2' }}>
            <Typography variant='h4' fontWeight='bolder' gutterBottom>
                SubPackages
            </Typography>
            <Paper elevation={0} sx={{ padding: '14px', backgroundColor: '#fff' }}>
                <Container sx={{ minWidth: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Button
                            variant="contained"
                            color="success"
                            startIcon={<AddIcon />}
                            onClick={handleAddClick}
                        >
                            Add SubPackage
                        </Button>
                    </div>
                    <DataTable
                        rows={tableData}
                        columns={[
                            { field: 'siNo', headerName: 'ID', minWidth:10, sortable: true },
                            {
                                field: 'subPackageImage',
                                headerName: 'Sub Package Image',
                                flex: 1,
                                sortable: false,
                                minWidth: 150,
                                renderCell: (params) => (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',   // Horizontally center the image
                                            alignItems: 'center',       // Vertically center the image
                                            width: '100%',              // Ensure the div takes full width of the cell
                                            height: '100%',             // Ensure the div takes full height of the cell
                                        }}
                                    >
                                        <img
                                            src={params.value !== 'N/A' ? params.value : 'placeholder-image-url.jpg'} // Placeholder for broken images
                                            alt="Item"
                                            style={{
                                                width: '100px',        // Fixed width
                                                height: '60px',        // Fixed height
                                                borderRadius: '4px',   // Rounded corners
                                                objectFit: 'cover',     // Cover the area while maintaining aspect ratio
                                            }}
                                        />
                                    </div>
                                ),
                            },
                            { field: 'subPackageName', headerName: 'Sub Package Name', flex: 1, sortable: true, renderCell: (params) => (
                                <div
                                    onClick={() => handleViewClick(params.row.id)} // Navigate to package details page on click
                                    style={{
                                        cursor: 'pointer',
    
                                    }}
                                >
                                    {params.value} {/* Display the package name */}
                                </div>
                            )},
                            { field: 'packageAllocatedTo', headerName: 'Allocated To', flex: 1, sortable: true },
                            { field: 'packageType', headerName: 'Package Type', flex: 1, sortable: true },
                            { field: 'createdAt', headerName: 'Created At', flex: 1, sortable: true },
                            {
                                field: 'Actions',
                                headerName: 'Actions',
                                minWidth: 160,
                                sortable: false,
                                renderCell: (params) => (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',   // Horizontally center the items
                                            alignItems: 'center',       // Vertically center the items
                                            width: '100%',              // Ensure the div takes full width of the cell
                                            height: '100%',             // Ensure the div takes full height of the cell
                                        }}
                                    >                                        <Tooltip title="View">
                                            <IconButton sx={{ color: '#87CEEB', marginRight: '8px' }} onClick={() => handleViewClick(params.row.id)}>
                                                <VisibilityIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Edit">
                                            <IconButton color="success" sx={{ marginRight: '8px' }} onClick={() => handleEditClick(params.row.id)}>
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Delete">
                                            <IconButton color="error" onClick={() => handleDeleteClick(params.row.id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                ),
                            },
                        ]}
                    />
                </Container>
            </Paper>
            <ConfirmDialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                onConfirm={handleConfirmDelete}
                title="Delete SubPackage"
                content="Are you sure you want to delete this subpackage? This action cannot be undone."
            />
            <CustomSnackbar
                open={snackbarOpen}
                message={snackbarMessage}
                severity={snackbarSeverity}
                onClose={handleSnackbarClose}
            />
        </div>
    );
};

export default SubPackagesPage;
