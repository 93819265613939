import React from 'react';
import { Box, Checkbox } from '@mui/material';
import PayoutTable from '../../../DataTables/PayoutTables/PayoutTable';

const FessteMartPayoutTable = ({ orders, selectedOrder, onCheckboxChange }) => {
    // Transform the orders array into a format suitable for the table
    const transformedOrders = orders.map((order) => ({
        id: order._id,
        invoiceId: order.invoiceId,
        serviceProvider: order.serviceProviderId?.spName || 'N/A', // Handle missing service provider name
        businessName: order.fessteMartId?.businessName || 'N/A', // Handle missing business name
        amount: order.amount, // Format amount to 2 decimal places
        payoutDate: new Date(order.payoutDate).toLocaleDateString(), // Format payout date
        status: order.status,
        paymentMethod: order.paymentMethod,
        fessteFee: order?.fessteFee, // Include additional fields like fessteFee if needed
        gstOnFessteFee:order?.gstOnFessteFee, // Include GST on Fesste Fee if required
        transactionFee: order?.transactionFee, // Include transaction fee
    }));

    // Define columns for the table
    const columns = [
        { field: 'invoiceId', headerName: 'Invoice ID',minWidth:160 },
        { field: 'serviceProvider', headerName: 'Service Provider', minWidth:200 },
        { field: 'businessName', headerName: 'Business Name', minWidth:200 },
        { field: 'amount', headerName: 'Amount', minWidth:200 },
        { field: 'payoutDate', headerName: 'Payout Date', minWidth:200 },
        { field: 'status', headerName: 'Status', minWidth:200 },
        { field: 'paymentMethod', headerName: 'Payment Method', minWidth:200 },
        { field: 'fessteFee', headerName: 'Fesste Fee', minWidth:200 }, // Fesste Fee column
        { field: 'gstOnFessteFee', headerName: 'GST on Fesste Fee', minWidth:200 }, // GST on Fesste Fee column
        { field: 'transactionFee', headerName: 'Transaction Fee', minWidth:200 }, // Transaction Fee column
        {
            field: 'pay',
            headerName: 'Pay',
            renderCell: (params) => (
                <Checkbox
                    color="primary"
                    checked={selectedOrder?._id === params.row.id}
                    onChange={() => onCheckboxChange(params.row)}
                />
            ),
            flex: 0.5,
        },
    ];
    
    return (
        <Box style={{ height: 400, width: '100%' }}>
            <PayoutTable
                rows={transformedOrders} 
                columns={columns}
                getRowId={(row) => row.id}
                pageSize={5}
                disableSelectionOnClick
                autoHeight
            />
        </Box>
    );
};

export default FessteMartPayoutTable;
