import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, styled, Paper, Button, Chip, IconButton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AdminDrawer from '../../components/AdminDrawer/AdminDrawer';
import AdvancedDataTable from '../../components/DataTables/AdvancedDataTable';
import avatarWomen from '../../assets/avatarwomen.jpg';

const TableContainer = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    maxHeight: '100vh',
    height: '650px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[0],
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
        height: 'auto',
    },
}));

const ButtonContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
}));

const data = [
    {
        id: 1, Name: "Rajesh Kumar", Email: "rajesh.kumar@delhi.com", Phone: "9876543210", Address: "101, Sector 15, Noida, Uttar Pradesh, India", status: "Active", Photo: avatarWomen
    },
    { id: 2, Name: "Priya Sharma", Email: "priya.sharma@mumbai.com", Phone: "9876543211", Address: "202, Marine Drive, Mumbai, Maharashtra, India", status: "Inactive", Photo: avatarWomen },
    { id: 3, Name: "Amit Patel", Email: "amit.patel@ahmedabad.com", Phone: "9876543212", Address: "303, Ashram Road, Ahmedabad, Gujarat, India", status: "Active", Photo: avatarWomen },
    { id: 4, Name: "Sita Rani", Email: "sita.rani@bengaluru.com", Phone: "9876543213", Address: "404, MG Road, Bengaluru, Karnataka, India", status: "Active", Photo: avatarWomen },
    { id: 5, Name: "Amitabh Bachchan", Email: "amitabh.bachchan@kolkata.com", Phone: "9876543214", Address: "505, Park Street, Kolkata, West Bengal, India", status: "Inactive", Photo: avatarWomen },
    { id: 6, Name: "Neha Gupta", Email: "neha.gupta@chennai.com", Phone: "9876543215", Address: "606, Anna Salai, Chennai, Tamil Nadu, India", status: "Active", Photo: avatarWomen },
];



const DeliveryPartners = () => {
    const navigate = useNavigate();

    const handleAddDeliveryPartnerClick = () => {
        navigate('/add-delivery-partners');
    };

    const columns = [
        { field: 'id', headerName: 'Partner ID', flex: 0.7, sortable: false },
        {
            field: 'Photo',
            headerName: 'Photo',
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <img
                    src={params.value}
                    alt="Item"
                    style={{
                        width: '45px',
                        height: '45px',
                        borderRadius: '50%', // Makes the image circular
                        objectFit: 'cover',  // Ensures the image covers the circle without distortion
                    }}
                />
            ),
        },
        { field: 'Name', headerName: 'Delivery Partner Name', flex: 1.5, sortable: true },
        { field: 'Email', headerName: 'Email', flex: 1.5, sortable: true },
        { field: 'Phone', headerName: 'Phone Number', flex: 1, sortable: true },
        { field: 'Address', headerName: 'Address', flex: 2, sortable: true },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            sortable: true,
            renderCell: (params) => (
                <Chip
                    label={params.value}
                    color={params.value === "Active" ? "success" : "warning"}
                />
            ),
        },
        {
            field: 'Actions',
            headerName: 'Actions',
            flex: 1,
            sortable: false,
            renderCell: () => (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Tooltip title="View">
                        <IconButton color="default">
                            <VisibilityIcon onClick={() => navigate('/add-delivery-partners')} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit">
                        <IconButton color="default">
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <IconButton color="default">
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            ),
        },
    ];

    return (
        <div style={{ backgroundColor: '#f2f2f2', minHeight: '100vh' }}>
            <AdminDrawer>
                <Typography variant='h4' fontWeight='bolder'>Delivery Partners</Typography>
                <TableContainer>
                    <ButtonContainer>
                        <Button
                            variant="contained"
                            color="success"
                            startIcon={<AddIcon />}
                            onClick={handleAddDeliveryPartnerClick}
                        >
                            Add Delivery Partner
                        </Button>
                    </ButtonContainer>
                    <AdvancedDataTable
                        rows={data}
                        columns={columns}
                        onAddClick={handleAddDeliveryPartnerClick}
                    />
                </TableContainer>
            </AdminDrawer>
        </div>
    );
};

export default DeliveryPartners;
