import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Paper, Grid, Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import imageCompression from 'browser-image-compression';
import { useNavigate, useParams } from 'react-router-dom';
import AdminDrawer from '../../../components/AdminDrawer/AdminDrawer';
import { createPackage, updatePackage, getPackageById } from '../../../api/catererPackages/catererPackages';
import CustomSnackbar from '../../../components/CustomeSnackBar/CustomSnackbar';

// Define icons for the checkboxes
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const packageTypeOptions = [
    { title: 'Veg' },
    { title: 'Non-Veg' },
];

const allocationOptions = [
    { title: 'General' },
    { title: 'Premium' },
];

const AddPackages = () => {
    const [packageName, setPackageName] = useState('');
    const [packageType, setPackageType] = useState([]);
    const [allocation, setAllocation] = useState([]);
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
    const [isEdit, setIsEdit] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            // Fetch the package details if `id` is available
            const fetchPackageData = async () => {
                try {
                    const response = await getPackageById(id);
                    const { packageName, type, allocatedTo, packageImages } = response;
    
                    // Ensure `type` and `allocatedTo` are arrays
                    const packageTypes = Array.isArray(type) ? type : [type];
                    const allocations = Array.isArray(allocatedTo) ? allocatedTo : [allocatedTo];
    
                    // Ensure `packageImages` is an array of objects with URL and name
                    const imageDetails = packageImages.map(url => ({
                        url,
                        name: url.split('/').pop() // Extract the image name from the URL
                    }));
    
                    setPackageName(packageName);
                    setPackageType(packageTypes.map(t => ({ title: t })));
                    setAllocation(allocations.map(a => ({ title: a })));
                    setImages(imageDetails); // Set the image details in state
                    setIsEdit(true);
                } catch (error) {
                    console.error('Error fetching package details:', error);
                    setSnackbar({ open: true, message: 'Failed to fetch package details.', severity: 'error' });
                }
            };
            fetchPackageData();
        }
    }, [id]);
    
    

    const handlePackageNameChange = (event) => {
        setPackageName(event.target.value);
    };

    const handlePackageTypeChange = (event, newValue) => {
        setPackageType(newValue);
    };

    const handleAllocationChange = (event, newValue) => {
        setAllocation(newValue);
    };

    const handleImageUpload = async (event) => {
        const files = Array.from(event.target.files);
        if (files.length + images.length > 5) {
            alert('You can upload a maximum of 5 images.');
            return;
        }

        const compressedImages = [];

        for (let file of files) {
            if (file.size > 2 * 1024 * 1024) {
                alert('File size should be less than 2MB');
                return;
            }

            const options = {
                maxSizeMB: 2,
                maxWidthOrHeight: 800,
                useWebWorker: true
            };

            try {
                const compressedFile = await imageCompression(file, options);
                compressedImages.push(compressedFile);
            } catch (error) {
                console.error('Error while compressing the image:', error);
            }
        }

        setImages([...images, ...compressedImages]);
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            if (isEdit) {
                // Call the update function for editing
                await updatePackage(id, {
                    packageName,
                    packageType,
                    allocation,
                    // images,
                });
                setSnackbar({ open: true, message: 'Package updated successfully!', severity: 'success' });
            } else {
                // Call the create function for adding a new package
                await createPackage({
                    packageName,
                    packageType,
                    allocation,
                    images,
                });
                setSnackbar({ open: true, message: 'Package created successfully!', severity: 'success' });
            }

            setTimeout(() => {
                navigate(-1); // Navigate to the previous page
            }, 500);

            // Reset form fields
            setPackageName('');
            setPackageType([]);
            setAllocation([]);
            setImages([]);
        } catch (error) {
            console.error('Error:', error);
            setSnackbar({ open: true, message: 'An error occurred while saving the package.', severity: 'error' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ backgroundColor: '#f2f2f2', padding: '20px', display: 'flex', flexDirection: 'column' }}>
            <AdminDrawer>
                <Paper
                    elevation={0}
                    sx={{
                        margin: '0 auto',
                        padding: '20px',
                        backgroundColor: 'white',
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Typography variant="h5" fontWeight="bold" gutterBottom mb={3}>
                        {isEdit ? 'Edit Package' : 'Add Package'}
                    </Typography>
                    <div>
                        {/* Package Name */}
                        <Grid container spacing={2} mb={2}>
                            <Grid item xs={12}>
                                <Typography component="legend">Package Name</Typography>
                                <TextField
                                    fullWidth
                                    placeholder="Enter Package Name Here"
                                    name="packageName"
                                    variant="outlined"
                                    size="small"
                                    value={packageName}
                                    onChange={handlePackageNameChange}
                                    sx={{ marginBottom: 2, marginTop: 1 }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} mb={2}>

                            {/* Allocation */}
                            <Grid item xs={12} sm={6}>
                                <Typography component="legend" sx={{ marginBottom: 1.5 }}>
                                    Allocation Type
                                </Typography>
                                <Autocomplete
                                    size="small"
                                    multiple
                                    id="allocation"
                                    options={allocationOptions}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.title}
                                    value={allocation}
                                    onChange={handleAllocationChange}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.title}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} placeholder="Select Allocations" />
                                    )}
                                />
                            </Grid>

                            {/* Package Type */}
                            <Grid item xs={12} sm={6}>
                                <Typography component="legend" sx={{ marginBottom: 1.5 }}>
                                    Package Type
                                </Typography>
                                <Autocomplete
                                    size="small"
                                    multiple
                                    id="package-type"
                                    options={packageTypeOptions}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.title}
                                    value={packageType}
                                    onChange={handlePackageTypeChange}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.title}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} placeholder="Select Package Types" />
                                    )}
                                />
                            </Grid>

                        </Grid>

                        {/* Image Upload */}
                        <Grid container spacing={2} mb={2}>
                            <Grid item xs={12}>
                                <Typography component="legend" sx={{ marginBottom: 1.5 }}>
                                    Upload Images (Max 5, less than 2mb each)
                                </Typography>
                                <TextField
                                    fullWidth
                                    name="uploadImages"
                                    variant="outlined"
                                    type="file"
                                    inputProps={{ multiple: true }}
                                    onChange={handleImageUpload}
                                    size="small"
                                    sx={{ marginBottom: 2, marginTop: 1 }}
                                    InputLabelProps={{ shrink: true }}
                                />
                                {images.length > 0 && (
                                    <Box mt={2}>
                                        {images.map((image, index) => (
                                            <Typography key={index}>{image.name}</Typography>
                                        ))}
                                    </Box>
                                )}
                            </Grid>
                        </Grid>

                        {/* Submit Button */}
                        <Box display="flex" justifyContent="flex-end" mt={4}>
                            <Button
                                variant="contained"
                                color="success"
                                sx={{ width: '200px', padding: '10px' }}
                                onClick={handleSubmit}
                                disabled={loading} // Disable button while loading
                            >
                                {loading ? 'Submitting...' : isEdit ? 'Update' : 'Submit'}
                            </Button>
                        </Box>
                    </div>
                </Paper>
            </AdminDrawer>

            {/* Snackbar Component */}
            <CustomSnackbar
                open={snackbar.open}
                message={snackbar.message}
                severity={snackbar.severity}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
            />
        </div>
    );
};

export default AddPackages;
