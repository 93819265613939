import React, { useEffect, useState } from 'react';
import { Typography, Button, Chip } from '@mui/material';
import AdminDrawer from '../../../../components/AdminDrawer/AdminDrawer';
import PayoutTable from '../../../../components/DataTables/PayoutTables/PayoutTable';
import CatererQueriesModal from '../CaterersQueriesModal';
import { fetchSupportByBusinessType, resolveTicket } from '../../../../api/supports/fessteMartSupportApis';

const PartyHallQueries = () => {
    const [entityData, setEntityData] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [loading, setLoading] = useState(true);
    const [resolveDescription, setResolveDescription] = useState('');
    const [action, setAction] = useState("pending"); // or whatever your default value is


    useEffect(() => {
        const fetchData = async () => {
            try {
                const businessType = 'PartyHall'; // You can update this value or pass it dynamically
                const fetchedData = await fetchSupportByBusinessType(businessType);
                if (fetchedData && fetchedData.tickets && Array.isArray(fetchedData.tickets)) {
                    const transformedData = fetchedData.tickets.map((item,index) => ({
                        id: item._id, // Unique ID from the fetched data,
                        siNo: index+1,
                        supportId: item._id,
                        createdAt: item.createdAt,
                        providerType: item.fessteMart.businesses[0]?.businessType || item.serviceProvider.spName,
                        action: item.action,
                        status: item.action,
                        reason: item.reason,
                        query: item.description,
                        topic: item.requestType,
                        updatedAt: item.updatedAt,
                    }));
                    setEntityData(transformedData);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleOpenModal = (row) => {
        setSelectedRow(row);
        setAction(row.action); // Ensure status is set correctly
        setResolveDescription('');
        setOpenModal(true);
    };


    const handleCloseModal = () => {
        setOpenModal(false);
        setResolveDescription('');
        setAction("pending"); // Reset to default or initial value
    };


    const onActionChange = (event) => {
        console.log("Selected action:", event.target.value); // Check selected value
        setAction(event.target.value);
    };


    const handleSubmit = async () => {
        const payload = {
            topic: "payment issue",
            action: action, // Ensure this is the action state
            reason: resolveDescription,
        };

        try {
            const result = await resolveTicket(selectedRow?.supportId, payload);
            console.log('Update Success:', result);

            // Update both 'action' and 'status' in entityData
            const updatedData = entityData.map((entity) =>
                entity.supportId === selectedRow.supportId
                    ? { ...entity, action: action, status: action, reason: resolveDescription }
                    : entity
            );
            setEntityData(updatedData);
            handleCloseModal();
        } catch (error) {
            console.error('Failed to update:', error);
        }
    };



    const columns = [
        { field: 'siNo', headerName: 'SI No', flex: 0.5, sortable: false },
        { field: 'supportId', headerName: 'Support Id', flex: 1 },
        { field: 'providerType', headerName: 'Provider Type', flex: 1 },
        { field: 'topic', headerName: 'Topic', flex: 1 },
        { field: 'query', headerName: 'Query', flex: 1},
        { field: 'reason', headerName: 'FeedBack', flex: 1 },
        { field: 'createdAt', headerName: 'Created At', flex: 1, renderCell: (params) => new Date(params.value).toLocaleString() },
        { field: 'updatedAt', headerName: 'Updated At', flex: 1, renderCell: (params) => new Date(params.value).toLocaleString() },
        {
            field: 'status',
            headerName: 'Action Taken',
            flex: 1,
            renderCell: (params) => {
                let color;
                switch (params.value) {
                    case 'open':
                        color = 'warning';
                        break;
                    case 'resolved':
                        color = 'success';
                        break;
                    case 'inProgress':
                        color = 'primary';
                        break;
                    case 'closed':
                        color = 'default';
                        break;
                    default:
                        color = 'default';
                        break;
                }

                return <Chip label={params.value} color={color} />;
            },
        },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1.2,
            renderCell: (params) => (
                <Button
                    variant="outlined"
                    color="success"
                    onClick={() => handleOpenModal(params.row)}
                >
                    <Typography fontSize="12px" textTransform="none" fontWeight="bold">
                        View Details
                    </Typography>
                </Button>
            ),
        },
    ];

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div style={{ backgroundColor: '#f2f2f2', height: '100vh' }}>
            <AdminDrawer>
                <Typography variant="h4" fontWeight="bolder">Party Hall Queries</Typography>
                <PayoutTable rows={entityData} columns={columns} />
            </AdminDrawer>

            <CatererQueriesModal
                open={openModal}
                onClose={handleCloseModal}
                selectedRow={selectedRow}
                action={action}
                onActionChange={onActionChange} // Pass the function
                onSubmit={handleSubmit}
                setAction={setAction}
                resolveDescription={resolveDescription}
                setResolveDescription={setResolveDescription}
            />

        </div>
    );
};

export default PartyHallQueries;
