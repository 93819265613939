import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Button, Chip } from "@mui/material";
import AdminDrawer from "../../../components/AdminDrawer/AdminDrawer";
import PayoutTable from "../../../components/DataTables/PayoutTables/PayoutTable";
import CustomerQueriesDetailModal from "./CustomerQueriesDetailsModal";
import { fetchCustomerSupportDetails } from "../../../api/supports/customerSupportApis";
import { fetchUserById } from "../../../api/users/usersApi";

const CustomerQueries = () => {
  const navigate = useNavigate();
  const [customerData, setCustomerData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [action, setAction] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedData = await fetchCustomerSupportDetails();
        if (fetchedData && Array.isArray(fetchedData)) {
          const transformedData = fetchedData.map((item, index) => ({
            id: index + 1,
            supportId: item.supportId, // Adding supportId for reference
            userName: item.user.fullName, // Accessing user's full name
            userId: item.user._id,
            phoneNumber: item.user.phoneNumber,
            email: item.user.email,
            orderId: item.orderId, // If present, else remove this line
            serviceProviderId: item.serviceProviderId, // If present, else remove this line
            topic: item.topic,
            reason: item.query, // Changing 'reason' to 'query'
            actionTaken: item.status, // Assuming 'actionTaken' can be the status
            createdAt: item.createdAt, // Adding createdAt for reference
            updatedAt: item.updatedAt, // Adding updatedAt for reference
          }));
          setCustomerData(transformedData);
        }
      } catch (error) {
        console.error("Error fetching customer data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleViewUser = async (row) => {
    try {
      const customerDetails = await fetchUserById(row.userId); // Correct the field to 'userId'
      navigate(`/user-details/${row.userId}`, { state: customerDetails }); // Use the proper userId for navigation
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>; // Placeholder while loading
  }

  const handleOpenModal = (row) => {
    setSelectedRow(row);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setAction("");
  };

  const handleActionChange = (event) => {
    setAction(event.target.value);
  };

  const handleSubmit = () => {
    const updatedData = customerData.map((customer) =>
      customer.id === selectedRow.id
        ? { ...customer, actionTaken: action }
        : customer
    );

    setCustomerData(updatedData);
    handleCloseModal();
  };

  console.log("customerData", customerData);

  const columns = [
    { field: "id", headerName: "ID", minWidth: 10, sortable: false ,},
    {
      field: "supportId",
      headerName: "Support Id",
      minWidth: 200,
      sortable: false,
      
    },
    {
      field: "userName",
      headerName: "User Name",
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Typography
            onClick={() => handleViewUser(params.row)} // Use handleViewUser with the full row
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "14px",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Typography
            onClick={() => handleViewUser(params.row)} // Use handleViewUser with the full row
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "14px",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Typography
            onClick={() => handleViewUser(params.row)} // Use handleViewUser with the full row
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "14px",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            {params.value}
          </Typography>
        );
      },
    },
    { field: "orderId", headerName: "Order ID", minWidth: 200 },
    {
      field: "serviceProviderId",
      headerName: "Service Provider ID",
      minWidth: 250,
    },
    { field: "topic", headerName: "Topic", minWidth: 200 },
    { field: "reason", headerName: "Reason", minWidth: 200 },
    {
      field: "actionTaken",
      headerName: "Action Taken",
      minWidth: 200,
      renderCell: (params) => {
        let color;
        switch (params.value) {
          case "Pending":
            color = "warning";
            break;
          case "Resolved":
            color = "success";
            break;
          case "In Progress":
            color = "primary";
            break;
          default:
            color = "default";
            break;
        }
        return <Chip label={params.value} color={color} />;
      },
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 200,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="success"
          onClick={() => handleOpenModal(params.row)}
        >
          <Typography fontSize="12px" textTransform="none" fontWeight="bold">
            View Details
          </Typography>
        </Button>
      ),
    },
  ];

  return (
    <div style={{ backgroundColor: "#f2f2f2", height: "100vh" }}>
      <AdminDrawer>
        <Typography variant="h4" fontWeight="bolder">
          Customer Queries
        </Typography>
        <PayoutTable rows={customerData} columns={columns} />
      </AdminDrawer>

      <CustomerQueriesDetailModal
        open={openModal}
        onClose={handleCloseModal}
        selectedRow={selectedRow}
        action={action}
        onActionChange={handleActionChange}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default CustomerQueries;
