import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Chip,
  CircularProgress,
  Container,
  Tooltip,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import OrdersTable from "../../../components/DataTables/OrdersTables/OrdersTable";
import { fetchRestaurantOrders } from "../../../api/orders/restaurantOrders";
import { fetchRestaurantOrderDetails } from "../../../api/orders/restaurantOrders";
import { useDialog } from "../../../hooks/useDialog";
import { renderCellWithMore } from "../../../components/ResuableComponents/ViewMoreComponents/ViewMorePopup";
import AdminDrawer from "../../../components/AdminDrawer/AdminDrawer";
import { fetchUserById } from "../../../api/users/usersApi";

const RestaurantOrders = () => {
  const { dialogContent, dialogOpen, handleOpenDialog, handleCloseDialog } =
    useDialog();

  const [orderData, setOrderData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const getOrders = async () => {
    try {
      const data = await fetchRestaurantOrders();
      setOrderData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrders();
  }, []);

  const handleViewDetails = async (orderId) => {
    try {
      const data = await fetchRestaurantOrderDetails(orderId); // Fetch order details
      navigate(`/restaurant-order-invoice/${orderId}`, {
        state: { orderData: data },
      }); // Pass data using state
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  const handleViewUser = async (row) => {
    try {
      const customerDetails = await fetchUserById(row.userId); // Correct the field to 'userId'
      navigate(`/user-details/${row.userId}`, { state: customerDetails }); // Use the proper userId for navigation
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  // Define columns for the DataGrid with minWidth instead of flex
  const columns = [
    { field: "siNo", headerName: "ID", minWidth: 10 },
    {
      field: "orderId",
      headerName: "Order ID",
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Typography
            onClick={() => handleViewDetails(params.row.id)}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "14px",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            {params.value} {/* Caterer name displayed here */}
          </Typography>
        );
      },
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Typography
            onClick={() => handleViewUser(params.row)} // Use handleViewUser with the full row
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "14px",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            {params.value}
          </Typography>
        );
      },
    },
    { field: "orderDate", headerName: "Order Date", minWidth: 200 },
    {
      field: "restaurant",
      headerName: "Restaurant Name",
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Typography
            onClick={() => {
              navigate(`/restaurant-details/${params.row.restaurantId}`, {
                state: { restaurantId: params.row.restaurantId },
              });
            }}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "14px",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            {params.value} {/* Restaurant name displayed here */}
          </Typography>
        );
      },
    },
    {
      field: "itemOrdered",
      headerName: "Items Ordered",
      minWidth: 250,
      sortable: true,
      renderCell: (params) => {
        const text = params.value || ""; // Ensure value is a string
        return renderCellWithMore(text, handleOpenDialog);
      },
    },
    { field: "quantity", headerName: "Quantity", minWidth: 200 },
    {
      field: "status",
      headerName: "Order Status",
      minWidth: 200,
      renderCell: (params) => {
        const statusMapping = {
          newOrder: "New Order",
          preparing: "In-Process",
          delivered: "Delivered",
          pickedUp: "Picked-Up",
          orderReady: "Order-Ready",
          Cancelled: "Cancelled",
          Refunded: "Refunded",
          rejected: "Rejected",
        };

        const statusColors = {
          Delivered: "success",
          rejected: "error",
          newOrder: "success",
          preparing: "warning",
          orderReady: "default",
          pickedUp: "primary",
          Cancelled: "default",
          Refunded: "info",
        };

        const label = statusMapping[params.value] || params.value;

        return (
          <Chip label={label} color={statusColors[params.value] || "primary"} />
        );
      },
    },
    { field: "rejectReason", headerName: "Reject Reason", minWidth: 200 },
    { field: "deliveryDate", headerName: "Delivered Date", minWidth: 200 },
    {
      field: "shippingAddress",
      headerName: "Delivered Address",
      minWidth: 250,
      sortable: true,
      renderCell: (params) => {
        const text = params.value || ""; // Ensure value is a string
        return renderCellWithMore(text, handleOpenDialog);
      },
    },
    { field: "deliveredBy", headerName: "Delivered By", minWidth: 200 },
    {
      field: "total",
      headerName: "Total(Before Discount)",
      minWidth: 250,
      renderCell: (params) => {
        return (
          <Typography
            onClick={() => handleViewDetails(params.row.id)}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "14px",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            {params.value} {/* Caterer name displayed here */}
          </Typography>
        );
      },
    },
    { field: "couponCodeUsed", headerName: "Coupon Code Used", minWidth: 250 },
    { field: "discountAmount", headerName: "Discount Amount", minWidth: 250 },
    {
      field: "totalAmount",
      headerName: "Total Amount(After Discount)",
      minWidth: 300,
      renderCell: (params) => {
        return (
          <Typography
            onClick={() => handleViewDetails(params.row.id)}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "14px",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            {params.value} {/* Caterer name displayed here */}
          </Typography>
        );
      },
    },
    { field: "paymentStatus", headerName: "Payment Status", minWidth: 300 },
    {
      field: "view",
      headerName: "Actions",
      minWidth: 150,
      sortable: false,
      renderCell: (params) => (
        <Tooltip title="View Details">
          <IconButton
            color="default"
            onClick={() => handleViewDetails(params.row.id)}
          >
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  return (
    <div style={{ backgroundColor: "#f2f2f2", height: "100vh" }}>
      <AdminDrawer>
        <div style={{ padding: "16px" }}>
          <Typography variant="h4" fontWeight="bolder">
            Restaurant Orders
          </Typography>
          {loading ? (
            <CircularProgress />
          ) : (
            <Container sx={{ minWidth: "100%" }}>
              <OrdersTable rows={orderData} columns={columns} />
            </Container>
          )}
        </div>
        <Dialog open={dialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>Details</DialogTitle>
          <DialogContent>
            <Typography>{dialogContent}</Typography>
          </DialogContent>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </Dialog>
      </AdminDrawer>
    </div>
  );
};

export default RestaurantOrders;
