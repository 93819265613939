import baseUrl from "../../config";

export const fetchRolesData = async () => {
    try {
        const response = await fetch(`${baseUrl}admin/getAllAdmins`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.ok) {
            const result = await response.json();
            return result.map((item) => ({
                id: item.id,
                _id: item._id,
                email: item.email,
                name: item.fullName,
                phoneNumber: item.phoneNumber,
                role: item.role,
                createdAt: new Date(item.createdAt).toLocaleString('en-GB', { 
                    day: 'numeric', 
                    month: 'long', 
                    year: 'numeric', 
                    hour: 'numeric', 
                    minute: 'numeric', 
                    second: 'numeric' 
                }),
            }));
        } else {
            throw new Error('Failed to fetch roles data');
        }
    } catch (error) {
        console.error('Error fetching roles data:', error);
        throw error;
    }
};

export const createRole = async (payload) => {
    const accessToken = localStorage.getItem('accessToken')?.replace(/"/g, '');

    if (!accessToken) {
        throw new Error('No access token found');
    }

    try {
        const response = await fetch(`${baseUrl}admin/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            const responseBody = await response.text(); 
            throw new Error(`Failed to add role: ${responseBody}`);
        }

        return response.json();
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const updateRole = async (roleId, payload) => {
    const accessToken = localStorage.getItem('accessToken')?.replace(/"/g, '');

    if (!accessToken) {
        throw new Error('No access token found');
    }

    try {
        const response = await fetch(`${baseUrl}admin/update/${roleId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            const responseBody = await response.text(); 
            throw new Error(`Failed to update role: ${responseBody}`);
        }

        return response.json();
    } catch (error) {
        console.error('Error updating role:', error);
        throw error;
    }
};

export const deleteRole = async (roleId) => {
    const accessToken = localStorage.getItem('accessToken')?.replace(/"/g, '');

    if (!accessToken) {
        throw new Error('No access token found');
    }

    try {
        const response = await fetch(`${baseUrl}admin/delete/${roleId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
        });

        if (!response.ok) {
            const responseBody = await response.text(); 
            throw new Error(`Failed to delete role: ${responseBody}`);
        }

        return response.json();
    } catch (error) {
        console.error('Error deleting role:', error);
        throw error;
    }
};

export const resetPassword = async ({ adminId, newPassword }) => {
    const accessToken = localStorage.getItem('accessToken')?.replace(/"/g, '');

    if (!accessToken) {
        throw new Error('No access token found');
    }

    try {
        const response = await fetch(`${baseUrl}admin/resetPassword`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
            body: JSON.stringify({ adminId, newPassword }),
        });

        if (!response.ok) {
            const responseBody = await response.text();
            throw new Error(`Failed to reset password: ${responseBody}`);
        }

        return response.json();
    } catch (error) {
        console.error('Error resetting password:', error);
        throw error;
    }
};


