import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Chip, IconButton, Tooltip, Container, CircularProgress, Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AdminDrawer from '../../components/AdminDrawer/AdminDrawer';
import { fetchQuotations, toggleQuoteVisibility } from '../../api/quotations/quotationsApis'; // Import toggle API
import DataTable from '../../components/DataTables/DataTable';
import CustomSnackbar from '../../components/CustomeSnackBar/CustomSnackbar';
import { useDialog } from '../../hooks/useDialog';
import { renderCellWithMore } from '../../components/ResuableComponents/ViewMoreComponents/ViewMorePopup';



const FessteMartQuotations = () => {
    const { dialogContent, dialogOpen, handleOpenDialog, handleCloseDialog } = useDialog();

    const navigate = useNavigate();
    const [quotations, setQuotations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    useEffect(() => {
        const getQuotations = async () => {
            console.log('Fetching quotations...');
            try {
                const data = await fetchQuotations();
                console.log('Fetched Data:', data);
                setLoading(false);
                setQuotations(data);
            } catch (error) {
                console.error('Failed to fetch quotations', error);
                setLoading(false);
            }
        };
        getQuotations();
    }, []);

    const handleToggleVisibility = async (quoteId) => {
        try {
            const result = await toggleQuoteVisibility(quoteId);
            console.log('Visibility toggled:', result);

            setSnackbar({
                open: true,
                message: 'Visibility successfully toggled!',
                severity: 'success'
            });

            // Refetch quotations to update the table
            const updatedQuotations = await fetchQuotations();
            setQuotations(updatedQuotations);
        } catch (error) {
            console.error('Error toggling visibility:', error);
            setSnackbar({
                open: true,
                message: 'Failed to toggle visibility. Please try again.',
                severity: 'error'
            });
        }
    };

    const closeSnackbar = () => {
        setSnackbar((prevState) => ({ ...prevState, open: false }));
    };

    const columns = [
        { field: 'SiNo', headerName: 'ID', minWidth: 10 },
        { field: 'customerName', headerName: 'Customer Name', minWidth: 200 },
        { field: 'customerEmail', headerName: 'Customer Email', minWidth: 200 },
        { field: 'customerPhone', headerName: 'Phone Number', minWidth: 200 },
        { field: 'eventDate', headerName: 'Event Date', minWidth: 200 },
        { field: 'eventStartTime', headerName: 'Start Time', minWidth: 200 },
        { field: 'eventEndTime', headerName: 'End Time', minWidth: 200 },
        {
            field: 'status',
            headerName: 'Service Provider Status',
            minWidth: 250,
                        renderCell: (params) => (
                <Chip label={params.row.status} color={params.row.status === "confirmed" ? "success" : "warning"} />
            )
        },
        { field: 'quoteStatus', headerName: 'User Status',  minWidth: 200 },
        { field: 'businessName', headerName: 'Business Name',  minWidth: 200 },
        {
            field: 'businessAddress', headerName: 'Business Address', minWidth: 250,
            sortable: true,
            renderCell: (params) => {
                const text = params.value || ''; // Ensure value is a string
                return renderCellWithMore(text, handleOpenDialog);
            },
        },
        {
            field: 'customerAddress', headerName: 'Customer Address', minWidth: 250,
            sortable: true,
            renderCell: (params) => {
                const text = params.value || ''; // Ensure value is a string
                return renderCellWithMore(text, handleOpenDialog);
            },
        },
        {
            field: 'Actions',
            headerName: 'Actions',
            minWidth: 300,
            sortable: false,
            renderCell: (params) => (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Tooltip title="View">
                        <IconButton color="default">
                            <VisibilityIcon onClick={() => navigate(`/view-quotation/${params.row.id}`)} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={params.row.visibleToServiceProvider ? "Already Sent" : "Send to Service Provider"}>
                        <span>
                            <Button
                                variant="contained"
                                color={params.row.visibleToServiceProvider ? "success" : "primary"}
                                disabled={params.row.visibleToServiceProvider} // Disable if already sent
                                onClick={() => handleToggleVisibility(params.row.id)}
                            >
                                {params.row.visibleToServiceProvider ? "Sent" : "Send to Service Provider"}
                            </Button>
                        </span>
                    </Tooltip>
                </div>
            ),
        },
    ];

    return (
        <div style={{ backgroundColor: '#f2f2f2', height: '100vh' }}>
            <AdminDrawer>
                <Typography variant='h4' fontWeight='bolder'>Fesste Mart Quotations</Typography>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <Container sx={{ minWidth: '100%' }}>
                        <DataTable
                            rows={quotations} columns={columns}
                        />
                    </Container>
                )}
            </AdminDrawer>

            {/* Snackbar for success/error messages */}
            <CustomSnackbar
                open={snackbar.open}
                message={snackbar.message}
                severity={snackbar.severity}
                onClose={closeSnackbar}
            />
            <Dialog open={dialogOpen} onClose={handleCloseDialog}>
                <DialogTitle>Details</DialogTitle>
                <DialogContent>
                    <Typography>{dialogContent}</Typography>
                </DialogContent>
                <Button onClick={handleCloseDialog} color="primary">Close</Button>
            </Dialog>
        </div>
    );
};

export default FessteMartQuotations;