import React from 'react';
import { Modal, Box, Typography, TextField, MenuItem, Grid, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const CustomerQueriesDetailModal = ({ open, onClose, selectedRow, action, onActionChange, onSubmit }) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={{ ...modalStyle }}>
                {/* Close Icon */}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>

                <Typography id="modal-title" variant="h6" component="h2"  fontWeight= 'bold' mb={4} gutterBottom>
                    Customer Details
                </Typography>
                {selectedRow && (
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Typography component="legend">User ID</Typography>
                            <TextField
                                fullWidth
                                placeholder="Enter User ID Here"
                                value={selectedRow.userId}
                                variant="outlined"
                                size="small"
                                sx={{ marginBottom: 2, marginTop: 1 }}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ readOnly: true }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography component="legend">User Name</Typography>
                            <TextField
                                fullWidth
                                placeholder="Enter User Name Here"
                                value={selectedRow.userName}
                                variant="outlined"
                                size="small"
                                sx={{ marginBottom: 2, marginTop: 1 }}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ readOnly: true }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography component="legend">Phone Number</Typography>
                            <TextField
                                fullWidth
                                placeholder="Enter Phone Number Here"
                                value={selectedRow.phoneNumber}
                                variant="outlined"
                                size="small"
                                sx={{ marginBottom: 2, marginTop: 1 }}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ readOnly: true }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography component="legend">Email</Typography>
                            <TextField
                                fullWidth
                                placeholder="Enter Email Here"
                                value={selectedRow.email}
                                variant="outlined"
                                size="small"
                                sx={{ marginBottom: 2, marginTop: 1 }}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ readOnly: true }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography component="legend">Order ID</Typography>
                            <TextField
                                fullWidth
                                placeholder="Enter Order ID Here"
                                value={selectedRow.orderId}
                                variant="outlined"
                                size="small"
                                sx={{ marginBottom: 2, marginTop: 1 }}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ readOnly: true }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography component="legend">Service Provider ID</Typography>
                            <TextField
                                fullWidth
                                placeholder="Enter Service Provider ID Here"
                                value={selectedRow.serviceProviderId}
                                variant="outlined"
                                size="small"
                                sx={{ marginBottom: 2, marginTop: 1 }}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ readOnly: true }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography component="legend">Reason</Typography>
                            <TextField
                                fullWidth
                                placeholder="Enter Reason Here"
                                value={selectedRow.reason}
                                variant="outlined"
                                size="small"
                                multiline
                                rows={3}
                                sx={{ marginBottom: 2, marginTop: 1 }}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ readOnly: true }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography component="legend">Action</Typography>
                            <TextField
                                select
                                fullWidth
                                value={action}
                                onChange={onActionChange}
                                variant="outlined"
                                size="small"
                                sx={{ marginBottom: 2, marginTop: 1 }}
                                InputLabelProps={{ shrink: true }}
                            >
                                <MenuItem value="Pending">Pending</MenuItem>
                                <MenuItem value="Resolved">Resolved</MenuItem>
                                <MenuItem value="In Progress">In Progress</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} textAlign="right">
                            <Button variant="contained" color="primary" onClick={onSubmit}>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </Box>
        </Modal>
    );
};

// Modal styling
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
};

export default CustomerQueriesDetailModal;
