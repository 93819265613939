import React, { useState, useRef, useCallback } from 'react';
import { useLoadScript, GoogleMap, Autocomplete, MarkerF } from '@react-google-maps/api';

const libraries = ['places'];

const center = {
    lat: 43.6532,
    lng: -79.3832,
};

const options = {
    disableDefaultUI: true,
    zoomControl: true,
};

export default function Map({ onAddressChange }) {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyBiJNKC1KDqdqyVCHOHO4RPoyairZDWFbM', // Replace with your API key
        libraries,
    });

    const [marker, setMarker] = useState(null);
    const autocompleteRef = useRef(null); // Ref for Autocomplete

    const onPlaceChanged = useCallback(() => {
        const place = autocompleteRef.current.getPlace();

        if (place.geometry) {
            const location = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
            };
            setMarker(location);
            
            // Extract address
            const address = place.formatted_address;
            
            // Notify parent component
            if (onAddressChange) {
                onAddressChange(address);
            }
        }
    }, [onAddressChange]);

    if (loadError) return <div>Error loading maps</div>;
    if (!isLoaded) return <div>Loading Maps...</div>;

    return (
        <div style={{ position: 'relative', height: '100vh' }}>
            {/* Autocomplete Input Field */}
            <Autocomplete
                onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
                onPlaceChanged={onPlaceChanged}
            >
                <input
                    type="text"
                    placeholder="Search for a location"
                    style={{
                        boxSizing: 'border-box',
                        border: '1px solid transparent',
                        width: '100%',
                        height: '40px',
                        padding: '0 12px',
                        borderRadius: '3px',
                        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                        fontSize: '16px',
                        outline: 'none',
                        textOverflow: 'ellipsis',
                        position: 'absolute',
                        top: '10px',
                        zIndex: '100',
                    }}
                />
            </Autocomplete>

            {/* Google Map */}
            <div style={{ width: '100%', height: '100%' }}>
                <GoogleMap
                    center={marker || center} // Center the map on the marker if available
                    zoom={marker ? 15 : 10} // Zoom in if a marker is present
                    mapContainerStyle={{ width: '100%', height: '400px' }}
                    options={options}
                >
                    {marker && <MarkerF position={marker} />}
                </GoogleMap>
            </div>
        </div>
    );
}
