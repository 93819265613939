import React, { useEffect, useState } from "react";
import { Download as DownloadIcon } from '@mui/icons-material';
import { useLocation } from "react-router-dom";
import {
  Typography, Tabs, Tab, Box, CircularProgress, Paper, Divider, Grid, Card, CardMedia,
  IconButton, CardContent, Dialog, Button
} from "@mui/material";
import baseUrl from "../../../../config";
import AdminDrawer from "../../../../components/AdminDrawer/AdminDrawer";

const PartyHallDetails = () => {
  const { state } = useLocation();
  const { id } = state || {};
  const [loading, setLoading] = useState(true);
  const [partyHallDetails, setPartyHallDetails] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [open, setOpen] = useState(false);  // State to control the modal
  const [selectedImage, setSelectedImage] = useState(null); // State to store the selected image

  useEffect(() => {
    const fetchPartyHallDetails = async () => {
      if (id) {
        try {
          const response = await fetch(`${baseUrl}partyHallSp/${id}`);
          const data = await response.json();
          setPartyHallDetails(data);
        } catch (error) {
          console.error("Error fetching lighting details:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchPartyHallDetails();
  }, [id]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (loading) {
    return <CircularProgress />;
  }

  const { kyc, serviceProvider, fessteMart } = partyHallDetails;

  // Handle image click to open the preview
  const handleImageClick = (image) => {
    setSelectedImage(image); // Set the clicked image URL
    setOpen(true); // Open the modal
  };

  // Handle modal close
  const handleClose = () => {
    setOpen(false); // Close the modal
    setSelectedImage(null); // Clear the selected image
  };
  const handleDownload = async () => {
    const apiUrl = `${baseUrl}downloadFessteMarts/${fessteMart._id}`;

    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'documents.zip'); // Replace with actual file name if necessary
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  return (
    <AdminDrawer>
      <Paper elevation={3} style={{ padding: "20px", backgroundColor: "#f9f9f9" }}>
        <Typography variant="h4" gutterBottom>
          Party Hall Details: {partyHallDetails?.partyHallID || "Unknown Lighting"}
        </Typography>

        <Tabs value={tabValue} onChange={handleChange} centered>
          <Tab label="Details" />
          <Tab label="Menu" />
        </Tabs>

        <Box mt={2}>
          {tabValue === 0 && (
            <><Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h5" gutterBottom>
                    Service Provider Details
                  </Typography>
                  <Typography><strong>Name:</strong> {serviceProvider?.spName}</Typography>
                  <Typography><strong>Phone:</strong> {serviceProvider?.spPhone}</Typography>
                  <Typography><strong>Email:</strong> {serviceProvider?.spEmail}</Typography>
                  <Divider sx={{ my: 2 }} />
                  <Typography variant="h5" gutterBottom>
                    KYC Details
                  </Typography>
                  <Typography><strong>PAN No:</strong> {partyHallDetails?.fessteMart?.panNo}</Typography>
                  <Typography><strong>GST No:</strong> {partyHallDetails?.fessteMart?.gstNo}</Typography>
                  <Dialog open={open} onClose={handleClose} maxWidth="md">
                    <img src={selectedImage} alt="Documents" style={{ maxHeight: '60vh', width: '50vw', objectFit: 'fit' }} />
                  </Dialog>

                </Grid>

                <Grid item xs={12} md={6} mb={2}>
                  <Typography variant="h5" gutterBottom>
                    Business Details
                  </Typography>
                  <Typography><strong>Business ID:</strong> {fessteMart?.fmID}</Typography>
                  <Typography><strong>Business Name:</strong> {fessteMart?.businessName}</Typography>
                  <Typography><strong>Street:</strong> {fessteMart?.address?.street}</Typography>
                  <Typography><strong>City:</strong> {fessteMart?.address?.city}</Typography>
                  <Typography><strong>Postal Code:</strong> {fessteMart?.address?.postalCode}</Typography>
                  <Typography><strong>Business Phone:</strong> {fessteMart?.businessPhoneNumber}</Typography>
                  <Typography><strong>Service Range:</strong> {fessteMart?.serviceRange} km</Typography>
                  <Typography><strong>Delivery Time:</strong> {fessteMart?.deliveryTime} hour(s)</Typography>
                  <Typography><strong>About Us:</strong> {fessteMart?.aboutUs}</Typography>


                </Grid>
              </Grid>
            </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6" fontWeight="bold" sx={{ mt: 2, alignSelf: 'flex-start' }}>
                  KYC Details
                </Typography>
                <Button variant="contained"
                  sx={{ borderRadius: '2rem', alignSelf: 'flex-end' }}
                  onClick={handleDownload}>
                  Download All
                </Button>
              </Box>
              <Grid container spacing={1}>

                <Grid item xs={4}>

                  <Card
                    sx={{
                      borderRadius: 2,
                      overflow: "hidden",
                      boxShadow: 1,
                      position: 'relative',
                      padding: 2,
                    }}
                  >

                    <Typography variant="body2">
                      Business Profile Photo
                    </Typography>
                    <Box sx={{ position: 'relative' }}>

                      <img
                        onClick={() => handleImageClick(partyHallDetails?.businessProfilePhoto?.[0])}  // Pass specific image URL
                        src={partyHallDetails?.businessProfilePhoto?.[0]}  // Image URL
                        alt="Business Profile"
                        style={{
                          width: '100%',
                          height: '180px',
                          objectFit: 'cover',
                          cursor: 'pointer',
                        }}
                      />
                      {/* Download Icon on Top of the Image */}
                      <a href={partyHallDetails?.businessProfilePhoto?.[0]} download>
                        <IconButton
                          sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            color: 'green',
                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            '&:hover': {
                              backgroundColor: 'rgba(255, 255, 255, 0.9)',
                            },
                          }}
                        >
                          <DownloadIcon fontSize="medium" />
                        </IconButton>
                      </a>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card
                    sx={{
                      borderRadius: 2,
                      overflow: "hidden",
                      boxShadow: 1,
                      position: 'relative',
                      padding: 2,
                    }}
                  >
                    <Typography variant="body2" fontWeight="bold">
                      PAN No:
                    </Typography>
                    <Typography variant="body2">
                      {partyHallDetails?.fessteMart?.panNo}
                    </Typography>
                    {/* Pan Image with Download Icon Overlay */}
                    <Box sx={{ position: 'relative' }}>
                      <img
                        component="img"
                        style={{
                          width: '100%', // Ensure the image takes up the full card width
                          height: '180px', // Set a fixed height for the image
                          objectFit: 'fill', // Ensure the image covers the area
                          cursor: 'pointer',
                        }}
                        src={partyHallDetails?.fessteMart?.panImage} // Image URL
                        alt="Pan Image"
                        onClick={() => handleImageClick(partyHallDetails?.fessteMart?.panImage)} // Pass specific image URL
                      />
                      {/* Download Icon Overlay */}
                      <a href={partyHallDetails?.fessteMart?.panImage} download>
                        <IconButton
                          sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            color: 'green',
                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            '&:hover': {
                              backgroundColor: 'rgba(255, 255, 255, 0.9)',
                            },
                          }}
                        >
                          <DownloadIcon />
                        </IconButton>
                      </a>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card
                    sx={{
                      borderRadius: 2,
                      overflow: "hidden",
                      boxShadow: 1,
                      position: 'relative',
                      padding: 2,
                    }}
                  >
                    <Typography variant="body2" fontWeight="bold">
                      GST No:
                    </Typography>
                    <Typography variant="body2">
                      {partyHallDetails?.fessteMart?.gstNo}
                    </Typography>
                    {/* Gst Image with Download Icon Overlay */}
                    <Box sx={{ position: 'relative' }}>
                      <img
                        component="img"
                        src={partyHallDetails?.fessteMart?.gstImage} // Image URL
                        onClick={() => handleImageClick(partyHallDetails?.fessteMart?.gstImage)} // Pass specific image URL
                        alt="GST Image"
                        style={{
                          width: '100%', // Ensure the image takes up the full card width
                          height: '180px', // Set a fixed height for the image
                          objectFit: 'fill', // Ensure the image covers the area
                          cursor: 'pointer',
                        }} />
                      {/* Download Icon Overlay */}
                      <a href={partyHallDetails?.fessteMart?.gstImage} download>
                        <IconButton
                          sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            color: 'green',
                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            '&:hover': {
                              backgroundColor: 'rgba(255, 255, 255, 0.9)',
                            },
                          }}
                        >
                          <DownloadIcon fontSize="medium" />
                        </IconButton>
                      </a>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card
                    sx={{
                      borderRadius: 2,
                      overflow: 'hidden',  // Prevent the card from scrolling
                      boxShadow: 1,
                      position: 'relative',
                      padding: 2,
                      height: 'auto',  // Ensure the card can grow to fit content if needed
                    }}
                  >
                    {/* PDF Preview */}
                    <Box sx={{ position: 'relative', height: '250px' }}>  {/* Match video height */}
                      <iframe
                        src={partyHallDetails?.fessteMart?.contractUrl}  // PDF URL
                        title="Contract PDF"
                        style={{
                          width: '100%',  // Ensure the iframe takes up the full card width
                          height: '100%',  // Fill the container height (250px)
                          border: 'none',  // Remove default iframe borders
                        }}
                      />
                    </Box>

                    {/* Download Icon */}
                    <a href={partyHallDetails?.fessteMart?.contractUrl} download>
                      <IconButton
                        sx={{
                          position: 'absolute',
                          top: 8,
                          right: 20,
                          color: 'green',
                          backgroundColor: 'rgba(255, 255, 255, 0.7)',
                          '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.9)',
                          },
                        }}
                      >
                        <DownloadIcon fontSize="medium" />
                      </IconButton>
                    </a>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card
                    sx={{
                      boxShadow: 1,
                      padding: 2,
                    }}
                  >
                    <video
                      component="video"
                      controls
                      src={partyHallDetails?.businessVideo}  // Ensure this contains the correct video URL
                      alt="Business Video"
                      style={{
                        width: '100%',  // Ensure the video takes up the full card width
                        height: '250px',  // Set the height to match the PDF card
                        objectFit: 'fill',  // Ensure the video covers the area
                        cursor: 'pointer',
                      }}
                    />
                  </Card>
                </Grid>

              </Grid></>
          )}
          {tabValue === 1 && (
            <Box>
              <Typography variant="h5" gutterBottom>
                Menu Items
              </Typography>
              {partyHallDetails?.menu?.halls && partyHallDetails?.menu?.halls.length > 0 ? (
                <Grid container spacing={2}>
                  {partyHallDetails?.menu.halls.map((hall) => (
                    <Grid item xs={12} md={6} key={hall._id}>
                      <Paper elevation={2} style={{ padding: '16px' }}>
                        <Typography variant="h6">{hall.partyHallType.typeName}</Typography>
                        <img src={hall.partyHallType.images[0]} alt={hall.partyHallType.typeName} width="100" />
                        <Typography>Price: ₹{hall.customPrice}</Typography>
                        <Typography>Description: {hall.partyHallType.description}</Typography>
                        <Typography>Available: {hall.availability ? "Yes" : "No"}</Typography>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Typography>No Halls available</Typography>
              )}
            </Box>
          )}
        </Box>
      </Paper>
    </AdminDrawer>
  );
};

export default PartyHallDetails;

