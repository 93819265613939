// src/api/restaurantorders.js
import baseUrl from "../../config";

export const fetchCatererOrders = async () => {
  try {
    const response = await fetch(`${baseUrl}getAllCatererOrders`);
    const data = await response.json();

    // Map the API response to match the columns
    const mappedData = data.map((order, index) => ({
      id: order?._id,
      siNo: index + 1,
      catererId: order.catererId?._id,
      orderId: order?.orderID,
      customerName: order?.userId?.fullName,
      orderType: order.status === 'Delivered' ? 'Delivery' : 'Pickup',
      deliveryPerson: 'N/A',
      caterer: order?.catererId?.businessName,
      status: order.status,
      packageType: order?.items?.[0]?.packageId?.packageName,
      quantity:order?.items?.[0]?.guestCount,
      deliveryDate:order?.items?.[0]?.deliveryDate,
      address: `${order?.catererId?.address?.street}, ${order?.catererId?.address?.city}, ${order?.catererId?.address?.state} - ${order?.catererId?.address?.postalCode}`,
      deliveryType: order?.deliveryType,
      rejectReason: order?.rejectReason,
      paymentStatus:order?.finalPaymentSchedule?.status,
      date: new Date(order.createdAt).toLocaleDateString(),
      shippingAddress: `${order?.shippingAddress?.street}, ${order?.deliveryAddress?.city}, ${order?.deliveryAddress?.state} - ${order?.deliveryAddress?.postalCode}`,
      paymentMode: order.paymentMethod || 'N/A',
      total: `₹${order?.grossAmount}`,
      totalAmount: `₹${order?.totalAmount}`,
      orderDate: new Date(order.createdAt).toLocaleDateString(),
      deliveredBy: order.deliveryPerson || 'Not Assigned',
      discountObtained: order.discountAmount || 0,
      couponCodeUsed: order.couponCode || 'None',
      discountAmount: order.discountAmount || 0,
      userId: order.userId._id
    }));
    return mappedData;
  } catch (error) {
    console.error('Error fetching orders:', error);
    throw error;
  }
};

// src/api/orders/orderDetails.js
export const fetchCatererOrderDetails = async (orderId) => {
  const response = await fetch(`${baseUrl}catererOrder/${orderId}`);
  if (!response.ok) {
    throw new Error('Failed to fetch order details');
  }
  const data = await response.json();
  return data;
};


//  adding caterer delivery type
export const updateDeiveryType = async (orderData) => {
  try {
    const response = await fetch(`${baseUrl}/updateDeliveryType`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(orderData),
    });

    if (!response.ok) {
      throw new Error('Failed to Update Delivery Type');
    }

    return await response.json();
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

