import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchItemById } from '../../../../api/catererPackages/catererItems';
import { Typography, Container, Paper, CircularProgress, Box } from '@mui/material';
import AdminDrawer from '../../../../components/AdminDrawer/AdminDrawer';

const ViewItemDetails = () => {
    const { id } = useParams(); // Get the item ID from the URL
    const [itemData, setItemData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getItemDetails = async () => {
            try {
                const response = await fetchItemById(id);
                setItemData(response);
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch item details:', error);
                setLoading(false);
            }
        };

        getItemDetails();
    }, [id]);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (!itemData) {
        return <Typography variant="h6">Item not found</Typography>;
    }

    return (
        <AdminDrawer>
            <Container>
                <Typography variant="h4" fontWeight="bold" gutterBottom>
                    Item Details
                </Typography>
                <Paper elevation={3} sx={{ padding: '20px', borderRadius: '8px', mb: 4 }}>
                    <Typography variant="h6" gutterBottom fontWeight="bold">
                        {itemData.itemName}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <strong>Description:</strong> {itemData.itemDescription}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <strong>Package:</strong> {itemData.packages?.map(pkg => pkg.packageName).join(', ')}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <strong>SubPackage:</strong> {itemData.subPackage?.subPackageName}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <strong>Allocated To:</strong> {itemData.allocatedTo?.join(', ')}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <strong>Created At:</strong> {new Date(itemData.createdAt).toLocaleDateString()}
                    </Typography>
                    {itemData.itemImages?.length > 0 && (
                        <Box mt={2}>
                            <Typography variant="h6" gutterBottom>
                                Images
                            </Typography>
                            <Box display="flex" flexWrap="wrap">
                                {itemData.itemImages.map((image, index) => (
                                    <Box
                                        key={index}
                                        component="img"
                                        src={image}
                                        alt={`Item ${index}`}
                                        sx={{
                                            width: '150px',
                                            height: 'auto',
                                            margin: '10px',
                                            borderRadius: '8px',
                                            border: '1px solid #ddd',
                                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                                        }}
                                    />
                                ))}
                            </Box>
                        </Box>
                    )}
                </Paper>
            </Container>
        </AdminDrawer>
    );
};

export default ViewItemDetails;
