import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Grid } from '@mui/material';
import AdminDrawer from '../../../components/AdminDrawer/AdminDrawer';
import StatisticsCard from '../../AdvancedComponents/PayoutComponents/StatisticsCard';
import PaymentBreakdown from '../../AdvancedComponents/PayoutComponents/PaymentBreakdown';
import FessteMartPayoutBreakdown from '../../AdvancedComponents/PayoutComponents/FessteMartPayoutTable/FessteMartPayoutBreakDown';
import PaymentDashBoardTable from '../../AdvancedComponents/PayoutComponents/PaymentDashBoardTable';
import FessteMartPayoutTable from '../../AdvancedComponents/PayoutComponents/FessteMartPayoutTable/FessteMartPayoutTable';
import { fetchCatererPayout, fetchRestaurantPayout, fetchFessteMartPayoutById } from '../../../api/payouts/payoutsApi';

const PaymentDashBoard = () => {
    const { id } = useParams(); // Get the ID from the URL
    const [payoutData, setPayoutData] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isCaterer, setIsCaterer] = useState(false); // Flag to track if it's a caterer
    const [isFessteMart, setIsFessteMart] = useState(false); // Flag to track if it's FessteMart

    useEffect(() => {
        const loadPayoutData = async () => {
            try {
                const path = window.location.pathname;
                const isCatererRoute = path.includes('/caterer/');
                const isFessteMartRoute = path.includes('/fmpayouts/');

                setIsCaterer(isCatererRoute);
                setIsFessteMart(isFessteMartRoute);

                let data;

                if (isFessteMartRoute) {
                    data = await fetchFessteMartPayoutById(id); // Fetch FessteMart payouts
                } else if (isCatererRoute) {
                    data = await fetchCatererPayout(id); // Fetch caterer payouts
                } else {
                    data = await fetchRestaurantPayout(id); // Fetch restaurant payouts
                }

                setPayoutData(data);
            } catch (error) {
                console.error("Error fetching payout data:", error);
                setPayoutData([]);
            } finally {
                setLoading(false);
            }
        };

        loadPayoutData();
    }, [id]);

    const handleCheckboxChange = (order) => {
        setSelectedOrder(order);
    };

    if (loading) {
        return (
            <div style={{ backgroundColor: '#f2f2f2', height: '100vh' }}>
                <AdminDrawer>
                    <div style={{ padding: '24px' }}>
                        <Typography variant='h6'>Loading...</Typography>
                    </div>
                </AdminDrawer>
            </div>
        );
    }

    if (!payoutData || payoutData.length === 0) {
        return (
            <div style={{ backgroundColor: '#f2f2f2', height: '100vh' }}>
                <AdminDrawer>
                    <div style={{ padding: '24px' }}>
                        <Typography variant='h6' color='error'>
                            Failed to load payout data
                        </Typography>
                    </div>
                </AdminDrawer>
            </div>
        );
    }

    // Check if payoutData is an array, handle the single object case
    const totalPayout = Array.isArray(payoutData)
        ? payoutData.reduce((acc, payoutDetails) => {
            acc.netPayout += payoutDetails?.netPayout || 0;
            acc.fessteFee += payoutDetails?.fessteFee || 0;
            acc.transactionFee += payoutDetails?.transactionFee || 0;
            return acc;
        }, {
            netPayout: 0,
            fessteFee: 0,
            transactionFee: 0,
        })
        : {
            netPayout: payoutData?.netPayout || 0,
            fessteFee: payoutData?.fessteFee || 0,
            transactionFee: payoutData?.transactionFee || 0,
        };

    return (
        <div style={{ backgroundColor: '#f2f2f2', height: 'max-content' }}>
            <AdminDrawer>
                <div style={{ padding: '24px' }}>
                    <Typography variant='h5' gutterBottom>
                        {isCaterer ? payoutData[0]?.caterer?.businessName : payoutData[0]?.restaurant?.restName}
                    </Typography>
                </div>

                <Grid container spacing={2} mb={2}>
                    <Grid item xs={12} sm={6} md={3}>
                        <StatisticsCard
                            title="Total Earnings"
                            value={`₹${totalPayout?.netPayout?.toFixed(2)}`}
                            background="linear-gradient(135deg, #6a1b9a 0%, #8e24aa 100%)"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <StatisticsCard
                            title="Fesste Fee"
                            value={`₹${totalPayout.fessteFee.toFixed(2)}`}
                            background="linear-gradient(135deg, #43a047 0%, #66bb6a 100%)"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <StatisticsCard
                            title="Transaction Fee"
                            value={`₹${totalPayout?.transactionFee?.toFixed(2)}`}
                            background="linear-gradient(135deg, #ffb300 0%, #ffa000 100%)"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <StatisticsCard
                            title="Net Payout"
                            value={`₹${totalPayout?.netPayout?.toFixed(2)}`}
                            background="linear-gradient(135deg, #1e88e5 0%, #42a5f5 100%)"
                        />
                    </Grid>
                </Grid>

                {isFessteMart && selectedOrder ? (
                    <FessteMartPayoutBreakdown selectedOrder={selectedOrder} />
                ) : selectedOrder ? (
                    <PaymentBreakdown selectedOrder={selectedOrder} />
                ) : null}

                {/* Conditionally render either PaymentDashBoardTable or FessteMartPayoutTable */}
                {isFessteMart ? (
                    <FessteMartPayoutTable
                        orders={payoutData} 
                        selectedOrder={selectedOrder}
                        onCheckboxChange={handleCheckboxChange}
                    />
                ) : (
                    <PaymentDashBoardTable
                        orders={payoutData} 
                        selectedOrder={selectedOrder}
                        onCheckboxChange={handleCheckboxChange}
                    />
                )}
            </AdminDrawer>
        </div>
    );
};

export default PaymentDashBoard;
