import React, { useState } from 'react';
import { TextField, Button, Typography, Paper, Grid, Box, MenuItem, IconButton  } from '@mui/material';
import { Upload as UploadIcon } from '@mui/icons-material';

import AdminDrawer from '../../components/AdminDrawer/AdminDrawer';
import Map from '../../components/Maps/Map';

const AddDeliveryPartners = () => {
    const [selectedAddress, setSelectedAddress] = useState('');
    const [panImage, setPanImage] = useState(null);
    const [idProofImage, setIdProofImage] = useState(null);
    const [motorProofImage, setMotorProofImage] = useState(null);
    const [ownerImage, setOwnerImage] = useState(null);

    const handleAddressChange = (address) => {
        setSelectedAddress(address);
    };

    const handleImageUpload = (event, setImage) => {
        const file = event.target.files[0];
        if (file) {
            setImage(URL.createObjectURL(file));
        }
    };

    console.log('selectedAddress', selectedAddress);


    return (
        <div style={{ backgroundColor: '#f2f2f2', padding: '20px', height: '100vh', display: 'flex', flexDirection: 'column' }}>
            <AdminDrawer>
                <Paper
                    elevation={0}
                    sx={{
                        margin: '0 auto',
                        padding: '20px',
                        backgroundColor: 'white',
                        flex: 1, // Allow Paper to expand
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Typography variant="h5" fontWeight='bold' gutterBottom mb={3}>
                        Delivery Partner Details
                    </Typography>

                    {/* Form Fields */}
                    <Grid container spacing={2} mb={2}>
                        <Grid item xs={12} sm={6}>
                            <Typography component="legend">Delivery Partner Name</Typography>
                            <TextField
                                fullWidth
                                placeholder="Enter Delivery Partner Name"
                                name="deliveryPartnerName"
                                variant="outlined"
                                size="small"
                                sx={{ marginBottom: 2, marginTop: 1 }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography component="legend">Mobile Number</Typography>
                            <TextField
                                fullWidth
                                placeholder="Enter Mobile Number"
                                name="mobileNumber"
                                variant="outlined"
                                size="small"
                                sx={{ marginBottom: 2, marginTop: 1 }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} mb={2}>
                        <Grid item xs={12} sm={6}>
                            <Typography component="legend">City</Typography>
                            <TextField
                                fullWidth
                                placeholder="Enter City"
                                name="city"
                                variant="outlined"
                                size="small"
                                sx={{ marginBottom: 2, marginTop: 1 }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography component="legend">Gender</Typography>
                            <TextField
                                select
                                fullWidth
                                placeholder="Select Gender"
                                name="gender"
                                variant="outlined"
                                size="small"
                                sx={{ marginBottom: 2, marginTop: 1 }}
                                InputLabelProps={{ shrink: true }}
                            >
                                <MenuItem value="Male">Male</MenuItem>
                                <MenuItem value="Female">Female</MenuItem>
                            </TextField>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} mb={2}>
                        <Grid item xs={12} sm={6}>
                            <Typography component="legend">Father Name</Typography>
                            <TextField
                                fullWidth
                                placeholder="Enter Father Name"
                                name="fatherName"
                                variant="outlined"
                                size="small"
                                sx={{ marginBottom: 2, marginTop: 1 }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography component="legend">Vehicle Type</Typography>
                            <TextField
                                fullWidth
                                placeholder="Enter Vehicle Type"
                                name="vehicleType"
                                variant="outlined"
                                size="small"
                                sx={{ marginBottom: 2, marginTop: 1 }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} mb={2}>
                        <Grid item xs={12} sm={6}>
                            <Typography component="legend">Ownership Type</Typography>
                            <TextField
                                fullWidth
                                placeholder="Enter Ownership Type"
                                name="ownershipType"
                                variant="outlined"
                                size="small"
                                sx={{ marginBottom: 2, marginTop: 1 }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography component="legend">Pan Number</Typography>
                            <TextField
                                fullWidth
                                placeholder="Enter Pan Number"
                                name="panNumber"
                                variant="outlined"
                                size="small"
                                sx={{ marginBottom: 2, marginTop: 1 }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} mb={2}>
                        <Grid item xs={12} sm={6}>
                            <Typography component="legend">Name of Pan</Typography>
                            <TextField
                                fullWidth
                                placeholder="Enter Name of Pan"
                                name="nameOfPan"
                                variant="outlined"
                                size="small"
                                sx={{ marginBottom: 2, marginTop: 1 }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography component="legend">State</Typography>
                            <TextField
                                fullWidth
                                placeholder="Enter State"
                                name="state"
                                variant="outlined"
                                size="small"
                                sx={{ marginBottom: 2, marginTop: 1 }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} mb={2}>
                        <Grid item xs={12} sm={6}>
                            <Typography component="legend">Pincode</Typography>
                            <TextField
                                fullWidth
                                placeholder="Enter Pincode"
                                name="pincode"
                                variant="outlined"
                                size="small"
                                sx={{ marginBottom: 2, marginTop: 1 }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography component="legend">House Number</Typography>
                            <TextField
                                fullWidth
                                placeholder="Enter House Number"
                                name="houseNumber"
                                variant="outlined"
                                size="small"
                                sx={{ marginBottom: 2, marginTop: 1 }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} mb={2}>
                        <Grid item xs={12} sm={6}>
                            <Typography component="legend">Area/Street</Typography>
                            <TextField
                                fullWidth
                                placeholder="Enter Area/Street"
                                name="areaStreet"
                                variant="outlined"
                                size="small"
                                sx={{ marginBottom: 2, marginTop: 1 }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </Grid>

                    {/* Image Upload Fields with Preview */}
                    <Grid container spacing={2} mb={2}>
                        <Grid item xs={12} sm={6}>
                            <Typography component="legend">Pan Image</Typography>
                            <TextField
                                fullWidth
                                placeholder="Upload Pan Image"
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    endAdornment: (
                                        <IconButton component="label">
                                            <UploadIcon />
                                            <input
                                                type="file"
                                                hidden
                                                onChange={(e) => handleImageUpload(e, setPanImage)}
                                            />
                                        </IconButton>
                                    ),
                                }}
                                sx={{ marginBottom: 2, marginTop: 1 }}
                            />
                            {panImage && (
                                <Box sx={{ marginTop: 2 }}>
                                    <img
                                        src={panImage}
                                        alt="Pan Preview"
                                        style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                                    />
                                </Box>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography component="legend">ID Proof Image</Typography>
                            <TextField
                                fullWidth
                                placeholder="Upload ID Proof Image"
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    endAdornment: (
                                        <IconButton component="label">
                                            <UploadIcon />
                                            <input
                                                type="file"
                                                hidden
                                                onChange={(e) => handleImageUpload(e, setIdProofImage)}
                                            />
                                        </IconButton>
                                    ),
                                }}
                                sx={{ marginBottom: 2, marginTop: 1 }}
                            />
                            {idProofImage && (
                                <Box sx={{ marginTop: 2 }}>
                                    <img
                                        src={idProofImage}
                                        alt="ID Proof Preview"
                                        style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                                    />
                                </Box>
                            )}
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} mb={2}>
                        <Grid item xs={12} sm={6}>
                            <Typography component="legend">Motor Proof Image</Typography>
                            <TextField
                                fullWidth
                                placeholder="Upload Motor Proof Image"
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    endAdornment: (
                                        <IconButton component="label">
                                            <UploadIcon />
                                            <input
                                                type="file"
                                                hidden
                                                onChange={(e) => handleImageUpload(e, setMotorProofImage)}
                                            />
                                        </IconButton>
                                    ),
                                }}
                                sx={{ marginBottom: 2, marginTop: 1 }}
                            />
                            {motorProofImage && (
                                <Box sx={{ marginTop: 2 }}>
                                    <img
                                        src={motorProofImage}
                                        alt="Motor Proof Preview"
                                        style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                                    />
                                </Box>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography component="legend">Owner Image</Typography>
                            <TextField
                                fullWidth
                                placeholder="Upload Owner Image"
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    endAdornment: (
                                        <IconButton component="label">
                                            <UploadIcon />
                                            <input
                                                type="file"
                                                hidden
                                                onChange={(e) => handleImageUpload(e, setOwnerImage)}
                                            />
                                        </IconButton>
                                    ),
                                }}
                                sx={{ marginBottom: 2, marginTop: 1 }}
                            />
                            {ownerImage && (
                                <Box sx={{ marginTop: 2 }}>
                                    <img
                                        src={ownerImage}
                                        alt="Owner Preview"
                                        style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                                    />
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                    {/* Map Section */}
                    <Box mb={2} sx={{ height: '300px', marginTop: 2, }}>
                        <Map onAddressSelect={handleAddressChange} />
                    </Box>

                    {/* Submit Button */}
                    <Box display="flex" justifyContent="flex-end" mt={20}>
                        <Button
                            variant="contained" color="success"
                            sx={{ width: '200px', padding: '10px' }}
                        >
                            Submit
                        </Button>
                    </Box>
                </Paper>
            </AdminDrawer>
        </div>
    );
};

export default AddDeliveryPartners;
