import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';

const data = [
    { x: 0, y: 2400 },
    { x: 1, y: 1398 },
    { x: 2, y: 9800 },
    { x: 3, y: 3908 },
    { x: 4, y: 4800 },
    { x: 5, y: 3800 },
    { x: 6, y: 4300 },
];

const OrdersProfitsLineCharts = () => {
    return (
        <Paper elevation={0} style={{ padding: '10px', borderRadius: '10px', height: '25%' }}>
           
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Paper
                        elevation={3}
                        style={{
                           
                            borderRadius: '10px',
                            backgroundColor: '#fff',
                        }}
                    >
                        <Typography variant="body2" style={{ color: '#777' }}>
                            Last 7 days Orders
                        </Typography>
                        <LineChart
                            xAxis={[{ data: data.map((point) => point.x) }]}
                            series={[{
                                data: data.map((point) => point.y),
                                color: '#8884d8',
                                showPoints: false,
                                strokeWidth: 2,
                            }]}
                            width={190}
                            height={100}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Paper
                        elevation={3}
                        style={{
                            borderRadius: '10px',
                            backgroundColor: '#fff',
                        }}
                    >
                        <Typography variant="body2" style={{ color: '#777' }}>
                            Last 7 days Profit
                        </Typography>
                        <LineChart
                            xAxis={[{ data: data.map((point) => point.x) }]}
                            series={[{
                                data: data.map((point) => point.y),
                                color: '#00C49F',
                                showPoints: false,
                                strokeWidth: 1,
                            }]}
                            width={190}
                            height={100}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default OrdersProfitsLineCharts;
