// src/api/userApi.js
import baseUrl from "../../config";

export const fetchUsers = async () => {
    try {
        const response = await fetch(`${baseUrl}users/customer`);
        const data = await response.json();
        return data.users; // Assuming 'data.users' contains the user array
    } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
    }
};

// Fetch user by ID
export const fetchUserById = async (userId) => {
    try {
        const response = await fetch(`${baseUrl}users/customer/${userId}`);
        const data = await response.json();
        return data;  // Assuming the API returns user data directly
    } catch (error) {
        console.error('Error fetching user details:', error);
        throw error;
    }
};

// src/api/userApi.js
export const fetchCatererOrders = async (userId) => {
    try {
        const response = await fetch(`${baseUrl}customers/${userId}/catererOrders`);
        if (!response.ok) {
            throw new Error('Failed to fetch caterer orders');
        }
        const data = await response.json();
        return data?.orders; // Assuming the API returns an array of orders
    } catch (error) {
        console.error('Error fetching caterer orders:', error);
        return [];
    }
};

// src/api/userApi.js
export const fetchRestaurantOrders = async (customerId) => {
    const response = await fetch(`${baseUrl}customers/${customerId}/restaurantOrders`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            // Add Authorization header if needed
        },
    });
    
    if (!response.ok) {
        throw new Error('Failed to fetch restaurant orders');
    }

    const data = await response.json();
    return data;
};

