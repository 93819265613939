import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AdminDrawer from '../../components/AdminDrawer/AdminDrawer';
import { Typography, Paper, TextField, Grid, Button, Select, MenuItem, FormControl, Box } from '@mui/material';
import { createRole, updateRole } from '../../api/userRoles/roles';
import CustomSnackbar from '../../components/CustomeSnackBar/CustomSnackbar';

const AddRoles = () => {
  const location = useLocation();
  const userData = location.state || {};

  const navigate = useNavigate();
  const [role, setRole] = useState(userData.role || '');
  const [fullName, setFullName] = useState(userData.name || '');
  const [email, setEmail] = useState(userData.email || '');
  const [phoneNumber, setPhoneNumber] = useState(userData.phoneNumber || '');
  const [password, setPassword] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const validateFields = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[6-9]\d{9}$/; // For Indian phone numbers
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
    const nameRegex = /^[A-Za-z\s]+$/;

    if (!fullName || !nameRegex.test(fullName)) {
      setSnackbar({
        open: true,
        message: 'Please enter a valid full name (letters and spaces only).',
        severity: 'error',
      });
      return false;
    }

    if (!email || !emailRegex.test(email)) {
      setSnackbar({
        open: true,
        message: 'Please enter a valid email address.',
        severity: 'error',
      });
      return false;
    }

    if (!phoneNumber || !phoneRegex.test(phoneNumber)) {
      setSnackbar({
        open: true,
        message: 'Please enter a valid phone number.',
        severity: 'error',
      });
      return false;
    }

    if (!userData.id && (!password || !passwordRegex.test(password))) {
      setSnackbar({
        open: true,
        message: 'Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character.',
        severity: 'error',
      });
      return false;
    }

    if (!role) {
      setSnackbar({
        open: true,
        message: 'Please select a role.',
        severity: 'error',
      });
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (!validateFields()) return;

    const payload = {
      fullName,
      email,
      phoneNumber,
      password: password || undefined, // Include password only if it's not empty
      role,
    };

    try {
      if (userData._id) {
        // Edit existing role
        await updateRole(userData._id, payload);  // Call the update API here
        setSnackbar({
          open: true,
          message: 'Role updated successfully!',
          severity: 'success',
        });
      } else {
        // Create new role
        await createRole(payload);  // Call the create API here
        setSnackbar({
          open: true,
          message: 'Role added successfully!',
          severity: 'success',
        });
      }

      // Navigate to /roles after the snackbar closes
      setTimeout(() => {
        navigate('/roles');
      }, 500);
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Error adding role. Please try again.',
        severity: 'error',
      });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <div style={{ backgroundColor: '#f2f2f2', padding: '20px', height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <AdminDrawer>
        <Paper
          elevation={0}
         sx={{
            margin: '0 auto',
            padding: '20px',
            backgroundColor: 'white',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '800px',
            width: '100%',
          }}
        >
          <Typography variant='h5' fontWeight='bolder' mb={3} gutterBottom>
            {userData._id ? 'Edit Role' : 'Add Role'}
          </Typography>
          <Box component="form" noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography component="legend">Full Name</Typography>
                <TextField
                  fullWidth
                  placeholder="Enter Full Name Here"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  name="name"
                  variant="outlined"
                  size="small"
                  sx={{ marginBottom: 2, marginTop: 1 }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography component="legend">Email</Typography>
                <TextField
                  fullWidth
                  placeholder="Enter Email Here"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                  variant="outlined"
                  size="small"
                  sx={{ marginBottom: 2, marginTop: 1 }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined" size="small" sx={{ marginBottom: 2, marginTop: 1 }}>
                  <Typography component="legend">Role</Typography>
                  <Select
                    value={role}
                    onChange={handleRoleChange}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select Role
                    </MenuItem>
                    <MenuItem value="admin">Admin</MenuItem>
                    <MenuItem value="accounts">Accounts</MenuItem>
                    <MenuItem value="customer_support">Customer Support</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography component="legend">Phone Number</Typography>
                <TextField
                  fullWidth
                  placeholder="Enter Phone Number Here"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  name="phoneNumber"
                  variant="outlined"
                  size="small"
                  sx={{ marginBottom: 2, marginTop: 1 }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              {/* Conditionally render password field only if not editing */}
              {!userData.id && (
                <Grid item xs={12} sm={6}>
                  <Typography component="legend">Password</Typography>
                  <TextField
                    fullWidth
                    type="password"
                    placeholder="Enter Password Here"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    name="password"
                    variant="outlined"
                    size="small"
                    sx={{ marginBottom: 2, marginTop: 1 }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              )}

              <Grid item xs={12} display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleSubmit}
                >
                  {userData.id ? 'Update' : 'Add'} Role
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </AdminDrawer>
      <CustomSnackbar
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={handleCloseSnackbar}
      />
    </div>
  );
};

export default AddRoles;
