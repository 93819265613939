import React, { useState, useEffect } from 'react';
import { TextField, Select, MenuItem, FormControl, Typography, Grid, Button, Box, Autocomplete } from '@mui/material';
import Map from '../../../components/Maps/Map';

const stateCityData = {
    Maharashtra: ['Mumbai', 'Pune', 'Nagpur'],
    Karnataka: ['Bengaluru', 'Mysuru', 'Hubli'],
    TamilNadu: ['Chennai', 'Coimbatore', 'Madurai'],
    Delhi: ['New Delhi', 'Dwarka', 'Rohini'],
    UttarPradesh: ['Lucknow', 'Kanpur', 'Agra'],
    WestBengal: ['Kolkata', 'Siliguri', 'Asansol'],
    Rajasthan: ['Jaipur', 'Udaipur', 'Jodhpur'],
    Gujarat: ['Ahmedabad', 'Surat', 'Vadodara'],
    Punjab: ['Chandigarh', 'Amritsar', 'Ludhiana'],
    Haryana: ['Gurugram', 'Faridabad', 'Ambala'],
};

const specializedAreasOptions = [
    'Birthday Party',
    'Corporate Party',
    'Marriage Party',
];

const flowerFilterOptions = [
    'Flowers',
    'Decorations',
];


const CatererForm = ({ onSubmit, initialValues }) => {
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedaddressType, setSelectedAddressType] = useState('');
    const [selectedAddress, setSelectedAddress] = useState('');
    const [formData, setFormData] = useState({
        ownerName: '',  // Fallback to empty string if no value
        mobileNo: '',
        email: '',
        deliveryTime: '',
        entityName: '',
        entityPhoneNumber: '',
        addressType: '',
        address: '',
        street: '',
        city: '',
        postalCode: '',
        serviceRange: '',
        waitTime: '',
        aboutUs: '',
        specializedAreas: [],  // Array fallback
        flowerFilter: [],      // Array fallback
        panNo: '',
        gstNo: '',
        fssaiNo: '',
        bussinessImage: null,  // Null fallback for image
        panImage: null,
        gstImage: null,
        fssaiImage: null,
        ownerPhoto: null,
        bussinessVideo: null,
    });

    // useEffect to update formData when initialValues change
    useEffect(() => {
        if (initialValues) {
            setFormData({
                ownerName: initialValues.ownerName || '',
                mobileNo: initialValues.mobileNo || '',
                email: initialValues.emailID || '',  // Note: You had `emailID` in the AddCateres, not `email`
                deliveryTime: initialValues.deliveryTime || '',
                entityName: initialValues.entityName || '',
                entityPhoneNumber: initialValues.entityPhoneNumber || '',
                addressType: initialValues.addressType || '',
                address: initialValues.address || '',
                street: initialValues.street || '',
                city: initialValues.city || '',
                postalCode: initialValues.postalCode || '',
                serviceRange: initialValues.serviceRange || '',
                waitTime: initialValues.waitTime || '',
                aboutUs: initialValues.aboutUs || '',
                specializedAreas: initialValues.specializedAreas || [],
                flowerFilter: initialValues.flowerFilter || [],
                panNo: initialValues.panNo || '',
                gstNo: initialValues.gstNo || '',
                fssaiNo: initialValues.fssaiNo || '',
                bussinessImage: initialValues.bussinessImage || null,
                panImage: initialValues.panImage || null,
                gstImage: initialValues.gstImage || null,
                fssaiImage: initialValues.fssaiImage || null,
                ownerPhoto: initialValues.ownerPhoto || null,
                businessVideo: initialValues.businessVideo || null,
            });
        }
    }, [initialValues]);  // This will re-run when initialValues change



    const handleStateChange = (event) => {
        setSelectedState(event.target.value);
        setSelectedCity(''); // Reset city when state changes
    };

    const handleCityChange = (event) => {
        setSelectedCity(event.target.value);
    };

    const handleAddressTypeChange = (event) => {
        const { value } = event.target;
        setSelectedAddressType(value);
        setFormData((prevData) => ({
            ...prevData,
            addressType: value,
        }));
    };

    const handleAddressChange = (address) => {
        setSelectedAddress(address);
    };


    const handleInputChange = (event) => {
        const { name, value, type, files } = event.target;

        if (type === 'file') {
            setFormData((prevData) => ({
                ...prevData,
                ...formData,
                [name]: files[0],
            }));
        } else if (name.includes('address')) {
            const field = name.split('.')[1];
            setFormData((prevData) => ({
                ...prevData,
                ...formData,
                address: {
                    ...prevData.address,
                    [field]: value,
                },
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                ...formData,
                [name]: value,
            }));
        }
    };

    const handleSpecializedAreasChange = (event, newValue) => {
        setFormData((prevData) => ({
            ...prevData,
            specializedAreas: newValue,
        }));
    };

    const handleFlowerFilterChange = (event, newValue) => {
        setFormData((prevData) => ({
            ...prevData,
            flowerFilter: newValue,
        }));
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        onSubmit(formData);
    };

    return (
        <form onSubmit={handleFormSubmit}>
            {/* Owner Name and Mobile No */}
            <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Owner Name</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter Owner Name Here"
                        name="ownerName"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.ownerName}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Mobile No</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter Mobile Number Here"
                        name="mobileNo"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.mobileNo}
                        onChange={handleInputChange}
                    />
                </Grid>
            </Grid>

            {/* Email ID and Delivery Time */}
            <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Email ID</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter Email ID Here"
                        name="email"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.email}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Delivery in days</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter Delivery Time"
                        name="deliveryTime"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.deliveryTime}
                        onChange={handleInputChange}
                    />
                </Grid>
            </Grid>

            {/* Entity Name and Entity Phone Number */}
            <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Entity Name</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter Entity Name Here"
                        name="entityName"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.entityName}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Entity Phone Number</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter Entity Number Here"
                        name="entityPhoneNumber"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.entityPhoneNumber}
                        onChange={handleInputChange}
                    />
                </Grid>
            </Grid>

            {/* State and City */}
            <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend" sx={{ marginBottom: 1.5 }}>State</Typography>
                    <FormControl fullWidth variant="outlined" size="small">
                        <Select value={selectedState} onChange={handleStateChange} displayEmpty>
                            <MenuItem value="" disabled>Select State</MenuItem>
                            {Object.keys(stateCityData).map((state) => (
                                <MenuItem key={state} value={state}>{state}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend" sx={{ marginBottom: 1.5 }}>City</Typography>
                    <FormControl fullWidth variant="outlined" size="small">
                        <Select
                            value={selectedCity}
                            onChange={handleCityChange}
                            displayEmpty
                            disabled={!selectedState}
                        >
                            <MenuItem value="" disabled>Select City</MenuItem>
                            {selectedState &&
                                stateCityData[selectedState].map((city) => (
                                    <MenuItem key={city} value={city}>{city}</MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Grid>

            </Grid>
            <Grid container spacing={2} style={{ flex: 1 }}>
                <Grid item xs={12} sm={4}>
                    <Typography component="legend">Street</Typography>
                    <FormControl fullWidth variant="outlined" size="small">
                        <TextField
                            fullWidth
                            placeholder="Enter street"
                            name="street"
                            variant="outlined"
                            size="small"
                            sx={{ marginBottom: 2, marginTop: 1 }}
                            InputLabelProps={{ shrink: true }}
                            value={formData.street}
                            onChange={handleInputChange}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <Typography component="legend">Postal Code</Typography>
                    <FormControl fullWidth variant="outlined" size="small">
                        <TextField
                            fullWidth
                            placeholder="Enter Postal Code"
                            name="postalCode"
                            variant="outlined"
                            size="small"
                            sx={{ marginBottom: 2, marginTop: 1 }}
                            InputLabelProps={{ shrink: true }}
                            value={formData.postalCode}
                            onChange={handleInputChange}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <Typography component="legend">Address Type</Typography>
                    <FormControl fullWidth variant="outlined" size="small">
                        <Select
                            sx={{ marginBottom: 2, marginTop: 1 }}
                            value={selectedaddressType}
                            onChange={handleAddressTypeChange}
                            displayEmpty
                        >
                            <MenuItem value="" disabled>
                                Select Address Type
                            </MenuItem>
                            <MenuItem value="Work">Work</MenuItem>
                            <MenuItem value="Home">Home</MenuItem>
                            <MenuItem value="Others">Others</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

             {/* Address */}
             <Grid container spacing={2} mb={2}>
                            <Grid item xs={12} style={{ height: '400px' }}>
                                <Map onAddressChange={handleAddressChange} mapContainerStyle={{ height: '100%' }} />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography component="legend">
                                    Address
                                </Typography>
                                <TextField
                                    disabled
                                    fullWidth
                                    placeholder="Enter Address"
                                    name="address"
                                    value={selectedAddress}
                                    variant="outlined"
                                    size="small"
                                    sx={{ marginBottom: 2, marginTop: 1 }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                        </Grid>
            {/* About Us */}
            <Grid container spacing={2} mb={2} >
                <Grid item xs={12}>
                    <Typography component="legend" sx={{ marginTop: 1.5 }}>About Us</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter About Us"
                        name="aboutUs"
                        variant="outlined"
                        multiline
                        rows={4}
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.aboutUs}
                        onChange={handleInputChange}
                    />
                </Grid>
            </Grid>

            {/* Specialized Areas */}
            <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Specialized Areas</Typography>
                    <Autocomplete
                        multiple
                        options={specializedAreasOptions}
                        getOptionLabel={(option) => option}
                        value={formData.specializedAreas}
                        onChange={handleSpecializedAreasChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Select Specialized Areas"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Flower Filter</Typography>
                    <Autocomplete
                        multiple
                        options={flowerFilterOptions}
                        getOptionLabel={(option) => option}
                        value={formData.flowerFilter}
                        onChange={handleFlowerFilterChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Select Flower Filter"
                            />
                        )}
                    />
                </Grid>
            </Grid>


            {/* PAN, GST, FSSAI, and Owner Photo */}
            <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">PAN Number</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter PAN Number"
                        name="panNo"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.panNo}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">GST Number</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter GST Number"
                        name="gstNo"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.gstNo}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">FSSAI Number</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter FSSAI Number"
                        name="fssaiNo"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.fssaiNo}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid container spacing={2} mb={2}>
                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">Business Video</Typography>
                        <input
                            type="file"
                            name="businessVideo"
                            accept="video/*"
                            onChange={handleInputChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">PAN Image</Typography>
                        <input
                            type="file"
                            name="panImage"
                            accept="image/*"
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">Bussiness Image</Typography>
                        <input
                            type="file"
                            name="bussinessImage"
                            accept="image/*"
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">GST Image</Typography>
                        <input
                            type="file"
                            name="gstImage"
                            accept="image/*"
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">FSSAI Image</Typography>
                        <input
                            type="file"
                            name="fssaiImage"
                            accept="image/*"
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">Owner Photo</Typography>
                        <input
                            type="file"
                            name="ownerPhoto"
                            accept="image/*"
                            onChange={handleInputChange}
                        />
                    </Grid>
                </Grid>
            </Grid>

            {/* Submit Button */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: 2 }}
                >
                    {initialValues ? 'Update' : 'Add'}
                </Button>
            </Box>
        </form>
    );
};

export default CatererForm;
