import React from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper
} from '@mui/material';

const PriceSummaryPayout = ({ paymentData }) => {  
  return (
    <TableContainer component={Paper} sx={{ mt: 3 }}>
      <Table aria-label="payment breakdown">
        <TableHead>
          <TableRow>
            <TableCell sx={{ border: '1px solid #e0e0e0' }}><strong>Payment Stage</strong></TableCell>
            <TableCell sx={{ border: '1px solid #e0e0e0' }}><strong>Amount</strong></TableCell>
            <TableCell sx={{ border: '1px solid #e0e0e0' }}><strong>Status</strong></TableCell>
            <TableCell sx={{ border: '1px solid #e0e0e0' }}><strong>Payment Date</strong></TableCell>
            <TableCell sx={{ border: '1px solid #e0e0e0' }}><strong>Payment Mode</strong></TableCell>
            <TableCell sx={{ border: '1px solid #e0e0e0' }}><strong>Transaction ID</strong></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* Total Amount */}
          <TableRow>
            <TableCell sx={{ border: '1px solid #e0e0e0' }}><strong>Total Amount</strong></TableCell>
            <TableCell sx={{ border: '1px solid #e0e0e0' }}>₹{paymentData?.totalAmount?.toFixed(2)}</TableCell>
            <TableCell sx={{ border: '1px solid #e0e0e0' }}>{paymentData?.paymentStatus}</TableCell>
            <TableCell sx={{ border: '1px solid #e0e0e0' }}>{new Date(paymentData?.finalPaymentDueDate).toDateString()}</TableCell>
            <TableCell sx={{ border: '1px solid #e0e0e0' }}>-</TableCell>
            <TableCell sx={{ border: '1px solid #e0e0e0' }}>-</TableCell>
          </TableRow>

          {/* Advance Payment */}
          <TableRow>
            <TableCell sx={{ border: '1px solid #e0e0e0' }}>Advance Payment</TableCell>
            <TableCell sx={{ border: '1px solid #e0e0e0' }}>₹{paymentData?.initialPayment?.toFixed(2)}</TableCell>
            <TableCell sx={{ border: '1px solid #e0e0e0' }}>{paymentData?.initialPaymentSchedule?.initialPaymentStatus}</TableCell>
            <TableCell sx={{ border: '1px solid #e0e0e0' }}>{new Date(paymentData?.orderDate).toDateString()}</TableCell>
            <TableCell sx={{ border: '1px solid #e0e0e0' }}>{paymentData?.initialPaymentSchedule?.initialPaymentMethod}</TableCell>
            <TableCell sx={{ border: '1px solid #e0e0e0' }}>{paymentData?.initialPaymentDetails?.razorpay_order_id}</TableCell>
          </TableRow>

          {/* Second Payment */}
          <TableRow>
            <TableCell sx={{ border: '1px solid #e0e0e0' }}>Second Payment</TableCell>
            <TableCell sx={{ border: '1px solid #e0e0e0' }}>₹{paymentData?.secondPayment?.toFixed(2)}</TableCell>
            <TableCell sx={{ border: '1px solid #e0e0e0' }}>{paymentData?.secondPaymentSchedule?.status}</TableCell>
            <TableCell sx={{ border: '1px solid #e0e0e0' }}>{new Date(paymentData?.secondPaymentDueDate).toDateString()}</TableCell>
            <TableCell sx={{ border: '1px solid #e0e0e0' }}>{paymentData?.secondPaymentSchedule?.secondPaymentMethod}</TableCell>
            <TableCell sx={{ border: '1px solid #e0e0e0' }}>{paymentData?.secondPayment?.transactionId}</TableCell>
          </TableRow>

          {/* Final Payment */}
          <TableRow>
            <TableCell sx={{ border: '1px solid #e0e0e0' }}>Final Payment</TableCell>
            <TableCell sx={{ border: '1px solid #e0e0e0' }}>₹{paymentData?.finalPayment?.toFixed(2)}</TableCell>
            <TableCell sx={{ border: '1px solid #e0e0e0' }}>{paymentData?.finalPaymentSchedule?.status}</TableCell>
            <TableCell sx={{ border: '1px solid #e0e0e0' }}>{new Date(paymentData?.finalPaymentDueDate).toDateString()}</TableCell>
            <TableCell sx={{ border: '1px solid #e0e0e0' }}>{paymentData?.finalPaymentSchedule?.finalPaymentMethod}</TableCell>
            <TableCell sx={{ border: '1px solid #e0e0e0' }}>{paymentData?.finalPayment?.transactionId}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PriceSummaryPayout;
