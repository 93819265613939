import React from 'react';
import { Typography, Rating, Chip } from '@mui/material';
import AdminDrawer from '../../../components/AdminDrawer/AdminDrawer';
import ReportsTable from '../../../components/DataTables/ReportsTables/ReportsTable';

const reportData = [
    {
        id: 1,
        name: 'Spice Symphony',
        email: 'spicesymphony@example.com',
        phone: '+91 9876543210',
        ratings: 4.7,
        address: '15 Spice St, New Delhi',
        area: 'Connaught Place',
        totalOrders: 220,
        totalEarnings: '₹2,20,000',
        pendingPayouts: '₹40,000',
        payoutsCompleted: '₹1,80,000',
        status: 'Active',
        date: '2024-08-01',
    },
    {
        id: 2,
        name: 'Masala Magic',
        email: 'masalamagic@example.com',
        phone: '+91 8765432109',
        ratings: 4.5,
        address: '22 Masala Rd, Mumbai',
        area: 'Bandra',
        totalOrders: 180,
        totalEarnings: '₹1,80,000',
        pendingPayouts: '₹30,000',
        payoutsCompleted: '₹1,50,000',
        status: 'Active',
        date: '2024-08-05',
    },
    {
        id: 3,
        name: 'Curry Delight',
        email: 'currydelight@example.com',
        phone: '+91 7654321098',
        ratings: 4.8,
        address: '30 Curry Ln, Bengaluru',
        area: 'Indiranagar',
        totalOrders: 240,
        totalEarnings: '₹2,40,000',
        pendingPayouts: '₹50,000',
        payoutsCompleted: '₹1,90,000',
        status: 'Active',
        date: '2024-08-10',
    },
    {
        id: 4,
        name: 'Tandoor Treat',
        email: 'tandoortreat@example.com',
        phone: '+91 6543210987',
        ratings: 4.6,
        address: '45 Tandoor Ave, Chennai',
        area: 'Anna Nagar',
        totalOrders: 160,
        totalEarnings: '₹1,60,000',
        pendingPayouts: '₹20,000',
        payoutsCompleted: '₹1,40,000',
        status: 'Active',
        date: '2024-08-12',
    },
    {
        id: 5,
        name: 'Biryani Palace',
        email: 'biryanipalace@example.com',
        phone: '+91 5432109876',
        ratings: 4,
        address: '60 Biryani Blvd, Hyderabad',
        area: 'Banjara Hills',
        totalOrders: 300,
        totalEarnings: '₹3,00,000',
        pendingPayouts: '₹60,000',
        payoutsCompleted: '₹2,40,000',
        status: 'InActive',
        date: '2024-08-15',
    },
    {
        id: 6,
        name: 'Naan Nirvana',
        email: 'naannirvana@example.com',
        phone: '+91 4321098765',
        ratings: 4.3,
        address: '80 Naan St, Kolkata',
        area: 'Park Street',
        totalOrders: 190,
        totalEarnings: '₹1,90,000',
        pendingPayouts: '₹35,000',
        payoutsCompleted: '₹1,55,000',
        status: 'Active',
        date: '2024-08-20',
    },
    {
        id: 7,
        name: 'Dosa Delight',
        email: 'dosadelight@example.com',
        phone: '+91 3210987654',
        ratings: 4.2,
        address: '90 Dosa Rd, Chennai',
        area: 'T. Nagar',
        totalOrders: 210,
        totalEarnings: '₹2,10,000',
        pendingPayouts: '₹45,000',
        payoutsCompleted: '₹1,65,000',
        status: 'Active',
        date: '2024-08-25',
    },
    {
        id: 8,
        name: 'Kebab King',
        email: 'kebabking@example.com',
        phone: '+91 2109876543',
        ratings: 4.4,
        address: '70 Kebab Ln, Delhi',
        area: 'Saket',
        totalOrders: 230,
        totalEarnings: '₹2,30,000',
        pendingPayouts: '₹55,000',
        payoutsCompleted: '₹1,75,000',
        status: 'Active',
        date: '2024-08-28',
    },
];


const columns = [
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'phone', headerName: 'Phone Number', flex: 1 },
    {
        field: 'ratings',
        headerName: 'Ratings',
        flex: 1.2,
        sortable: true,
        renderCell: (params) => (
            <Rating value={params.value} readOnly size="small" />
        ),
    },
    { field: 'address', headerName: 'Address', flex: 1 },
    { field: 'area', headerName: 'Area', flex: 1 },
    { field: 'totalOrders', headerName: 'Total Orders', flex: 1 },
    { field: 'totalEarnings', headerName: 'Total Restaurant Earnings', flex: 1 },
    { field: 'pendingPayouts', headerName: 'Pending Payouts', flex: 1 },
    { field: 'payoutsCompleted', headerName: 'Payouts Completed', flex: 1 },
    {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        sortable: true,
        renderCell: (params) => (
            <Chip label={params.value} color={params.value === 'Active' ? 'success' : 'warning'} />
        ),
    },
    { field: 'date', headerName: 'Date', flex: 1, sortable: true },
];


const RestaurantReports = () => {
    return (
        <div style={{ backgroundColor: '#f2f2f2', height: '100vh' }}>
            <AdminDrawer>
                <div style={{ padding: '16px' }}>
                    <Typography variant='h4' fontWeight='bolder'>Restaurant Reports</Typography>
                    <ReportsTable
                        rows={reportData}
                        columns={columns}
                    />
                </div>
            </AdminDrawer>
        </div>
    );
};

export default RestaurantReports;
