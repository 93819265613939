import React from 'react';
import { Paper, CardContent, Typography, List, ListItem, ListItemAvatar, Avatar, ListItemText } from '@mui/material';
import { green } from '@mui/material/colors';


const recentUsers = [
    { name: 'Ajay', orders: 50, revenue: 4567.88, logo: 'https://randomuser.me/api/portraits/men/6.jpg' },
    { name: 'Anjali Sharma', orders: 45, revenue: 14500.75, logo: 'https://randomuser.me/api/portraits/women/7.jpg' },
    { name: 'Bharath', orders: 42, revenue: 9800.60, logo: 'https://randomuser.me/api/portraits/men/8.jpg' },
    { name: 'Sonia', orders: 38, revenue: 6700.83, logo: 'https://randomuser.me/api/portraits/women/9.jpg' },
    { name: 'Sunil', orders: 30, revenue: 4300.50, logo: 'https://randomuser.me/api/portraits/men/10.jpg' },
];

const DashBoardOrdersCard = () => {
    return (
        <Paper sx={{ maxWidth: 400, borderRadius: 2,}} elevation={0}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Recent Orders
                </Typography>
                <List>
                    {recentUsers?.map((restaurant, index) => (
                        <ListItem key={index} disableGutters>
                            <ListItemAvatar>
                                <Avatar src={restaurant.logo} alt={restaurant.name} />
                            </ListItemAvatar>
                            <ListItemText
                                primary={restaurant.name}
                                secondary={`Orders: ${restaurant.orders}`}
                            />
                            <Typography variant="body2" sx={{ color: green[500], fontWeight: 'bold' }}>
                                ₹ {restaurant.revenue.toFixed(2)}
                            </Typography>
                        </ListItem>
                    ))}
                </List>
            </CardContent>
        </Paper>
    );
};

export default DashBoardOrdersCard;
