import React from 'react';
import { Grid, Paper, Typography, Box } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import RestaurantMenuOutlinedIcon from '@mui/icons-material/RestaurantMenuOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';

const DashboardStatistics = () => {
    const stats = [
        { icon: <RestaurantMenuOutlinedIcon style={{ color: '#F21374' }} />, label: 'Total Caters', value: '229' },
        { icon: <RestaurantIcon style={{ color: '#F2A774' }} />, label: 'Total Restaurants', value: '479' },
        { icon: <LocalShippingIcon style={{ color: '#7AE582', }} />, label: 'Total Delivery Partners', value: '668' },
        { icon: <PeopleIcon style={{ color: '#65C6C4' }} />, label: 'Total Customers', value: '1403' },
        { icon: <ShoppingCartOutlinedIcon style={{ color: '#FFB6C1' }} />, label: 'Fesste Mart', value: '34' },
        { icon: <AccountBalanceWalletIcon style={{ color: '#99E7F5' }} />, label: 'Total Earnings', value: '119810.57' },
        { icon: <TrendingUpIcon style={{ color: '#F16F6F' }} />, label: 'Today Earnings', value: '0' },
        { icon: <MonetizationOnIcon style={{ color: '#FFD700' }} />, label: 'Total Admin Commission', value: '9067.9' },
        { icon: <LocalMallIcon style={{ color: '#7AE582' }} />, label: 'Total Restaurant Commission', value: '95656.7' },
        { icon: <LocalMallIcon style={{ color: '#FF4500' }} />, label: 'Total Delivery Boy Commission', value: '10671.11' },
    ];

    return (
        <Paper elevation={0} style={{ padding: '10px', borderRadius: '5px' }}>
            <Grid container spacing={2}>
                {stats.map((stat, index) => (
                    <Grid item xs={12} sm={6} md={2.4} key={index}>
                        <Box
                            display="flex"
                            alignItems="center"
                            style={{
                                backgroundColor: '#f9f9f9',
                                padding: '10px',
                                borderRadius: '10px',
                            }}
                        >
                            <Box mr={2}>{stat.icon}</Box>
                            <Box>
                                <Typography variant="h6" style={{ fontWeight: 'bold', color: '#333' }}>
                                    {stat.value}
                                </Typography>
                                <Typography variant="caption" style={{ color: '#777' }}>
                                    {stat.label}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Paper>
    );
};

export default DashboardStatistics;
