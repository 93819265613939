import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, IconButton, InputAdornment, Typography, Box } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const ResetPasswordDialog = ({ open, onClose, onConfirm, title }) => {
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        if (!open) {
            setPassword(''); // Clear password field when the dialog is closed
            setShowPassword(false); // Hide password when dialog is reopened
        }
    }, [open]);

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleConfirm = () => {
        onConfirm(password);
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
            <DialogTitle>
                <Typography variant="h6" fontWeight="bold">
                    {title}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Box mt={2}>
                    <TextField
                        placeholder="Enter new password"
                        variant="outlined"
                        type={showPassword ? 'text' : 'password'}
                        fullWidth
                        value={password}
                        onChange={handlePasswordChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={togglePasswordVisibility}>
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="inherit" variant="contained">
                    <Typography fontSize={14} fontWeight='bold'>Cancel</Typography>
                </Button>
                <Button onClick={handleConfirm} color="primary" variant="contained" disabled={!password}>
                   <Typography fontSize={14} fontWeight='bold'>Reset Password</Typography> 
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ResetPasswordDialog;
