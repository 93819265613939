import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Paper, Typography } from '@mui/material';
import AdminDrawer from '../../../components/AdminDrawer/AdminDrawer';
import CustomSnackbar from '../../../components/CustomeSnackBar/CustomSnackbar';
import RestaurantForm from './RestaurantForm';
import PhoneNumberModal from './PhoneNumberModal';
import { registerRestaurant, fetchRestaurantById, updateRestaurantById, fetchServiceProviderByPhone } from '../../../api/servicePartners/RestaurantApis'; // Assuming updateRestaurantById exists

const AddRestaurant = () => {


  const [serviceProviderId, setServiceProviderId] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  console.log('serviceProviderId', serviceProviderId);

  const handlePhoneNumberSubmit = async (phoneNumber) => {
    try {
      const response = await fetchServiceProviderByPhone(phoneNumber);

      if (response.data.isRegistered) {
        setServiceProviderId(response.data._id);
        // Update formData with service provider details
        setFormData((prevData) => ({
          ...prevData,
          ownerName: response.data.spName,
          ownerNumber: response.data.spPhone,
          ownerEmail: response.data.spEmail,
        }));
      } else {
        alert('Not a registered customer.');
      }
    } catch (error) {
      console.error('Error fetching service provider:', error);
    } finally {
      setModalOpen(false); // Close modal after submission
    }
  };


  const { id } = useParams(); // Get restaurant id from the route params
  const [formData, setFormData] = useState({
    panImage: null,
    gstImage: null,
    fssaiImage: null,
    restaurantImage: null,
    serviceProviderId: '',
    restName: '',
    restAddress: {
      street: '',
      city: '',
      postalCode: '',
    },
    ownerName: '',
    ownerNumber: '',
    ownerEmail: '',
    managerNumber: '',
    deliveryType: '',
    restDescription: '',
    address: '',
    cuisine: '',
    operationalTime: [],
    fessteApproach: false,
    panNo: '',
    gstNo: '',
    fssaiNo: '',
    bankDetails: {
      accountNumber: '',
      accountType: '',
      ifscCode: '',
    },
    isDeliveryTimingsSameAsOperational: false,
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [selectedAddress, setSelectedAddress] = useState('');

  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const navigate = useNavigate();

  // Fetch restaurant details if the ID exists
  useEffect(() => {
    if (id) {
      fetchRestaurantDetails(id);
    }
  }, [id]);

  const fetchRestaurantDetails = async (restaurantId) => {
    const result = await fetchRestaurantById(restaurantId);
    if (result.success) {
      setFormData(result.data); // Assuming the API returns the restaurant data in `data`
    } else {
      setSnackbarMessage(result.message || 'Failed to fetch restaurant details.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleAddressChange = (address) => {
    setSelectedAddress(address);
  };

  const handleSubmit = async (formData) => {
    const formDataObj = new FormData();

    // Append files and other fields to FormData
    if (formData.panImage) formDataObj.append('panImage', formData.panImage);
    if (formData.gstImage) formDataObj.append('gstImage', formData.gstImage);
    if (formData.fssaiImage) formDataObj.append('fssaiImage', formData.fssaiImage);
    if (formData.restaurantImage) formDataObj.append('restaurantImage', formData.restaurantImage);

    // Append other fields (example)
    formDataObj.append('serviceProviderId', formData.serviceProviderId);
    formDataObj.append('restName', formData.restName);
    formDataObj.append('spEmail', formData.ownerEmail);
    formDataObj.append('spName', formData.ownerName);
    formDataObj.append('spPhone', formData.ownerNumber);
    formDataObj.append('restAddress[street]', formData.restAddress.street);
    formDataObj.append('restAddress[city]', formData.restAddress.city);
    formDataObj.append('restAddress[postalCode]', formData.restAddress.postalCode);
    formDataObj.append('managerNumber', formData.managerNumber);
    formDataObj.append('deliveryType', formData.deliveryType);
    formDataObj.append('restDescription', formData.restDescription);
    formDataObj.append('cuisine', formData.cuisine);

    // Operational time and other fields
    formData.operationalTime.forEach((time, index) => {
      formDataObj.append(`operationalTime[${index}][day]`, time.day);
      formDataObj.append(`operationalTime[${index}][startTime]`, time.startTime);
      formDataObj.append(`operationalTime[${index}][endTime]`, time.endTime);
    });

    formDataObj.append('fessteApproach', formData.fessteApproach ? 'true' : 'false');
    formDataObj.append('panNo', formData.panNo);
    formDataObj.append('gstNo', formData.gstNo);
    formDataObj.append('fssaiNo', formData.fssaiNo);
    formDataObj.append('bankDetails[accountNumber]', formData.bankDetails.accountNumber);
    formDataObj.append('bankDetails[accountType]', formData.bankDetails.accountType);
    formDataObj.append('bankDetails[ifscCode]', formData.bankDetails.ifscCode);
    formDataObj.append('isDeliveryTimingsSameAsOperational', formData.isDeliveryTimingsSameAsOperational ? 'true' : 'false');

    let result;
    if (id) {
      result = await updateRestaurantById(id, formDataObj); // Call update API if ID exists
    } else {
      result = await registerRestaurant(formDataObj); // Otherwise call the register API
    }

    if (result.success) {
      setSnackbarMessage(id ? 'Restaurant updated successfully!' : 'Restaurant added successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);

      setTimeout(() => {
        navigate('/restaurants');
      }, 500);
    } else {
      setSnackbarMessage(result.message || 'Failed to save restaurant. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div className="AddRestaurant">
      <AdminDrawer>
        <Paper
          elevation={0}
          sx={{
            margin: '0 auto',
            padding: '20px',
            backgroundColor: 'white',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="h5" fontWeight="bold" gutterBottom mb={3}>
            {id ? 'Edit Restaurant Details' : 'Add Restaurant Details'}
          </Typography>
          <Grid container spacing={2}>
            <PhoneNumberModal
              open={isModalOpen}
              onClose={handleModalClose}
              onSubmit={handlePhoneNumberSubmit}
            />
            <Grid item xs={12}>
              <Typography
                variant="body1"
                color="primary"
                onClick={handleModalOpen}
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
              >
                {/* Are you an existing customer? */}
              </Typography>
              {formData.restName ? ( // Conditional rendering
                <RestaurantForm
                  set
                  isModalOpen={isModalOpen}
                  setModalOpen={setModalOpen}
                  serviceProviderId={serviceProviderId}
                  handlePhoneNumberSubmit={handlePhoneNumberSubmit}
                  selectedAddress={selectedAddress}
                  handleAddressChange={handleAddressChange}
                  onSubmit={handleSubmit}
                  initialValues={formData} // Pass the initial values
                />
              ) : (
                <RestaurantForm
                  isModalOpen={isModalOpen}
                  setModalOpen={setModalOpen}
                  serviceProviderId={serviceProviderId}
                  handlePhoneNumberSubmit={handlePhoneNumberSubmit}
                  selectedAddress={selectedAddress}
                  handleAddressChange={handleAddressChange}
                  onSubmit={handleSubmit}
                />
              )}
            </Grid>
          </Grid>
        </Paper>
      </AdminDrawer>

      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </div>
  );
};

export default AddRestaurant;
