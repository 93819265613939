import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AdminDrawer from "../../../components/AdminDrawer/AdminDrawer";
import { fetchCaterers } from "../../../api/servicePartners/CaterersApis";
import {
  IconButton,
  Tooltip,
  Typography,
  styled,
  Paper,
  Button,
  Dialog, DialogContent, DialogTitle,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useDialog } from "../../../hooks/useDialog";
import { renderCellWithMore } from "../../../components/ResuableComponents/ViewMoreComponents/ViewMorePopup";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddIcon from "@mui/icons-material/Add";
import DataTable from "../../../components/DataTables/DataTable";
import ConfirmDialog from "../../../components/ResuableComponents/ConfirmDialog";
import { deleteCatererById } from "../../../api/servicePartners/CaterersApis";
import CustomSnackbar from "../../../components/CustomeSnackBar/CustomSnackbar";

const ButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: theme.spacing(2),
}));

const Caterers = () => {
  const { dialogContent, dialogOpen, handleOpenDialog, handleCloseDialog } = useDialog();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const navigate = useNavigate();

  const handleAddCatererClick = () => {
    navigate("/add-caterers");
  };

  const handleViewClick = (catererId, serviceProviderId) => {
    navigate(`/caterer-details/${serviceProviderId}`, {
      state: { catererId, serviceProviderId }, // Passing both catererId and serviceProviderId
    });
  };

  // Fetch the caterers' data
  useEffect(() => {
    const getCaterers = async () => {
      try {
        const caterers = await fetchCaterers();
        const mappedData = caterers.map((item, index) => ({
          id: index + 1,
          catererId: item._id,
          serviceProviderId: item.catID,
          businessName: item.businessName,
          address: `${item?.address?.street}, ${item?.address?.city}, ${item?.address?.postalCode}`,
          contactNumber: item.businessPhoneNumber,
          email: item.serviceProvider?.spEmail,
          ownerName: item.serviceProvider?.spName,
          ownerImage: item.ownerPhoto,
          ownerNumber: item.serviceProvider?.spPhone,
          ownerEmail: item.serviceProvider?.spEmail,
          registeredOn: new Date(item.createdAt).toLocaleString(),
          createdOn: new Date(item.createdAt).toLocaleString(),
          businessImage: item.businessProfilePhoto,
          fessteApproach: item.fessteApproach
        }));
        setData(mappedData);
      } catch (error) {
        console.error("Failed to fetch caterers:", error);
      } finally {
        setLoading(false);
      }
    };
    getCaterers();
  }, []);

  const handleEditClick = (rowData) => {
    navigate(`/edit-caterers/${rowData}`, { state: rowData });
  };

  const handleDeleteClick = (rowData) => {
    setSelectedRow(rowData);
    setOpenDialog(true);
  };


  const confirmDelete = async () => {
    if (selectedRow) {
      try {
        await deleteCatererById(selectedRow.catererId);
        // Update the data by filtering out the deleted caterer
        setData(data.filter((row) => row.catererId !== selectedRow.catererId));
        setSnackbarMessage('Caterer deleted successfully');
        setSnackbarOpen(true);  // Show snackbar for success
      } catch (error) {
        console.error('Error deleting Caterer:', error);
        setSnackbarMessage('Failed to delete Caterer');
        setSnackbarOpen(true);  // Show snackbar for error
      } finally {
        setOpenDialog(false);
      }
    }
  };


  const handleCloseDialog1 = () => {
    setOpenDialog(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };



  return (
    <div style={{ backgroundColor: '#f2f2f2' }}>
      <AdminDrawer>
        <Typography variant="h4" fontWeight="bolder">
          Caterers
        </Typography>

        <Paper elevation={0} sx={{ padding: "14px", backgroundColor: "#fff" }}>
          <ButtonContainer>
            <Button
              variant="contained"
              color="success"
              startIcon={<AddIcon />}
              onClick={handleAddCatererClick}
            >
              Add Caterer
            </Button>
          </ButtonContainer>
          {loading ? (
            <Typography>Loading...</Typography>
          ) : (

            <DataTable rows={data} columns={[

              { field: "id", headerName: "Id", minWidth: 10, sortable: false },
              {
                field: "serviceProviderId",
                headerName: "Service Provider ID",
                minWidth: 240,
                sortable: true,
                renderCell: (params) => {
                  const text = params.value || ''; // Ensure value is a string
              
                  return (
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleViewClick(params.row.catererId, params.row.serviceProviderId)}
                    >
                      {text}
                    </div>
                  );
                },
              },
              {
                field: "businessImage",
                headerName: "Business Image",
                type: 'image',
                minWidth: 160,
                sortable: false,
                renderCell: (params) => (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',   // Horizontally center the image
                      alignItems: 'center',       // Vertically center the image
                      width: '100%',              // Ensure the div takes full width of the cell
                      height: '100%',             // Ensure the div takes full height of the cell
                    }}
                  >
                    <img
                      src={params.value !== 'N/A' ? params.value : 'placeholder-image-url.jpg'} // Placeholder for broken images
                      alt="Item"
                      style={{
                        width: '100px',        // Fixed width
                        height: '60px',        // Fixed height
                        borderRadius: '4px',   // Rounded corners
                        objectFit: 'cover',     // Cover the area while maintaining aspect ratio
                      }}
                    />
                  </div>

                ),
              },
              {
                field: "businessName",
                headerName: "Business Name",
                minWidth: 250,
                sortable: true,
                renderCell: (params) => {
                  const text = params.value || ''; // Ensure value is a string
              
                  return (
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleViewClick(params.row.catererId, params.row.serviceProviderId)}
                    >
                      {text}
                    </div>
                  );
                },
              },
              {
                field: "address", headerName: "Address", minWidth: 250,
                sortable: true,
                type: 'text',
                renderCell: (params) => {
                  const text = params.value || ''; // Ensure value is a string
                  return renderCellWithMore(text, handleOpenDialog);
                },
              },
              {
                field: "contactNumber",
                headerName: "Contact Number",
                minWidth: 200,
                sortable: true,
              },
              { field: "email", headerName: "Email", minWidth: 160, sortable: true },
              {
                field: "ownerName",
                headerName: "Owner Name",
                minWidth: 200,
                sortable: true,
              },
              {
                field: "ownerImage",
                type: 'image',
                headerName: "Owner Image",
                minWidth: 160,
                sortable: false,
                renderCell: (params) => (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',   // Horizontally center the image
                      alignItems: 'center',       // Vertically center the image
                      width: '100%',              // Ensure the div takes full width of the cell
                      height: '100%',             // Ensure the div takes full height of the cell
                    }}
                  >
                    <img
                      src={params.value}
                      alt="Owner"
                      style={{
                        width: "45px",
                        height: "45px",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                ),
              },
              {
                field: "ownerNumber",
                headerName: "Owner Number",
                minWidth: 200,
                sortable: true,
              },
              {
                field: "registeredOn",
                headerName: "Registered On",
                minWidth: 200,
                sortable: true,
              },
              {
                field: "fessteApproach",
                headerName: "Item Creation Request",
                minWidth: 250,
                sortable: true,
                renderCell: (params) => {
                  return params.value ? "Access Granted" : "No Access";
                },
              },
              
              {
                field: "Actions",
                headerName: "Actions",
                type: 'actions',
                minWidth: 160,
                sortable: false,
                renderCell: (params) => (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Tooltip title="View">
                      <IconButton
                       sx={{ color: '#87CEEB', marginRight: '8px' }}
                        onClick={() =>
                          handleViewClick(
                            params.row.catererId,
                            params.row.serviceProviderId
                          )
                        }
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit">
                      <IconButton color="success" onClick={() => handleEditClick(params.row.catererId)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton color="error" onClick={() => handleDeleteClick(params.row)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                ),
              },

            ]} />
          )}
        </Paper>
        <ConfirmDialog
          open={openDialog}
          onClose={handleCloseDialog1}
          onConfirm={confirmDelete}
          title="Confirm Deletion"
          content="Are you sure you want to delete this Caterer?"
        />
        <CustomSnackbar
          open={snackbarOpen}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
          severity={snackbarMessage.toLowerCase().includes('successfully') ? 'success' : 'error'}
        />
        <Dialog open={dialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>Details</DialogTitle>
          <DialogContent>
            <Typography>{dialogContent}</Typography>
          </DialogContent>
          <Button onClick={handleCloseDialog} color="primary">Close</Button>
        </Dialog>
      </AdminDrawer>
    </div>
  );
};

export default Caterers;
