import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Button,
  CircularProgress,
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import AdminDrawer from "../../../components/AdminDrawer/AdminDrawer";
import PayoutTable from "../../../components/DataTables/PayoutTables/PayoutTable";
import { fetchCatererPayouts } from "../../../api/payouts/payoutsApi";
import { useDialog } from "../../../hooks/useDialog";
import { renderCellWithMore } from "../../../components/ResuableComponents/ViewMoreComponents/ViewMorePopup";

const CaterersPayout = () => {
  const { dialogContent, dialogOpen, handleOpenDialog, handleCloseDialog } =
    useDialog();

  const navigate = useNavigate();
  const [catererData, setCatererData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getCatererPayouts = async () => {
      try {
        const data = await fetchCatererPayouts();
        console.log(data);

        const formattedData = data.caterers.map((item, index) => ({
          id: item.catererId, // Add this line to ensure each row has a unique id
          siNo: index + 1,
          catererId: item?.catererId || "N/A",
          catID: item?.catID,
          catName: item?.businessName || "N/A",
          phoneNumber: item?.businessPhoneNumber || "N/A",
          totalOrders: item?.totalOrders || 0,
          acceptedOrders: item?.acceptedOrders || 0,
          rejectedOrders: item?.rejectedOrders || "N/A",
          totalGrossAmount: item.totalGrossAmount || "N/A",
        }));

        setCatererData(formattedData);
      } catch (error) {
        setError(error.message);
        console.error("Error fetching caterer payouts:", error);
      } finally {
        setLoading(false);
      }
    };

    getCatererPayouts();
  }, []);

  console.log(catererData);
  // Handle payment action
  const handleMakePayment = (row) => {
    navigate(`/payment-dashboard/caterer/${row.catererId}`);
  };

  const handleCatererDetails = (catererId) => {
    navigate(`/caterer-details/${catererId}`, {
      state: { catererId }, // Passing both catererId and serviceProviderId
    });
  };

  // Define table columns
  const columns = [
    { field: "siNo", headerName: "ID", minWidth: 10 },
    {
      field: "catID",
      headerName: "Caterer ID",
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Typography
            onClick={() => handleCatererDetails(params.row.catererId)}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "14px",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            {params.value} {/* Caterer name displayed here */}
          </Typography>
        );
      },
    },
    {
      field: "catName",
      headerName: "Caterer Name",
      minWidth: 250,
      sortable: true,
      renderCell: (params) => {
        return (
          <Typography
            onClick={() => handleCatererDetails(params.row.catererId)}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "14px",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            {params.value} {/* Caterer name displayed here */}
          </Typography>
        );
      },
    },
    { field: "phoneNumber", headerName: "Caterer Contact", minWidth: 200 },
    { field: "totalOrders", headerName: "Total Orders", minWidth: 200 },
    { field: "acceptedOrders", headerName: "Pending Orders", minWidth: 250 },
    { field: "rejectedOrders", headerName: "Cancelled Orders", minWidth: 200 },
    {
      field: "totalGrossAmount",
      headerName: "Revenue Generation",
      minWidth: 220,
    },

    {
      field: "action",
      headerName: "Action",
      minWidth: 200,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="success"
          onClick={() => handleMakePayment(params.row)}
        >
          <Typography fontSize="14px" textTransform="none" fontWeight="bold">
            Make Payment
          </Typography>
        </Button>
      ),
    },
  ];

  console.log("catererData", catererData);

  return (
    <div style={{ backgroundColor: "#f2f2f2", height: "100vh" }}>
      <AdminDrawer>
        <div style={{ padding: "16px" }}>
          <Typography variant="h4" fontWeight="bolder">
            Caterers Payout
          </Typography>
          {loading ? (
            <CircularProgress />
          ) : error ? (
            <Alert severity="error">{error}</Alert>
          ) : (
            <PayoutTable
              rows={catererData}
              columns={columns}
              getRowId={(row) => row.catererId}
            />
          )}
        </div>
        <Dialog open={dialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>Details</DialogTitle>
          <DialogContent>
            <Typography>{dialogContent}</Typography>
          </DialogContent>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </Dialog>
      </AdminDrawer>
    </div>
  );
};

export default CaterersPayout;
