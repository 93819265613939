import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Typography, Tabs, Tab, Box, CircularProgress, Paper, Divider, Grid, Card,Button,IconButton, Dialog } from "@mui/material";
import { Download as DownloadIcon } from '@mui/icons-material';
import AdminDrawer from "../../../../components/AdminDrawer/AdminDrawer";
import baseUrl from "../../../../config";

const LightingDetails = () => {
  const { state } = useLocation();
  const { id } = state || {};
  const [loading, setLoading] = useState(true);
  const [lightingDetails, setLightingDetails] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [open, setOpen] = useState(false);  // State to control the modal
  const [selectedImage, setSelectedImage] = useState(null); // State to store the selected image

  useEffect(() => {
    const fetchLightingDetails = async () => {
      if (id) {
        try {
          const response = await fetch(`${baseUrl}lightingSp/${id}`);
          const data = await response.json();
          setLightingDetails(data);
        } catch (error) {
          console.error("Error fetching lighting details:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchLightingDetails();
  }, [id]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (loading) {
    return <CircularProgress />;
  }

  const { kyc, serviceProvider, fessteMart } = lightingDetails;


  // Handle image click to open the preview
  const handleImageClick = (image) => {
    setSelectedImage(image); // Set the clicked image URL
    setOpen(true); // Open the modal
  };

  // Handle modal close
  const handleClose = () => {
    setOpen(false); // Close the modal
    setSelectedImage(null); // Clear the selected image
  };

  const handleDownload = async () => {
    const apiUrl = `${baseUrl}downloadFessteMarts/${fessteMart._id}`;

    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'documents.zip'); // Replace with actual file name if necessary
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };


  return (
    <AdminDrawer>
      <Paper elevation={3} style={{ padding: "20px", backgroundColor: "#f9f9f9" }}>
        <Typography variant="h4" gutterBottom>
          Lighting Details: {lightingDetails?.lightingID || "Unknown Lighting"}
        </Typography>

        <Tabs value={tabValue} onChange={handleChange} centered>
          <Tab label="Details" />
          <Tab label="Menu" />
        </Tabs>

        <Box mt={2}>
          {tabValue === 0 && (
            <><Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h5" gutterBottom>
                    Service Provider Details
                  </Typography>
                  <Typography><strong>Name:</strong> {serviceProvider?.spName}</Typography>
                  <Typography><strong>Phone:</strong> {serviceProvider?.spPhone}</Typography>
                  <Typography><strong>Email:</strong> {serviceProvider?.spEmail}</Typography>
                  <Divider sx={{ my: 2 }} />
                  <Typography variant="h5" gutterBottom>
                    KYC Details
                  </Typography>
                  <Typography><strong>PAN No:</strong> {fessteMart?.panNo}</Typography>
                  <Typography><strong>GST No:</strong> {fessteMart?.gstNo}</Typography>
                </Grid>
                <Grid item xs={12} md={6} mb={2}>
                  <Typography variant="h5" gutterBottom>
                    Business Details
                  </Typography>
                  <Typography><strong>Business ID:</strong> {fessteMart?.fmID}</Typography>
                  <Typography><strong>Street:</strong> {fessteMart?.address?.street}</Typography>
                  <Typography><strong>City:</strong> {fessteMart?.address?.city}</Typography>
                  <Typography><strong>Postal Code:</strong> {fessteMart?.address?.postalCode}</Typography>
                  <Typography><strong>Business Phone:</strong> {fessteMart?.businessPhoneNumber}</Typography>
                  <Typography><strong>Service Range:</strong> {fessteMart?.serviceRange} km</Typography>
                  <Typography><strong>Delivery Time:</strong> {fessteMart?.deliveryTime} hour(s)</Typography>
                  <Typography><strong>About Us:</strong> {fessteMart?.aboutUs}</Typography>


                  <Dialog open={open} onClose={handleClose} maxWidth="md">
                    <img src={selectedImage} alt="Documents" style={{ maxHeight: '60vh', width: '50vw' }} />
                  </Dialog>

                </Grid>
              </Grid>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                  <Typography variant="h6" fontWeight="bold" sx={{ mt: 2, alignSelf: 'flex-start' }}>
                    KYC Details
                  </Typography>
                  <Button variant="contained"
                    sx={{ borderRadius: '2rem', alignSelf: 'flex-end' }}
                    onClick={handleDownload}>
                    Download All
                  </Button>
                </Box>
            </Box><Grid container spacing={1}>
                
                <Grid item xs={4}>
               
                  <Card
                    sx={{
                      borderRadius: 2,
                      overflow: "hidden",
                      boxShadow: 1,
                      position: 'relative',
                      padding: 2,
                    }}
                  >
                    <Typography variant="body2" fontWeight="bold">
                      PAN No:
                    </Typography>
                    <Typography variant="body2">
                      {fessteMart.panNo}
                    </Typography>
                    {/* Pan Image with Download Icon Overlay */}
                    <Box sx={{ position: 'relative' }}>
                      <img
                        component="img"
                        style={{
                          width: '100%', // Ensure the image takes up the full card width
                          height: '180px', // Set a fixed height for the image
                          objectFit: 'fill', // Ensure the image covers the area
                          cursor: 'pointer',
                        }}
                        src={fessteMart.panImage} // Image URL
                        alt="Pan Image"
                        onClick={() => handleImageClick(fessteMart.panImage)} // Pass specific image URL
                      />
                      {/* Download Icon Overlay */}
                      <a href={fessteMart.panImage} download>
                        <IconButton
                          sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            color: 'green',
                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            '&:hover': {
                              backgroundColor: 'rgba(255, 255, 255, 0.9)',
                            },
                          }}
                        >
                          <DownloadIcon />
                        </IconButton>
                      </a>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card
                    sx={{
                      borderRadius: 2,
                      overflow: "hidden",
                      boxShadow: 1,
                      position: 'relative',
                      padding: 2,
                    }}
                  >
                    <Typography variant="body2" fontWeight="bold">
                      GST No:
                    </Typography>
                    <Typography variant="body2">
                      {fessteMart.gstNo}
                    </Typography>
                    {/* Gst Image with Download Icon Overlay */}
                    <Box sx={{ position: 'relative' }}>
                      <img
                        component="img"
                        src={fessteMart.gstImage} // Image URL
                        onClick={() => handleImageClick(fessteMart.gstImage)} // Pass specific image URL
                        alt="GST Image"
                        style={{
                          width: '100%', // Ensure the image takes up the full card width
                          height: '180px', // Set a fixed height for the image
                          objectFit: 'fill', // Ensure the image covers the area
                          cursor: 'pointer',
                        }} />
                      {/* Download Icon Overlay */}
                      <a href={fessteMart.gstImage} download>
                        <IconButton
                          sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            color: 'green',
                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            '&:hover': {
                              backgroundColor: 'rgba(255, 255, 255, 0.9)',
                            },
                          }}
                        >
                          <DownloadIcon fontSize="medium" />
                        </IconButton>
                      </a>
                    </Box>
                  </Card>
                </Grid>

                <Grid item xs={4}>
                  <Card
                    sx={{
                      overflow: "hidden",
                      boxShadow: 1,
                      padding: 2,
                    }}
                  >
                    <video
                      component="video"
                      controls
                      src={fessteMart?.businessVideo} // Ensure this contains the correct video URL
                      alt="Business Video"
                      height="200"
                      style={{
                        width: '100%', // Ensure the image takes up the full card width
                        height: '250px', // Set a fixed height for the image
                        objectFit: 'fill', // Ensure the image covers the area
                        cursor: 'pointer',
                      }} />
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card
                    sx={{
                      borderRadius: 2,
                      overflow: 'hidden',  // Prevent the card from scrolling
                      boxShadow: 1,
                      position: 'relative',
                      padding: 2,
                      height: 'auto',  // Ensure the card can grow to fit content if needed
                    }}
                  >
                    {/* PDF Preview */}
                    <Box sx={{ position: 'relative', height: '250px' }}>  {/* Match video height */}
                      <iframe
                        src={fessteMart?.contractUrl}  // PDF URL
                        title="Contract PDF"
                        style={{
                          width: '100%',  // Ensure the iframe takes up the full card width
                          height: '100%',  // Fill the container height (250px)
                          border: 'none',  // Remove default iframe borders
                        }}
                      />
                    </Box>

                    {/* Download Icon */}
                    <a href={fessteMart?.contractUrl} download>
                      <IconButton
                        sx={{
                          position: 'absolute',
                          top: 8,
                          right: 20,
                          color: 'green',
                          backgroundColor: 'rgba(255, 255, 255, 0.7)',
                          '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.9)',
                          },
                        }}
                      >
                        <DownloadIcon fontSize="medium" />
                      </IconButton>
                    </a>
                  </Card>
                </Grid>
              </Grid></>
          )}
          {tabValue === 1 && (
            <Box>
              <Typography variant="h5" gutterBottom>
                Menu Items
              </Typography>
              {lightingDetails?.menu?.lights && lightingDetails.menu.lights.length > 0 ? (
                <Grid container spacing={2}>
                  {lightingDetails.menu.lights.map((lights) => (
                    <Grid item xs={12} md={6} key={lights._id}>
                      <Paper elevation={2} style={{ padding: '16px' }}>
                        <Typography variant="h6">{lights.lightingType.typeName}</Typography>
                        <img src={lights.lightingType.images[0]} alt={lights.lightingType.typeName} width="100" />
                        <Typography>Price: ₹{lights.customPrice}</Typography>
                        <Typography>Description: {lights.lightingType.description}</Typography>
                        <Typography>Available: {lights.availability ? "Yes" : "No"}</Typography>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Typography>No Lightings available</Typography>
              )}
            </Box>
          )}
        </Box>
      </Paper>
    </AdminDrawer>
  );
};

export default LightingDetails;
