import React from 'react';
import { Box, Typography, Button } from '@mui/material';

const FessteMartPayoutBreakdown = ({ selectedOrder }) => {    
    console.log('FessteMart selectedOrder', selectedOrder);

    return (
        <Box mt={4} p={3} bgcolor="#ffffff" borderRadius="8px">
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#3f51b5' }}>
                FessteMart Payment Breakdown
            </Typography>
            <Typography sx={{ fontSize: '1rem', mb: 1 }}>
                Total Initial Payout: <strong>₹{parseFloat(selectedOrder?.totalInitialPayoutAmt)}</strong>
            </Typography>
            <Typography sx={{ fontSize: '1rem', mb: 1 }}>
                Fesste Fee: <strong>₹{selectedOrder?.fessteFee?.toFixed(2)}</strong>
            </Typography>
            <Typography sx={{ fontSize: '1rem', mb: 1 }}>
                GST on Fesste Fee: <strong>₹{selectedOrder?.gstOnFessteFee?.toFixed(2)}</strong>
            </Typography>
            <Typography sx={{ fontSize: '1rem', mb: 1 }}>
                Transaction Fee: <strong>₹{selectedOrder?.transactionFee?.toFixed(2)}</strong>
            </Typography>
          
            <Typography variant="h6" color="primary" sx={{ fontWeight: 'bold', mt: 2 }}>
                Net Amount to be Paid: <strong>₹{(selectedOrder?.totalInitialPayoutAmt - selectedOrder?.fessteFee - selectedOrder?.gstOnFessteFee - selectedOrder?.transactionFee).toLocaleString()}</strong>
            </Typography>

            <Box display="flex" alignItems="center" mt={3}>
                <input
                    type="text"
                    disabled
                    value={selectedOrder?.invoiceId}
                    placeholder="Invoice ID"
                    style={{
                        flexGrow: 1,
                        padding: '12px',
                        borderRadius: '8px',
                        marginRight: '12px',
                        border: '1px solid grey',
                        fontSize: '1rem',
                        fontWeight: '500',
                        color: 'grey',
                    }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    sx={{
                        padding: '10px 20px',
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        textTransform: 'none',
                        backgroundColor: '#f44336',
                        '&:hover': {
                            backgroundColor: '#d32f2f',
                        },
                    }}
                >
                    Submit
                </Button>
            </Box>
        </Box>
    );
};

export default FessteMartPayoutBreakdown;
