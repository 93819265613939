import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { RadialBarChart, RadialBar, Legend } from 'recharts';

const data = [
    {
        name: 'Delivered',
        value: 2400,
        fill: '#FFD700' // yellow
    },
    {
        name: 'Cancelled',
        value: 4567,
        fill: '#FF4500' // red
    },
    {
        name: 'Failed',
        value: 1398,
        fill: '#FF6347' // orange-red
    }
];

const DashBoardEarnings = () => {
    return (
        <Paper elevation={0} sx={{ borderRadius: 3, p: 1 }}>
            <Box sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>Earnings</Typography>
                <Typography variant="subtitle2" color="textSecondary">This Month</Typography>
                <Typography variant="h6" gutterBottom>₹0.59</Typography>
                <Typography variant="caption" color="textSecondary">
                    99.98% less earnings than last month
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <RadialBarChart 
                        width={250} 
                        height={250} 
                        innerRadius="20%" 
                        outerRadius="100%" 
                        data={data} 
                        startAngle={90} 
                        endAngle={-270}>
                        <RadialBar minAngle={15} clockWise dataKey="value" />
                        <Legend iconSize={10} layout="horizontal" verticalAlign="bottom" align="center" />
                    </RadialBarChart>
                </Box>
            </Box>
        </Paper>
    );
};

export default DashBoardEarnings;
