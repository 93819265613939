import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Chip, Container, CircularProgress, Card, CardContent, Grid, Box, Stack } from '@mui/material';
import { CheckCircleOutline, PendingActions } from '@mui/icons-material';
import AdminDrawer from '../../components/AdminDrawer/AdminDrawer';
import { getQuoteById } from '../../api/quotations/quotationsApis'; // Import the API function

const FessteQuotationDetails = () => {
  const { id } = useParams(); // Get the ID from the URL
  const [quotation, setQuotation] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchQuotation = async () => {
      setLoading(true);
      const data = await getQuoteById(id);
      if (data) {
        setQuotation(data);
      }
      setLoading(false);
    };

    fetchQuotation();
  }, [id]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!quotation) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography variant="h6">No quotation found.</Typography>
      </Box>
    );
  }
  console.log('quotation', quotation);


  return (
    <div style={{ backgroundColor: '#f2f2f2', minHeight: '100vh', padding: '20px' }}>
      <AdminDrawer>
        <Container maxWidth="lg">
          <Typography variant="h4" fontWeight="bold" gutterBottom>
            Quotation Details
          </Typography>

          {/* Customer Information Card */}
          <Card variant="outlined" sx={{ mb: 3, borderRadius: 2, boxShadow: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>Customer Information</Typography>
              <Stack spacing={1}>
                <Typography><strong>Name:</strong> {quotation.customer?.fullName}</Typography>
                <Typography><strong>Email:</strong> {quotation.customer?.email}</Typography>
                <Typography><strong>Phone:</strong> {quotation.customer?.phoneNumber}</Typography>
                <Typography><strong>Address:</strong> {`${quotation.customer?.addresses[0]?.address1}, ${quotation.customer?.addresses[0]?.city}, ${quotation.customer?.addresses[0]?.state} - ${quotation.customer?.addresses[0]?.postalCode}`}</Typography>
              </Stack>
            </CardContent>
          </Card>

          {/* Event Details Card */}
          <Card variant="outlined" sx={{ mb: 3, borderRadius: 2, boxShadow: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>Event Details</Typography>
              <Stack spacing={1}>
                <Typography><strong>Event Date:</strong> {new Date(quotation.eventDate).toLocaleDateString()}</Typography>
                <Typography><strong>Start Time:</strong> {new Date(quotation.eventStartTime).toLocaleTimeString()}</Typography>
                <Typography><strong>End Time:</strong> {new Date(quotation.eventEndTime).toLocaleTimeString()}</Typography>
              </Stack>
            </CardContent>
          </Card>

          {/* Business Information Card */}
          <Card variant="outlined" sx={{ mb: 3, borderRadius: 2, boxShadow: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>Business Information</Typography>
              <Stack spacing={1}>
                <Typography><strong>Name:</strong> {quotation.fessteMart?.businessName}</Typography>
                <Typography><strong>Address:</strong> {`${quotation.fessteMart?.address?.street}, ${quotation.fessteMart?.address?.city}, ${quotation.fessteMart?.address?.postalCode}`}</Typography>
              </Stack>
            </CardContent>
          </Card>

          {/* Quote Status */}
          <Box mt={4}>
            <Typography variant="h6" gutterBottom>Quote Status</Typography>
            <Chip
              label={quotation.quoteStatus}
              icon={
                quotation.quoteStatus === "confirmed" ? (
                  <CheckCircleOutline />
                ) : (
                  <PendingActions />
                )
              }
              color={quotation.quoteStatus === "confirmed" ? "success" : "warning"}
              sx={{ fontSize: '1rem', padding: '10px 15px' }}
            />
          </Box>

          {/* Total Price */}
          <Box mt={4}>
            <Typography variant="h6" gutterBottom>Total Price</Typography>
            <Typography variant="h5" fontWeight="bold">₹{quotation.totalPrice}</Typography>
          </Box>

          <Box mt={4}>
            <Typography variant="h6" gutterBottom>Services</Typography>
            <Grid container spacing={3}>
              {quotation.services.map((service, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card variant="outlined" sx={{ borderRadius: 2, boxShadow: 2, '&:hover': { boxShadow: 6 } }}>
                    <CardContent>
                      {/* Service Type */}
                      <Typography variant="body1" fontWeight="bold">
                        {service.serviceType}
                      </Typography>

                      {/* Service Name */}
                      <Typography variant="body1" fontWeight="bold">
                        {service.serviceId?.typeName}
                      </Typography>

                      {/* Service Description */}
                      <Typography variant="body2" color="text.secondary">
                        {service.serviceId?.description}
                      </Typography>

                      {/* Service Unit */}
                      <Typography variant="body2" color="text.secondary">
                        Unit: {service.serviceId?.unit}
                      </Typography>

                      {/* Availability */}
                      <Typography variant="body2" color={service.serviceId?.availability ? "text.primary" : "error"}>
                        {service.serviceId?.availability ? "Available" : "Unavailable"}
                      </Typography>

                      {/* Service Price */}
                      <Typography variant="body2" color="text.secondary">
                        Price: ₹{service.price}
                      </Typography>

                      {/* Images */}
                      {service.serviceId?.images?.length > 0 && (
                        <Box mt={2}>
                          <img
                            src={service.serviceId?.images[0]}
                            alt={service.serviceId?.typeName}
                            style={{ width: '100%', height: '100%', borderRadius: '8px' }}
                          />
                        </Box>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>

        </Container>
      </AdminDrawer>
    </div>
  );
};

export default FessteQuotationDetails;
