import React from 'react';
import { Box, Typography, Button } from '@mui/material';

const PaymentBreakdown = ({ selectedOrder }) => {    
    
    return (
        <Box mt={4} p={3} bgcolor="#ffffff" borderRadius="8px">
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#3f51b5' }}>
                Payment Breakdown
            </Typography>
            <Typography sx={{ fontSize: '1rem', mb: 1 }}>
                Total Order Value: <strong>₹ {parseFloat(selectedOrder?.totalAmount)}</strong>
            </Typography>
            <Typography sx={{ fontSize: '1rem', mb: 1 }}>
                Advance Payment: <strong>₹ {parseFloat(selectedOrder?.grossPayout)}</strong>
            </Typography>
            <Typography sx={{ fontSize: '1rem', mb: 1 }}>
                Second Payment: <strong>₹ {parseFloat(selectedOrder?.secondPayemnt.toFixed(2))}</strong>
            </Typography>
            <Typography sx={{ fontSize: '1rem', mb: 1 }}>
                Final Payment: <strong>₹ {parseFloat(selectedOrder?.finalPayment.toFixed(2))}</strong>
            </Typography>
            <Typography sx={{ fontSize: '1rem', mb: 1 }}>
                Platform Fee: <strong>₹ {selectedOrder?.fessteFee}</strong>
            </Typography>
            <Typography sx={{ fontSize: '1rem', mb: 1 }}>
                Platform Fee GST @ 18% on Service Fee: <strong>₹ {selectedOrder?.gstonFessteFee}</strong>
            </Typography>
            <Typography sx={{ fontSize: '1rem', mb: 1 }}>
                Transaction Fee: <strong>₹ {selectedOrder?.transactionFee}</strong>
            </Typography>
            <Typography sx={{ fontSize: '1rem', mb: 1 }}>
                TDS: <strong>₹ {selectedOrder?.tds}</strong>
            </Typography>
          
            <Typography variant="h6" color="primary" sx={{ fontWeight: 'bold', mt: 2 }}>
                Net Amount to be Settled: <strong>₹{parseFloat(selectedOrder?.netPayout).toLocaleString()}</strong>
            </Typography>

            <Box display="flex" alignItems="center" mt={3}>
                <input
                    type="text"
                    disabled
                    value={selectedOrder?.netPayout.toFixed(2)}
                    placeholder="Net Payout"
                    style={{
                        flexGrow: 1,
                        padding: '12px',
                        borderRadius: '8px',
                        marginRight: '12px',
                        border: '1px solid grey',
                        fontSize: '1rem',
                        fontWeight: '500',
                        color: 'grey',
                    }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    sx={{
                        padding: '10px 20px',
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        textTransform: 'none',
                        backgroundColor: '#f44336',
                        '&:hover': {
                            backgroundColor: '#d32f2f',
                        },
                    }}
                >
                    Submit
                </Button>
            </Box>
        </Box>
    );
};

export default PaymentBreakdown;
