import React, { useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
  Box,
  CssBaseline,
  Typography,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  AppBar as MuiAppBar,
  Drawer as MuiDrawer,
  Avatar,
  Menu,
  MenuItem,
} from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu'; // Add this import for the menu icon
import { useNavigate } from 'react-router-dom';
import avatarwomen from '../../assets/avatarwomen.jpg';
import AdminDrawerList from './AdminDrawerList';

const drawerWidth = 300; // Adjusted width for the collapsible drawer

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
}));

const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  width: open ? drawerWidth : 0,
  overflowX: 'hidden',
  '& .MuiDrawer-paper': {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowY: 'auto',
    height: '100vh',
  },
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AdminDrawer = ({ children }) => {
  const theme = useTheme();
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [drawerOpen, setDrawerOpen] = useState(false); // State to manage drawer open/close

  useEffect(() => {
    const userResponse = localStorage.getItem('userResponse');
    if (userResponse) {
      setUserData(JSON.parse(userResponse));
    }
  }, []);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    handleMenuClose();
    localStorage.clear();
    navigate('/login');
  };

  const toggleDrawer = () => {
    setDrawerOpen((prevOpen) => !prevOpen);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ backgroundColor: '#ffffff' }}>
        <Toolbar>
          <IconButton onClick={toggleDrawer} edge="start" color="inherit" aria-label="menu">
            <MenuIcon sx={{ color: 'black' }} />
          </IconButton>
          <DrawerHeader >
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', }}>
              <Typography  sx={{ fontFamily: 'Metropolis', fontWeight: 700, fontSize: '32px', color: '#baf200' }}

              >
                <a href='/' style={{ textDecoration: 'none'}}>Fesste Admin</a>
              </Typography>
            </Box>

          </DrawerHeader>
          <Box sx={{ flexGrow: 1 }} />
          <Typography color='black' fontSize={14}>
            {userData?.fullName}
          </Typography>
          <IconButton onClick={handleMenuOpen} color="inherit">
            <Avatar src={avatarwomen} alt="User Avatar" />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleMenuClose}
          >
            <MenuItem>
              <Avatar src={avatarwomen} alt="User Avatar" />
              <Typography variant="body1" sx={{ marginLeft: 2 }}>
                {userData?.fullName}
              </Typography>
            </MenuItem>
            <MenuItem>
              <Typography variant="body2" sx={{ marginLeft: 2 }}>
                Role: {userData?.role || 'Admin'}
              </Typography>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogoutClick}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      <Drawer variant="temporary" open={drawerOpen} onClose={toggleDrawer}>
        <DrawerHeader>
          <IconButton onClick={toggleDrawer}>
            <ExitToAppIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <AdminDrawerList
          Drawer={Drawer}
          userData={userData}
          theme={theme}
          DrawerHeader={DrawerHeader}
          selectedIndex={0}
        />
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
};

export default AdminDrawer;
