import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminDrawer from '../../components/AdminDrawer/AdminDrawer';
import { Typography, Paper, Button, Tooltip, IconButton, styled, Skeleton, Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LockResetIcon from '@mui/icons-material/LockReset';
import AddIcon from '@mui/icons-material/Add';
import DataTable from '../../components/DataTables/DataTable';
import { fetchRolesData, deleteRole, resetPassword } from '../../api/userRoles/roles';
import CustomSnackbar from '../../components/CustomeSnackBar/CustomSnackbar';
import ConfirmDialog from '../../components/ResuableComponents/ConfirmDialog';
import ResetPasswordDialog from '../../components/ResuableComponents/ResetPasswordDialog';

const TableContainer = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    maxHeight: '100vh',
    height: '650px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[0],
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
        height: 'auto',
    },
}));

const ButtonContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
}));

const SkeletonTable = () => (
    <Grid container spacing={2}>
        {[...Array(5)].map((_, index) => (
            <React.Fragment key={index}>
                <Grid item xs={2}>
                    <Skeleton variant="rectangular" height={40} />
                </Grid>
                <Grid item xs={2}>
                    <Skeleton variant="rectangular" height={40} />
                </Grid>
                <Grid item xs={2}>
                    <Skeleton variant="rectangular" height={40} />
                </Grid>
                <Grid item xs={2}>
                    <Skeleton variant="rectangular" height={40} />
                </Grid>
                <Grid item xs={2}>
                    <Skeleton variant="rectangular" height={40} />
                </Grid>
                <Grid item xs={2}>
                    <Skeleton variant="rectangular" height={40} />
                </Grid>
            </React.Fragment>
        ))}
    </Grid>
);

const Roles = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [openResetDialog, setOpenResetDialog] = useState(false);

    useEffect(() => {
        const getData = async () => {
            try {
                const rolesData = await fetchRolesData();
                setData(rolesData);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        getData();
    }, []);

    const handleAddStaffClick = () => {
        navigate('/add-roles');
    };

    const handleEditClick = (rowData) => {
        navigate(`/edit-roles/${rowData._id}`, { state: rowData });
    };

    const handleDeleteClick = (rowData) => {
        setSelectedRow(rowData);
        setOpenDialog(true);
    };

    const confirmDelete = async () => {
        if (selectedRow) {
            try {
                await deleteRole(selectedRow._id);
                setData(data.filter((row) => row._id !== selectedRow._id));
                setSnackbarMessage('Role deleted successfully');
            } catch (error) {
                setSnackbarMessage('Failed to delete role');
                console.error('Error deleting role:', error);
            } finally {
                setSnackbarOpen(true);
                setOpenDialog(false);
            }
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleResetPasswordClick = (rowData) => {
        setSelectedRow(rowData);
        setOpenResetDialog(true);
    };

    const handleConfirmResetPassword = async (newPassword) => {
        if (selectedRow) {
            try {
                await resetPassword({ adminId: selectedRow._id, newPassword });
                setSnackbarMessage('Password reset successfully');
            } catch (error) {
                setSnackbarMessage('Failed to reset password');
                console.error('Error resetting password:', error);
            } finally {
                setSnackbarOpen(true);
                setOpenResetDialog(false); // Close the reset dialog after success
            }
        }
    };


    const handleCloseResetDialog = () => {
        setOpenResetDialog(false);
    };

    const columns = [
        { field: 'id', headerName: 'User ID', flex: 1, sortable: true },
        { field: 'name', headerName: 'Name', flex: 1, sortable: true },
        { field: 'email', headerName: 'Email', flex: 1, sortable: true },
        { field: 'phoneNumber', headerName: 'Phone Number', flex: 1, sortable: true },
        { field: 'role', headerName: 'Role', flex: 1, sortable: true },
        { field: 'createdAt', headerName: 'Register Date', flex: 1.5, sortable: true },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Tooltip title="Reset Password">
                        <IconButton onClick={() => handleResetPasswordClick(params.row)}>
                            <LockResetIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit">
                        <IconButton onClick={() => handleEditClick(params.row)}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <IconButton onClick={() => handleDeleteClick(params.row)}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            ),
        },
    ];

    return (
        <div style={{ backgroundColor: '#f2f2f2' }}>
            <AdminDrawer>
                <Typography variant='h4' fontWeight='bolder'>Roles</Typography>
                <TableContainer>
                    <ButtonContainer>
                        <Button
                            variant="contained"
                            color="success"
                            startIcon={<AddIcon />}
                            onClick={handleAddStaffClick}
                        >
                            Add Roles
                        </Button>
                    </ButtonContainer>
                    {loading ? (
                        <SkeletonTable />
                    ) : (
                        <DataTable
                            rows={data}
                            columns={columns}
                            disableRowSelected
                        />
                    )}
                </TableContainer>
                {/* Confirmation Dialog */}
                <ConfirmDialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    onConfirm={confirmDelete}
                    title="Confirm Deletion"
                    content="Are you sure you want to delete this role?"
                />

                {/* Password Reset Dialog */}
                <ResetPasswordDialog
                    open={openResetDialog}
                    onClose={handleCloseResetDialog}
                    onConfirm={handleConfirmResetPassword}
                    title={`Reset Password for ${selectedRow?.name}`}
                />

                {/* Snackbar */}
                <CustomSnackbar
                    open={snackbarOpen}
                    onClose={handleSnackbarClose}
                    message={snackbarMessage}
                    severity={snackbarMessage.includes('successfully') ? 'success' : 'error'}
                />

            </AdminDrawer>
        </div>
    );
};

export default Roles;
