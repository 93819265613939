import React from 'react';
import { Box, Checkbox } from '@mui/material';
import PayoutTable from '../../DataTables/PayoutTables/PayoutTable';

const PaymentDashBoardTable = ({ orders, selectedOrder, onCheckboxChange }) => {    
    // Transform the orders array into a format suitable for the table
    const transformedOrders = orders.map((order) => ({
        id: order._id, // Use _id as the unique ID (important for DataGrid)
        orderID: order.orderID, // Order ID from order object
        custName: order.orderId.userId.fullName, // Convert orderDate to readable format
        deliveryAddress: order.orderId.shippingAddress.street, // Format the order total as currency
        deliveredDate: new Date(order?.orderId?.orderDate).toLocaleDateString(), 
        packageName: order?.orderId.items[0]?.packageId.packageName, // Order status
        quantity: order?.orderId.items[0]?.guestCount,
        totalAmount: order?.totalAmount, // Payment method
        initalPayment: order?.initialPayment?.toFixed(2),
        secondPayemnt: order?.orderId?.secondPayment,
        finalPayment: order?.orderId?.finalPayment,
        paymentStatus: order?.orderId?.paymentStatus,
        grossPayout: order?.initialPayment?.toFixed(2),
        fessteFee: order?.fessteFee,
        gstonFessteFee: order?.gstOnFessteFee,
        transactionFee:order?.transactionFee,
        tds: order?.tds,
        netPayout: order?.netPayout
            
    }));

    // Define columns for the table
    const columns = [
        { field: 'orderID', headerName: 'Order ID', minWidth:200 },
        { field: 'custName', headerName: 'Customer Name', minWidth:200 },
        { field: 'deliveryAddress', headerName: 'Delivery Address', minWidth:250 },
        { field: 'deliveredDate', headerName: 'Delivery Date', minWidth:200 },
        { field: 'packageName', headerName: 'Package Name', minWidth:200 },
        { field: 'quantity', headerName: 'Quantity', minWidth:200 },
        { field: 'totalAmount', headerName: 'Total Amount', minWidth:200 },
        { field: 'initalPayment', headerName: 'Advance Payment', minWidth:220 },
        { field: 'secondPayemnt', headerName: 'Second Payment', minWidth:200 },
        { field: 'finalPayment', headerName: 'Final Payment', minWidth:200 },
        { field: 'paymentStatus', headerName: 'Order Status', minWidth:200 },
        
        {
            field: 'pay',
            headerName: 'Pay',
            minWidth:160,
            renderCell: (params) => (
                <Checkbox
                    color="primary"
                    checked={selectedOrder?._id === params.row.id} // Check if the current row is selected
                    onChange={() => onCheckboxChange(params.row)} // Handle checkbox change
                />
            ),
            flex: 0.5,
        },
    ];
    
    return (
        <Box style={{ height: 400, width: '100%' }}>
            <PayoutTable
                rows={transformedOrders} // Use the transformed data
                columns={columns}
                getRowId={(row) => row.id} // Use _id as row identifier
                pageSize={5}
                disableSelectionOnClick
                autoHeight
            />
        </Box>
    );
};

export default PaymentDashBoardTable;
