import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { styled } from '@mui/system';

// Draggable Dialog Title
const DraggableDialogTitle = styled(DialogTitle)(({ theme }) => ({
    cursor: 'move',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
}));

const ConfirmDialog = ({ open, onClose, onConfirm, title, content,message }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                style: { padding: '20px' }
            }}
            transitionDuration={{ enter: 300, exit: 200 }}
        >
            <DraggableDialogTitle disabletypography>
                <WarningIcon color="error" />
                <Typography variant="h6" component="span">
                    {title}
                </Typography>
            </DraggableDialogTitle>
            <DialogContent dividers>
                <Typography variant="body1">
                    {content || message} 
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    color="primary"
                    variant="outlined"
                    style={{ textTransform: 'none' }}
                >
                    No
                </Button>
                <Button
                    onClick={onConfirm}
                    color="secondary"
                    variant="contained"
                    style={{ textTransform: 'none' }}
                >
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
