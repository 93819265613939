import React from 'react';
import { Container, Paper, IconButton, Divider } from '@mui/material';
import { Print, Download } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import OrderInvoiceHeader from './OrderInvoiceHeader';
import OrderCustomerDetails from './OrderCustomerDetails';
import OrderItemsTable from './OrderItemsTable';
import OrderPriceSummary from './OrderPriceSummary';

const RestaurantOrderInvoice = () => {
    const { state } = useLocation(); // Access passed state
    const { orderData } = state || {}; // Retrieve order data

    const handlePrint = () => {
        window.print();
    };

    const handleDownload = () => {
        const content = document.getElementById('printable-content');
        const excludedElements = document.querySelectorAll('.exclude-from-download');

        excludedElements.forEach(element => {
            element.style.visibility = 'hidden';
        });

        html2canvas(content, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const imgWidth = 210;
            const pageHeight = 295;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            let position = 0;

            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            pdf.save('order-details.pdf');

            excludedElements.forEach(element => {
                element.style.visibility = '';
            });
        });
    };    

    return (
        <Container
            sx={{
                padding: 4,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                backgroundColor: '#f5f5f5',
                position: 'relative',
            }}
        >
            <IconButton
                id="print-button"
                onClick={handlePrint}
                sx={{
                    position: 'absolute',
                    top: 16,
                    right: 76,
                }}
            >
                <Print />
            </IconButton>

            <IconButton
                id="download-button"
                onClick={handleDownload}
                sx={{
                    position: 'absolute',
                    color: 'green',
                    top: 16,
                    right: 16,
                }}
            >
                <Download />
            </IconButton>

            <Paper id="printable-content" sx={{ padding: 4, width: '100%', maxWidth: '900px', backgroundColor: '#fff' }}>
                <OrderInvoiceHeader
                    invoiceNumber="U72900KA"
                    invoiceDate={orderData?.createdAt}
                    orderNumber={orderData?.restaurant_orderId}
                    restaurantName={orderData?.restaurantId?.restName}
                    restaurantAddress={orderData?.restaurantId?.restAddress}
                    restaurantGST={orderData?.restaurantId?.gstNo}
                />

                <OrderCustomerDetails
                    customerName={orderData?.customer?.fullName}
                    customerAddress={orderData?.deliveryAddress}
                    customerPhone={orderData?.customer?.phoneNumber}
                />

                <OrderItemsTable
                    items={orderData?.items.map((item) => ({
                        particulars: item?.itemId?.restItemName,
                        qty: item?.quantity,
                        price: item?.itemId?.restItemPrice,
                        grossValue: item?.quantity * item?.itemId?.restItemPrice,
                        discount: 0, // Update this if needed
                        total: item?.quantity * item?.itemId?.restItemPrice,
                        addOns: item?.addOns.map(addOn => ({
                            name: addOn?.addOnId?.addOnName,
                            price: addOn?.addOnId?.addOnPrice,
                            addonQty:addOn?.quantity,
                        }))
                    }))}
                />

                <OrderPriceSummary
                    totalValue={orderData?.totalAmount}
                    platformFeeGst={orderData?.platformFeeGst}
                    gstTotal={orderData?.gst}
                    platformFee={orderData?.platformFee}
                    deliveryFee={orderData?.deliveryFee}
                />
                <Divider sx={{ marginY: 1, borderColor: '#000' }} />
            </Paper>
        </Container>
    );
};

export default RestaurantOrderInvoice;
