import React from 'react';
import { Container, Paper, IconButton, Divider } from '@mui/material';
import { Print, Download } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import OrderInvoiceHeader from './OrderInvoiceHeader';
import OrderCustomerDetails from './OrderCustomerDetails';
import OrderItemsTable from './OrderItemsTable';
import OrderPriceSummary from './OrderPriceSummary';
// import CompanyDetails from './CompanyDetails';
import PriceSummaryPayout from './PriceSummaryPayout';

const PayoutInvoice = () => {
    const { state } = useLocation(); // Access passed state
    const { orderData } = state || {}; // Retrieve order data


    console.log('orderData',orderData);
    

    const handlePrint = () => {
        window.print();
    };

    const handleDownload = () => {
        const content = document.getElementById('printable-content');
        const excludedElements = document.querySelectorAll('.exclude-from-download');

        // Hide the elements with the 'exclude-from-download' class
        excludedElements.forEach(element => {
            element.style.visibility = 'hidden'; // Set visibility to hidden instead of display none
        });

        html2canvas(content, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const imgWidth = 210;
            const pageHeight = 295;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            let position = 0;

            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            pdf.save('order-details.pdf');

            // Restore the visibility of the excluded elements
            excludedElements.forEach(element => {
                element.style.visibility = ''; // Restore visibility
            });
        });
    };
    const catererItemData = orderData?.items[0];

    return (
        <Container
            sx={{
                padding: 4,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                backgroundColor: '#f5f5f5',
                position: 'relative',
            }}
        >
            <IconButton
                id="print-button"
                onClick={handlePrint}
                sx={{
                    position: 'absolute',
                    top: 16,
                    right: 76,
                }}
            >
                <Print />
            </IconButton>

            <IconButton
                id="download-button"
                onClick={handleDownload}
                sx={{
                    position: 'absolute',
                    color: 'green',
                    top: 16,
                    right: 16,
                }}
            >
                <Download />
            </IconButton>

            <Paper id="printable-content" sx={{ padding: 4, width: '100%', maxWidth: '900px', backgroundColor: '#fff' }}>
                <OrderInvoiceHeader
                    invoiceNumber="U72900KA"
                    invoiceDate={orderData?.orderDate}
                    orderNumber={orderData?.orderID}
                    restaurantName={orderData?.catererId?.businessName}
                    restaurantAddress={orderData?.catererId?.address}
                    restaurantGST={orderData?.catererId?.gstNo}
                />

                <OrderCustomerDetails
                    customerName={orderData?.userId?.fullName}
                    customerAddress={orderData?.shippingAddress}
                    customerPhone={orderData?.userId?.phoneNumber}
                />

                <OrderItemsTable
                    items={[
                        { particulars: catererItemData?.packageId?.packageName, qty: catererItemData?.guestCount, price: catererItemData?.pricePerPerson, grossValue: orderData?.grossAmount, discount: 0, total: catererItemData?.totalAmount },
                    ]}
                />

                <OrderPriceSummary
                    totalValue={orderData?.totalAmount}
                    platformFeeGst={orderData?.platformFeeGst}
                    gstTotal={orderData?.gst}
                    platformFee={orderData?.platformFee}
                    deliveryFee={orderData?.deliveryFee}
                />
                <Divider sx={{ marginY: 1, borderColor: '#000' }} />

                {/* Display PriceSummaryCaters component below the rest */}
                <div className="exclude-from-download">
                    <PriceSummaryPayout paymentData={orderData} />

                </div>
            </Paper>
        </Container>
    );
};

export default PayoutInvoice;
