//src/api/caterePackages

import imageCompression from 'browser-image-compression';
import baseUrl from '../../config';

export const createPackage = async ({ packageName, packageType, allocation, images }) => {
    const accessToken = localStorage.getItem('accessToken')?.replace(/"/g, '');

    if (!accessToken) {
        throw new Error('No access token found');
    }

    const formData = new FormData();

    // Append form data
    formData.append('packageName', packageName);
    packageType.forEach((type) => formData.append('type[]', type.title));
    allocation.forEach((allocation) => formData.append('allocatedTo[]', allocation.title));

    const compressedImages = [];
    for (let image of images) {
        if (image.size > 2 * 1024 * 1024) {
            alert('File size should be less than 2MB');
            return;
        }

        const options = {
            maxSizeMB: 2,
            maxWidthOrHeight: 800,
            useWebWorker: true,
        };

        try {
            const compressedImage = await imageCompression(image, options);
            compressedImages.push(compressedImage);
        } catch (error) {
            console.error('Error while compressing the image:', error);
            throw error;
        }
    }

    compressedImages.forEach((image) => formData.append('packageImages', image));

    try {
        const response = await fetch(`${baseUrl}menu/create_package`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
            body: formData,
        });

        if (!response.ok) {
            const responseBody = await response.text(); 
            throw new Error(`Failed to create package: ${responseBody}`);
        }

        return response.json();
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const getPackageById = async (id) => {
    try {
        const response = await fetch(`${baseUrl}menu/package/${id}`);
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            console.error('Failed to fetch package by ID:', response.statusText);
            return null;
        }
    } catch (error) {
        console.error('Error fetching package by ID:', error);
        return null;
    }
};


export const updatePackage = async (id, { packageName, packageType, allocation, images }) => {
    const accessToken = localStorage.getItem('accessToken')?.replace(/"/g, '');

    if (!accessToken) {
        throw new Error('No access token found');
    }

    const formData = new FormData();

    // Append form data
    formData.append('packageName', packageName);
    packageType.forEach((type) => formData.append('type', type.title));
    allocation.forEach((allocation) => formData.append('allocatedTo[]', allocation.title));

    // const compressedImages = [];
    // for (let image of images) {
    //     if (image.size > 2 * 1024 * 1024) {
    //         alert('File size should be less than 2MB');
    //         return;
    //     }

    //     const options = {
    //         maxSizeMB: 2,
    //         maxWidthOrHeight: 800,
    //         useWebWorker: true,
    //     };

    //     try {
    //         const compressedImage = await imageCompression(image, options);
    //         compressedImages.push(compressedImage);
    //     } catch (error) {
    //         console.error('Error while compressing the image:', error);
    //         throw error;
    //     }
    // }

    // compressedImages.forEach((image) => formData.append('packageImages', image));

    try {
        const response = await fetch(`${baseUrl}menu/update_Package/${id}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
            body: formData,
        });

        if (!response.ok) {
            const responseBody = await response.text();
            throw new Error(`Failed to update package: ${responseBody}`);
        }

        return response.json();
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};


// src/api/caterePackages.js

export const deletePackage = async (id) => {
    const accessToken = localStorage.getItem('accessToken')?.replace(/"/g, '');

    if (!accessToken) {
        throw new Error('No access token found');
    }

    try {
        const response = await fetch(`${baseUrl}menu/delete_Package/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
        });

        if (!response.ok) {
            const responseBody = await response.text();
            throw new Error(`Failed to delete package: ${responseBody}`);
        }

        return response.json();
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};


