import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Chip, CircularProgress, Container, Tooltip, IconButton, Button, useTheme, Dialog, DialogContent, DialogTitle } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import OrdersTable from '../../../components/DataTables/OrdersTables/OrdersTable';
import { fetchCatererOrders, fetchCatererOrderDetails, updateDeiveryType } from '../../../api/orders/catererOrders';
import CustomSnackbar from '../../../components/CustomeSnackBar/CustomSnackbar'; // Import CustomSnackbar
import { useDialog } from "../../../hooks/useDialog";
import { renderCellWithMore } from "../../../components/ResuableComponents/ViewMoreComponents/ViewMorePopup";
import { fetchUserById } from "../../../api/users/usersApi";
import  AdminDrawer from '../../../components/AdminDrawer/AdminDrawer';

const CatererOrders = () => {
    const { dialogContent, dialogOpen, handleOpenDialog, handleCloseDialog } = useDialog();

    const theme = useTheme(); // Get the theme
    const [orderData, setOrderData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success',
    });
    const [backgroundColor, setBackgroundColor] = useState('#f2f2f2'); // Default background color
    const navigate = useNavigate();

    // Wrap getOrders in useCallback
    const getOrders = useCallback(async () => {
        try {
            const data = await fetchCatererOrders();
            const updatedOrders = data.map(order => ({
                ...order,
                // Disable the respective button based on the deliveryType
                fidDisabled: order.deliveryType === 'FID',
                fedDisabled: order.deliveryType === 'FED',
            }));

            setOrderData(updatedOrders);
            setLoading(false);

            // Set background color based on delivery type
            if (updatedOrders.length > 0) {
                const deliveryType = updatedOrders[0].deliveryType; // Get the delivery type of the first order
                setBackgroundColor(getBackgroundColor(deliveryType)); // Update background color
            }
        } catch (error) {
            setLoading(false);
        }
    }, []); // No dependencies needed here

    const getBackgroundColor = (deliveryType) => {
        switch (deliveryType) {
            case 'FID':
                return '#e3f2fd'; // Light blue
            case 'FED':
                return '#ffe0b2'; // Light orange
            case 'NewOrder':
                return '#e8f5e9'; // Light green
            default:
                return '#f2f2f2'; // Default color
        }
    };

    useEffect(() => {
        getOrders();
    }, [getOrders]); // Include getOrders in the dependency array

    const handleCatererDetails = (catererId) => {
        navigate(`/caterer-details/${catererId}`, {
          state: { catererId }, // Passing both catererId and serviceProviderId
        });
      };

    const handleViewDetails = async (orderId) => {
        try {
            const data = await fetchCatererOrderDetails(orderId);
            navigate(`/caterer-order-invoice/${orderId}`, { state: { orderData: data } });
        } catch (error) {
            console.error("Error fetching order details:", error);
        }
    };

    const handleViewUser = async (row) => {
        try {
          const customerDetails = await fetchUserById(row.userId); // Correct the field to 'userId'
          navigate(`/user-details/${row.userId}`, { state: customerDetails }); // Use the proper userId for navigation
        } catch (error) {
          console.error('Error fetching user details:', error);
        }
      };

    const handleUpdateDeliveryType = async (orderId, deliveryType) => {
        try {
            await updateDeiveryType({ orderId, deliveryType });
            setSnackbar({ open: true, message: `Delivery Type updated to ${deliveryType}`, severity: 'success' });
            getOrders();
        } catch (error) {
            console.error('Failed to update delivery type:', error);
            setSnackbar({ open: true, message: 'Failed to update delivery type', severity: 'error' });
        }
    };

    const columns = [
        { field: 'siNo', headerName: 'ID', minWidth: 120 },
        { field: 'orderId', headerName: 'Order ID', minWidth: 200, renderCell: (params) => {
            return (

                <Typography
                    onClick={() => handleViewDetails(params.row.id)}
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        marginTop: '14px',
                        fontSize: '14px',
                        cursor: 'pointer',
                    }}
                >
                    {params.value} {/* Caterer name displayed here */}
                </Typography>
            );
        }},
        {
            field: "customerName",
            headerName: "Customer Name",
            minWidth: 200,
            renderCell: (params) => {
              return (
                <Typography
                  onClick={() => handleViewUser(params.row)} // Use handleViewUser with the full row
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginTop: "14px",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                >
                  {params.value}
                </Typography>
              );
            },
          },
        
        { field: 'date', headerName: 'Order Date', minWidth: 200 },
        {
            field: 'caterer', headerName: 'Caterer Name', minWidth: 200,  renderCell: (params) => {
                return (
    
                    <Typography
                        onClick={() => handleCatererDetails(params.row.catererId)}
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            marginTop: '14px',
                            fontSize: '14px',
                            cursor: 'pointer',
                        }}
                    >
                        {params.value} {/* Caterer name displayed here */}
                    </Typography>
                );
            }
        },
        { field: 'packageType', headerName: 'Package Ordered', minWidth: 200 },
        { field: 'quantity', headerName: 'Quantity', minWidth: 200 },
        {
            field: 'status',
            headerName: 'Status',
            minWidth: 200,
            renderCell: (params) => {
                const statusMapping = {
                    newOrder: "New Order",
                    preparing: "In-Process",
                    Delivered: "Delivered",
                    Cancelled: "Cancelled",
                    Refunded: "Refunded",
                    Rejected: "Rejected"
                };

                const statusColors = {
                    Delivered: "success",
                    Rejected: "error",
                    newOrder: "success",
                    preparing: "warning",
                    Cancelled: "default",
                    Refunded: "info"
                };

                const label = statusMapping[params.value] || params.value;

                return (
                    <Chip
                        label={label}
                        color={statusColors[params.value] || "primary"}
                    />
                );
            }
        },

        { field: 'rejectReason', headerName: 'Reject Reason', minWidth: 200 },
        { field: 'deliveryDate', headerName: 'Delivery Date', minWidth: 200 },

        {
            field: 'shippingAddress', headerName: 'Delivered Address', minWidth: 250,
            sortable: true,
            renderCell: (params) => {
                const text = params.value || ''; // Ensure value is a string
                return renderCellWithMore(text, handleOpenDialog);
            },
        },
        { field: 'deliveredBy', headerName: 'Delivered By', minWidth: 200 },
        { field: 'total', headerName: 'Total Amount(Before Discount)', minWidth: 300 },
        { field: 'couponCodeUsed', headerName: 'Discount/Coupon Code Used', minWidth: 300 },
        { field: 'discountAmount', headerName: 'Discount Amount', minWidth: 250 },
        { field: 'totalAmount', headerName: 'Total Amount(After Discount)', minWidth: 300 },
        { field: 'paymentStatus', headerName: 'Payment Status', minWidth: 300 },
        {
            field: 'actions',
            headerName: 'Actions',
            minWidth: 200,
            sortable: false,
            renderCell: (params) => (
                <>
                    <Button
                        variant="contained"
                        color='primary'
                        onClick={() => handleUpdateDeliveryType(params.row.id, 'FID')}
                        style={{
                            marginRight: '8px',
                            backgroundColor: params.row.fidDisabled ? theme.palette.grey[400] : '#6f0d6a',
                            pointerEvents: params.row.fidDisabled ? 'none' : undefined,
                        }}
                        disabled={params.row.fidDisabled}
                    >
                        FID
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => handleUpdateDeliveryType(params.row.id, 'FED')}
                        style={{
                            backgroundColor: params.row.fedDisabled ? theme.palette.grey[400] : '#3c006a',
                            pointerEvents: params.row.fedDisabled ? 'none' : undefined,
                        }}
                        disabled={params.row.fedDisabled}
                    >
                        FED
                    </Button>
                    <Tooltip title="View Details">
                        <IconButton
                            color="default"
                            onClick={() => handleViewDetails(params.row.id)}
                        >
                            <VisibilityIcon />
                        </IconButton>
                    </Tooltip>
                </>
            ),
        },
    ];

    return (
        <div style={{ backgroundColor: backgroundColor, height: 'max-content' }}>
                      <AdminDrawer>

            <div style={{ padding: '16px' }}>
                <Typography variant='h4' fontWeight='bolder'>Caterer Orders</Typography>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <Container sx={{ minWidth: '100%' }}>
                        <OrdersTable
                            rows={orderData}
                            columns={columns}
                        />
                    </Container>
                )}
            </div>
            </AdminDrawer>

            {/* Render CustomSnackbar */}
            <CustomSnackbar
                open={snackbar.open}
                message={snackbar.message}
                severity={snackbar.severity}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
            />
            <Dialog open={dialogOpen} onClose={handleCloseDialog}>
                <DialogTitle>Details</DialogTitle>
                <DialogContent>
                    <Typography>{dialogContent}</Typography>
                </DialogContent>
                <Button onClick={handleCloseDialog} color="primary">Close</Button>
            </Dialog>
        </div>
    );
};

export default CatererOrders;
