import React, { useState, useEffect } from 'react';
import { useDialog } from '../../../hooks/useDialog';
import { renderCellWithMore } from '../../../components/ResuableComponents/ViewMoreComponents/ViewMorePopup';
import { useNavigate } from 'react-router-dom';
import AdminDrawer from '../../../components/AdminDrawer/AdminDrawer';
import { IconButton, Tooltip, styled, Container, Tab, Typography, Tabs, Button, Box, Paper, Dialog, DialogContent, DialogTitle } from '@mui/material';
import DataTable from '../../../components/DataTables/DataTable';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { fetchRestaurantItems, fetchRestaurantAddons, fetchRestaurantSubPackages, deleteRestaurantSubPackage, getRestaurantSubPackageById } from '../../../api/restaurantPackages/restaurantItemsApi';
import ConfirmDialog from '../../../components/ResuableComponents/ConfirmDialog';
import CustomSnackbar from '../../../components/CustomeSnackBar/CustomSnackbar';

const StyledTabs = styled(Tabs)(({ theme }) => ({
    '& .MuiTabs-indicator': {
        backgroundColor: 'green',
    },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
    textTransform: 'none',
    color: 'green',
    fontWeight: 400,
    '&.Mui-selected': {
        color: 'green',
        fontWeight: 500,
    },
}));

const tabConfig = [
    { title: 'Items', apiEndpoint: 'restaurantItems' },
    { title: 'Add-ons', apiEndpoint: 'restaurantAddons' },
    // { title: 'SubPackages', apiEndpoint: 'restaurantSubPackages' },
];


const RestaurantItemList = () => {
    const { dialogContent, dialogOpen, handleOpenDialog, handleCloseDialog } = useDialog();
    const [value, setValue] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const navigate = useNavigate();
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [selectedSubPackageId, setSelectedSubPackageId] = useState(null);


    const itemColumns = [
        { field: 'id', headerName: 'ID', minWidth: 10, sortable: false },
        { field: 'restaurantId', headerName: 'Restaurant Id', minWidth: 200, sortable: true,  renderCell: (params) => {
            return (
              <Typography
                onClick={() => {
                  navigate(`/restaurant-details/${params.row.restId}`, {
                    state: { restaurantId: params.row.restId },
                  });
                }}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginTop: "14px",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
              >
                {params.value} {/* Restaurant name displayed here */}
              </Typography>
            );
          }, },
        { field: 'restaurantName', headerName: 'Restaurant Name', minWidth: 200, sortable: true, renderCell: (params) => {
            return (
              <Typography
                onClick={() => {
                  navigate(`/restaurant-details/${params.row.restId}`, {
                    state: { restaurantId: params.row.restId },
                  });
                }}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginTop: "14px",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
              >
                {params.value} {/* Restaurant name displayed here */}
              </Typography>
            );
          }, },
        {
            field: 'Image', headerName: 'Item Image', minWidth: 100, sortable: false, renderCell: (params) => (
                <img src={params.value} alt="Item" style={{ width: '45px', height: '45px', borderRadius: '50%', objectFit: 'cover' }} />
            )
        },
        { field: 'Name', headerName: 'Item Name', minWidth: 200, sortable: true },
        {
            field: 'Description',
            headerName: 'Item Description',
            minWidth: 250,
            sortable: true,
            renderCell: (params) => {
                const text = params.value || ''; // Ensure value is a string
                return renderCellWithMore(text, handleOpenDialog);
            },
        }, { field: 'Price', headerName: 'Price', minWidth: 200, sortable: true },
        { field: 'ComboType', headerName: 'Combo Type', minWidth: 200, sortable: true },
        { field: 'SubPackage', headerName: 'Sub Package Type', minWidth: 250, sortable: true },
        {
            field: 'Comments', headerName: 'Comments', minWidth: 250, sortable: true,
            renderCell: (params) => {
                const text = params.value || ''; // Ensure value is a string
                return renderCellWithMore(text, handleOpenDialog);
            },
        },
        { field: 'CreatedOn', headerName: 'Created On', minWidth: 160, sortable: true },
    ];

    const addonColumns = [
        { field: 'id', headerName: 'ID', minWidth: 10, sortable: false },
        { field: 'restaurantId', headerName: 'Restaurant Id', minWidth: 200, sortable: true },
        { field: 'restaurantName', headerName: 'Restaurant Name', minWidth: 200, sortable: true },
        { field: 'Name', headerName: 'Add-on Name', minWidth: 200, sortable: true },
        { field: 'Price', headerName: 'Add-on Price', minWidth: 200, sortable: true },
        { field: 'ComboType', headerName: 'Type', minWidth: 200, sortable: true },
        {
            field: 'ItemName', headerName: 'Item Name', minWidth: 250, renderCell: (params) => {
                const text = params.value || ''; // Ensure value is a string
                return renderCellWithMore(text, handleOpenDialog);
            },
        },
        { field: 'CreatedOn', headerName: 'Created On', minWidth: 200, sortable: true },
    ];


    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleAddRestaurantSubPackage = () => {
        navigate('/addRestaurantSubPackage');
    };

    const handleEditRestaurantSubPackage = (id) => {
        navigate(`/editRestaurantSubPackage/${id}`);
    };



    const handleViewRestaurantSubPackage = async (id) => {
        try {
            const orderData = await getRestaurantSubPackageById(id);

            console.log('orderData in item', orderData);

            // Navigate to the OrderDetails component and pass orderData as state
            navigate(`/viewRestaurantSubPackage/${id}`, { state: { orderData } });
        } catch (error) {
            setSnackbarMessage('Error fetching subpackage details');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            console.error('Error fetching subpackage:', error);
        }
    };

    const handleOpenConfirmDialog = (id) => {
        setSelectedSubPackageId(id);
        setConfirmDialogOpen(true);
    };

    const handleCloseConfirmDialog = () => {
        setConfirmDialogOpen(false);
    };
    const subPackageColumns = [
        { field: 'id', headerName: 'ID', minWidth: 10, sortable: false },
        {
            field: 'restaurantId', headerName: 'Restaurant Id', minWidth: 250, renderCell: (params) => {
                const text = params.value || ''; // Ensure value is a string
                return renderCellWithMore(text, handleOpenDialog);
            },
        },
        {
            field: 'restaurantName', headerName: 'Restaurant Name', minWidth: 250, renderCell: (params) => {
                const text = params.value || ''; // Ensure value is a string
                return renderCellWithMore(text, handleOpenDialog);
            },
        },
        { field: 'subPackageName', headerName: 'Sub Package Name', minWidth: 250, sortable: true },
        { field: 'CreatedOn', headerName: 'Created On', minWidth: 200, sortable: true },
        {
            field: 'Actions',
            headerName: 'Actions',
            minWidth: 200,
            sortable: false,
            renderCell: (params) => (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Tooltip title="View">
                        <IconButton color="default" onClick={() => handleViewRestaurantSubPackage(params.row._id)}>
                            <VisibilityIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit">
                        <IconButton color="default" onClick={() => handleEditRestaurantSubPackage(params.row._id)}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <IconButton color="default" onClick={() => handleOpenConfirmDialog(params.row._id)}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            ),
        },
    ];

    const handleDeleteRestaurantSubPackage = async () => {
        try {
            await deleteRestaurantSubPackage(selectedSubPackageId);
            setSnackbarMessage('SubPackage deleted successfully');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);

            const restaurantSubPackages = await fetchRestaurantSubPackages();
            const formattedData = restaurantSubPackages.map((subPackage, index) => ({
                id: index + 1,
                restaurantId: subPackage.restaurants.map(rest => rest.restID).join(', '),
                restaurantName: subPackage.restaurants.map(rest => rest.restName).join(', '),
                subPackageName: subPackage?.restSubPackName,
                CreatedOn: new Date(subPackage.createdAt).toLocaleString(),
                _id: subPackage._id,
            }));
            setTableData(formattedData);
            setConfirmDialogOpen(false);
        } catch (error) {
            setSnackbarMessage('Error deleting subpackage');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            console.error('Error deleting subpackage:', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (tabConfig[value].apiEndpoint === 'restaurantItems') {
                const restaurantItems = await fetchRestaurantItems();
                const formattedData = restaurantItems.map((item, index) => ({
                    id: index + 1,
                    restId: item?.restaurant?._id,
                    restaurantId: item?.restaurant?.restID,
                    restaurantName: item?.restaurant?.restName,
                    Name: item.restItemName,
                    Description: item.restItemDescription,
                    Price: item.restItemPrice,
                    ComboType: item.type,
                    SubPackage: item.restSubPackageId?.restSubPackName,
                    Ratings: 4,
                    Comments: 'No comments available',
                    CreatedOn: new Date(item.createdAt).toLocaleString(),
                }));
                setTableData(formattedData);
            } else if (tabConfig[value].apiEndpoint === 'restaurantAddons') {
                const restaurantAddons = await fetchRestaurantAddons();
                const formattedData = restaurantAddons.map((addon, index) => ({
                    id: index + 1,
                    restaurantId: addon?.restaurant?.restID,
                    restaurantName: addon?.restaurant?.restName,
                    Name: addon.addOnName,
                    Price: addon.addOnPrice,
                    ComboType: addon.type,
                    ItemName: addon.menuItem?.restItemName,
                    CreatedOn: new Date(addon.createdAt).toLocaleString(),
                }));
                setTableData(formattedData);
            } else if (tabConfig[value].apiEndpoint === 'restaurantSubPackages') {
                const restaurantSubPackages = await fetchRestaurantSubPackages();
                const formattedData = restaurantSubPackages.map((subPackage, index) => ({
                    id: index + 1,
                    restaurantId: subPackage.restaurants.map(rest => rest.restID).join(', '),
                    restaurantName: subPackage.restaurants.map(rest => rest.restName).join(', '),
                    subPackageName: subPackage.restSubPackName,
                    CreatedOn: new Date(subPackage.createdAt).toLocaleString(),
                    _id: subPackage._id,
                }));
                setTableData(formattedData);
            }
        };

        fetchData();
    }, [value]);

    return (
        <div style={{ backgroundColor: '#f2f2f2' }}>
            <AdminDrawer>
                <Typography variant="h4" fontWeight="bolder">
                    {tabConfig[value].title}
                </Typography>
                <StyledTabs value={value} onChange={handleTabChange} aria-label="tabs">
                    {tabConfig.map((tab, index) => (
                        <StyledTab key={index} label={tab.title} />
                    ))}
                </StyledTabs>
                <Paper>
                    {value === 2 && (
                        <Box display="flex" justifyContent="flex-end">
                            <Button
                                variant="contained"
                                color="success"
                                startIcon={<AddIcon />}
                                onClick={handleAddRestaurantSubPackage}
                                style={{ margin: '20px' }}
                            >
                                Add SubPackage
                            </Button>
                        </Box>
                    )}
                    <DataTable
                        rows={tableData}
                        columns={value === 0 ? itemColumns : value === 1 ? addonColumns : subPackageColumns}
                        onEdit={handleEditRestaurantSubPackage}
                        onDelete={handleOpenConfirmDialog}
                        onView={handleViewRestaurantSubPackage}
                    />
                </Paper>
                <ConfirmDialog
                    open={confirmDialogOpen}
                    onClose={handleCloseConfirmDialog}
                    onConfirm={handleDeleteRestaurantSubPackage}
                    title="Confirm Delete"
                    message="Are you sure you want to delete this subpackage?"
                />
                <CustomSnackbar
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
                <Dialog open={dialogOpen} onClose={handleCloseDialog}>
                    <DialogTitle>Details</DialogTitle>
                    <DialogContent>
                        <Typography>{dialogContent}</Typography>
                    </DialogContent>
                    <Button onClick={handleCloseDialog} color="primary">Close</Button>
                </Dialog>
            </AdminDrawer>
        </div>
    );
};

export default RestaurantItemList;
