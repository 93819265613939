import React from 'react';
import { Paper, CardContent, Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { green } from '@mui/material/colors';

const restaurants = [
    { name: 'Shanthi Sagar', orders: 39, revenue: 3067.88 },
    { name: 'Udupi Kitchen', orders: 39, revenue: 17913.67 },
    { name: 'Sandeep', orders: 38, revenue: 10330.36 },
    { name: 'Bharath', orders: 31, revenue: 674.83 },
    { name: 'Raja', orders: 17, revenue: 74.82 },
];

const DashBoardTopRestaurants = () => {
    return (
        <Paper sx={{ maxWidth: 400, borderRadius: 2 }} elevation={0}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Top Restaurants
                </Typography>
                <List>
                    {restaurants.map((restaurant, index) => (
                        <ListItem key={index} disableGutters>
                            <ListItemIcon>
                                <LocationOnIcon sx={{ color: green[500] }} />
                            </ListItemIcon>
                            <ListItemText
                                primary={restaurant.name}
                                secondary={`Orders: ${restaurant.orders}`}
                            />
                            <Typography variant="body2" sx={{ color: green[500], fontWeight: 'bold' }}>
                                ₹ {restaurant.revenue.toFixed(2)}
                            </Typography>
                        </ListItem>
                    ))}
                </List>
            </CardContent>
        </Paper>
    );
};

export default DashBoardTopRestaurants;
