import React, { useState } from 'react';
import AdminDrawer from '../../../components/AdminDrawer/AdminDrawer';
import { Tabs, Tab } from '@mui/material';
import PackagesPage from './PackagesPage';
import SubPackagesPage from './SubPackagesPage';
import MenuItems from './MenuItems';

const MenuList = () => {
    const [value, setValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div style={{ backgroundColor: '#f2f2f2' }}>
            <AdminDrawer>
                <Tabs value={value} onChange={handleTabChange} aria-label="menu tabs" >
                    <Tab label="Packages" />
                    <Tab label="Subpackages" />
                    <Tab label="Items" />
                </Tabs>

                {value === 0 && <PackagesPage />}
                {value === 1 && <SubPackagesPage />}
                {value === 2 && <MenuItems />}
            </AdminDrawer>
        </div>
    );
};

export default MenuList;
