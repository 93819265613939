import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Download as DownloadIcon } from '@mui/icons-material';
import VerifiedIcon from '../../../assets/verifyIcon.png';
import {
  Box,
  Tabs,
  Tab,
  Typography,
  CircularProgress,
  Alert,
  Grid,
  Card,
  CardContent,
  Button,
  CardMedia,
  IconButton,
  Dialog, DialogActions, DialogContent, DialogTitle, TextField
} from "@mui/material";
import AdminDrawer from "../../../components/AdminDrawer/AdminDrawer";
import {
  fetchCatererDetails,
  fetchCatererPackage,
} from "../../../api/servicePartners/CaterersApis";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PackagePopup from "../../../components/ResuableComponents/packaegPopup";
import CustomSnackbar from "../../../components/CustomeSnackBar/CustomSnackbar";
import baseUrl from "../../../config";

const CatererDetailsPage = () => {
  const { id } = useParams();
  const location = useLocation();
  const { catererId } = location.state || {};
  const [tabIndex, setTabIndex] = useState(0);
  const [catererDetails, setCatererDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [catererPackages, setCatererPackages] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedPackageId, setSelectedPackageId] = useState(null);
  const [isVerified, setIsVerified] = useState(catererDetails?.isVerified);
  const [isRejected, setIsRejected] = useState(false);
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
  const [rejectionReason, setRejectionReason] = useState('');
  const [open, setOpen] = useState(false);  // State to control the modal

  // Snackbar states
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // Default to success

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    const getCatererDetails = async () => {
      setLoading(true);
      setError(null);
      try {
        const details = await fetchCatererDetails(catererId);
        setCatererDetails(details);

      } catch (err) {
        setError("Failed to fetch caterer details.");
      } finally {
        setLoading(false);
      }
    };

    const getCatererPackages = async () => {
      setLoading(true);
      setError(null);
      try {
        const packages = await fetchCatererPackage(catererId);
        setCatererPackages(packages);
      } catch (err) {
        setError("Failed to fetch caterer packages.");
      } finally {
        setLoading(false);
      }
    };

    if (catererId) {
      getCatererDetails();
      getCatererPackages();
    }
  }, [catererId]);

  useEffect(() => {
    setIsVerified(catererDetails?.isVerified)
  }, [catererDetails?.isVerified])

  const handlePackageClick = (packageId) => {
    setSelectedPackageId(packageId); // Set selected package ID
    setOpenPopup(true); // Open the popup
  };

  const handleClosePopup = () => {
    setOpenPopup(false); // Close the popup
    setSelectedPackageId(null); // Clear selected package ID
  };

  const handleVerify = async () => {
    if (!isVerified) {
      try {
        const response = await fetch(`${baseUrl}caterer/verify/${catererId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ catererId }),
        });

        const data = await response.json();

        console.log('data', data);

        if (data.catererDetails.isVerified) {
          setIsVerified(true);
          setSnackbarMessage("Restaurant verified successfully!");
          setSnackbarSeverity("success");
        } else {
          setSnackbarMessage("Verification failed.");
          setSnackbarSeverity("error");
        }
      } catch (error) {
        console.error('Error verifying restaurant:', error);
        setSnackbarMessage("An error occurred while verifying.");
        setSnackbarSeverity("error");
      } finally {
        setSnackbarOpen(true); // Show the snackbar
      }
    }
  };

  // Snackbar close handler
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };


  // Handle reject click (open dialog)
  const handleReject = () => {
    setRejectDialogOpen(true);
  };

  // Handle rejection submission
  const handleRejectSubmit = () => {
    setIsRejected(true);
    setRejectDialogOpen(false);
    setSnackbarMessage(`Rejected with reason: ${rejectionReason}`);
    setSnackbarOpen(true);
  };

  // Handle dialog close without submission
  const handleDialogClose = () => {
    setRejectDialogOpen(false);
  };

  const [selectedImage, setSelectedImage] = useState(null); // State to store the selected image

  // Handle image click to open the preview
  const handleImageClick = (image) => {
    setSelectedImage(image); // Set the clicked image URL
    setOpen(true); // Open the modal
  };

  // Handle modal close
  const handleClose = () => {
    setOpen(false); // Close the modal
    setSelectedImage(null); // Clear the selected image
  };

  const handleDownload = async () => {
    const apiUrl = `${baseUrl}downloadCaterers/${catererId}`;

    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'documents.zip'); // Replace with actual file name if necessary
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };


  return (
    <AdminDrawer>
      <Box
        sx={{
          width: "100%",
          typography: "body1",
          bgcolor: "#f4f6f8",
          padding: 2,
          borderRadius: 2,
        }}
      >
        <Tabs value={tabIndex} onChange={handleTabChange} centered>
          <Tab label="Caterer Details" />
          <Tab label="Caterer Packages" />
        </Tabs>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>


          {/* Rejection Reason Dialog */}
          <Dialog open={rejectDialogOpen} onClose={handleDialogClose}>
            <DialogTitle>Reason for Rejection</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                placeholder="Rejection Reason"
                fullWidth
                variant="outlined"
                value={rejectionReason}
                onChange={(e) => setRejectionReason(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose}>Cancel</Button>
              <Button onClick={handleRejectSubmit} variant="contained" color="error">
                Submit
              </Button>
            </DialogActions>
          </Dialog>

          {/* Snackbar for success messages */}
          <CustomSnackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={() => setSnackbarOpen(false)}
            message={snackbarMessage}
          />
        </Box>

        {tabIndex === 0 && (

          <Box p={3}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                onClick={handleVerify}  // Trigger verification on click
                sx={{
                  backgroundColor: isVerified ? 'transparent' : '#0e0593',  // Green if verified, blue if not
                  color: 'white',  // Always keep the text white for better contrast
                  '&:hover': {
                    backgroundColor: isVerified ? 'transparent' : '#0e0593',  // Darker green or blue on hover
                  },
                }}
              >
                <Typography color='white'>
                  {isVerified ? <img src={VerifiedIcon} style={{ height: '40px', }} /> : 'Verify'}
                </Typography>
              </Button>
            </Box>


            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Caterer Details: {id}
            </Typography>
            {loading && <CircularProgress />}
            {error && <Alert severity="error">{error}</Alert>}

            {catererDetails && (
              <Grid container spacing={2} sx={{ mt: 2 }}>
                {/* Business Profile Card */}
                <Grid container spacing={3}>
                  {/* Business Profile Photo Card */}
                  <Grid item xs={12} md={6}>
                    <Card elevation={3} sx={{ height: '100%', borderRadius: 2, display: 'flex', padding: 2, flexDirection: 'column' }}>
                      <Box sx={{ position: 'relative' }}>
                        <img
                          onClick={() => handleImageClick(catererDetails?.businessProfilePhoto)}
                          src={catererDetails?.businessProfilePhoto}
                          alt="Business Profile"
                          style={{
                            width: '100%',
                            height: '180px',
                            objectFit: 'fill',
                            cursor: 'pointer',
                          }}
                        />
                        <a href={catererDetails?.businessProfilePhoto} download>
                          <IconButton
                            sx={{
                              position: 'absolute',
                              top: 8,
                              right: 8,
                              color: 'green',
                              backgroundColor: 'rgba(255, 255, 255, 0.7)',
                              '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                              },
                            }}
                          >
                            <DownloadIcon fontSize="medium" />
                          </IconButton>
                        </a>
                      </Box>
                      <Dialog open={open} onClose={handleClose} maxWidth="md">
                        <img src={selectedImage} alt="Documents" style={{ maxHeight: '60vh', width: '50vw' }} />
                      </Dialog>
                      <CardContent sx={{ flexGrow: 1 }}>
                        <Typography variant="h6" fontWeight="bold">
                          {catererDetails.businessName}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                          <IconButton size="small">
                            <PhoneIcon fontSize="small" />
                          </IconButton>
                          <Typography color="textSecondary">{catererDetails?.businessPhoneNumber}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <IconButton size="small">
                            <EmailIcon fontSize="small" />
                          </IconButton>
                          <Typography color="textSecondary">
                            {catererDetails?.serviceProvider?.spEmail || 'N/A'}
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', mt: 2 }}>
                          <Typography variant="body2" fontWeight="bold">
                            AboutUs:
                          </Typography>
                          <Typography variant="body2" sx={{ textAlign: 'right', ml: 1 }}>
                            {catererDetails.aboutUs}
                          </Typography>
                        </Box>

                        <Typography variant="body2" fontWeight="bold" >
                          Specialized Areas:
                        </Typography>
                        <Typography variant="body2" sx={{ textAlign: 'right' }}>
                          {catererDetails.specializedAreas.join(', ')}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>

                  {/* Address Card with Service Provider and Registration Details */}
                  <Grid item xs={12} md={6}>
                    <Card elevation={3} sx={{ height: '100%', borderRadius: 2, p: 2, display: 'flex', flexDirection: 'column' }}>
                      <CardContent>
                        <Typography variant="h6" fontWeight="bold">
                          Address
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                          <IconButton size="small">
                            <LocationOnIcon fontSize="small" />
                          </IconButton>
                          <Typography variant="body2">
                            {`${catererDetails?.address?.street}, ${catererDetails?.address?.city}, ${catererDetails?.address?.postalCode}, ${catererDetails?.addressType}`}
                          </Typography>
                        </Box>

                        <Typography variant="h6" fontWeight="bold" sx={{ mt: 2 }}>
                          Service Provider
                        </Typography>
                        <Typography variant="body2">
                          Name: {catererDetails?.serviceProvider?.spName} <br />
                          Phone: {catererDetails?.serviceProvider?.spPhone} <br />
                          Email: {catererDetails?.serviceProvider?.spEmail || 'N/A'}
                        </Typography>

                        <Typography variant="h6" fontWeight="bold" sx={{ mt: 2 }}>
                          Registration Details
                        </Typography>
                        <Typography variant="body2">
                          PAN: {catererDetails?.panNo} <br />
                          GST: {catererDetails?.gstNo} <br />
                          FSSAI: {catererDetails?.fssaiNo}
                        </Typography>

                        {/* Specialized Areas aligned to the right */}

                      </CardContent>
                    </Card>
                  </Grid>

                </Grid>

                {/* Caterer Images Card */}


                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h6" fontWeight="bold" sx={{ mt: 2, alignSelf: 'flex-start' }}>
                      KYC Details
                    </Typography>
                    <Button variant="contained"
                      sx={{ borderRadius: '2rem', alignSelf: 'flex-end' }}
                      onClick={handleDownload}>
                      Download All
                    </Button>
                  </Box>
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <Card
                        sx={{
                          borderRadius: 2,
                          overflow: "hidden",
                          boxShadow: 1,
                          position: 'relative',
                          padding: 2,
                        }}
                      >
                        <Typography variant="body2" fontWeight="bold">
                          PAN No:
                        </Typography>
                        <Typography variant="body2">
                          {catererDetails.panNo}
                        </Typography>
                        {/* Pan Image with Download Icon Overlay */}
                        <Box sx={{ position: 'relative' }}>
                          <img
                            component="img"
                            style={{
                              width: '100%',  // Ensure the image takes up the full card width
                              height: '180px',  // Set a fixed height for the image
                              objectFit: 'fill',  // Ensure the image covers the area
                              cursor: 'pointer',
                            }}
                            src={catererDetails.panImage}  // Image URL
                            alt="Pan Image"
                            onClick={() => handleImageClick(catererDetails.panImage)}  // Pass specific image URL
                          />
                          {/* Download Icon Overlay */}
                          <a href={catererDetails.panImage} download>
                            <IconButton
                              sx={{
                                position: 'absolute',
                                top: 8,
                                right: 8,
                                color: 'green',
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                '&:hover': {
                                  backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                },
                              }}
                            >
                              <DownloadIcon />
                            </IconButton>
                          </a>
                        </Box>
                      </Card>
                    </Grid>
                    <Grid item xs={4}>
                      <Card
                        sx={{
                          borderRadius: 2,
                          overflow: "hidden",
                          boxShadow: 1,
                          position: 'relative',
                          padding: 2,
                        }}
                      >
                        <Typography variant="body2" fontWeight="bold">
                          GST No:
                        </Typography>
                        <Typography variant="body2">
                          {catererDetails.gstNo}
                        </Typography>
                        {/* Gst Image with Download Icon Overlay */}
                        <Box sx={{ position: 'relative' }}>
                          <img
                            component="img"
                            src={catererDetails.gstImage}  // Image URL
                            onClick={() => handleImageClick(catererDetails.gstImage)}  // Pass specific image URL
                            alt="GST Image"
                            style={{
                              width: '100%',  // Ensure the image takes up the full card width
                              height: '180px',  // Set a fixed height for the image
                              objectFit: 'fill',  // Ensure the image covers the area
                              cursor: 'pointer',
                            }}
                          />
                          {/* Download Icon Overlay */}
                          <a href={catererDetails.gstImage} download>
                            <IconButton
                              sx={{
                                position: 'absolute',
                                top: 8,
                                right: 8,
                                color: 'green',
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                '&:hover': {
                                  backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                },
                              }}
                            >
                              <DownloadIcon fontSize="medium" />
                            </IconButton>
                          </a>
                        </Box>
                      </Card>
                    </Grid>
                    <Grid item xs={4}>
                      <Card
                        sx={{
                          borderRadius: 2,
                          overflow: "hidden",
                          boxShadow: 1,
                          position: 'relative',
                          padding: 2,
                        }}
                      >
                        <Typography variant="body2" fontWeight="bold">
                          FSSAI No:
                        </Typography>
                        <Typography variant="body2">
                          {catererDetails.fssaiNo}
                        </Typography>

                        {/* FSSAI Image with Download Icon Overlay */}
                        <Box sx={{ position: 'relative' }}>
                          <img
                            onClick={() => handleImageClick(catererDetails.fssaiImage)}  // Pass specific image URL
                            src={catererDetails.fssaiImage}  // Image URL
                            alt="FSSAI"
                            style={{
                              width: '100%',  // Ensure the image takes up the full card width
                              height: '180px',  // Set a fixed height for the image
                              objectFit: 'fill',  // Ensure the image covers the area
                              cursor: 'pointer',
                            }}
                          />

                          {/* Download Icon Overlay */}
                          <a href={catererDetails.fssaiImage} download>
                            <IconButton
                              sx={{
                                position: 'absolute',
                                top: 8,
                                right: 8,
                                color: 'green',
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                '&:hover': {
                                  backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                },
                              }}
                            >
                              <DownloadIcon fontSize="medium" />
                            </IconButton>
                          </a>
                        </Box>
                      </Card>

                    </Grid>

                    <Grid item xs={4}>
                      <Card
                        sx={{
                          borderRadius: 2,
                          overflow: 'hidden',  // Prevent the card from scrolling
                          boxShadow: 1,
                          position: 'relative',
                          padding: 2,
                          height: 'auto',  // Ensure the card can grow to fit content if needed
                        }}
                      >
                        {/* PDF Preview */}
                        <Box sx={{ position: 'relative', height: '250px' }}>  {/* Match video height */}
                          <iframe
                            src={catererDetails?.contractUrl}  // PDF URL
                            title="Contract PDF"
                            style={{
                              width: '100%',  // Ensure the iframe takes up the full card width
                              height: '100%',  // Fill the container height (250px)
                              border: 'none',  // Remove default iframe borders
                            }}
                          />
                        </Box>

                        {/* Download Icon */}
                        <a href={catererDetails?.contractUrl} download>
                          <IconButton
                            sx={{
                              position: 'absolute',
                              top: 8,
                              right: 20,
                              color: 'green',
                              backgroundColor: 'rgba(255, 255, 255, 0.7)',
                              '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                              },
                            }}
                          >
                            <DownloadIcon fontSize="medium" />
                          </IconButton>
                        </a>
                      </Card>
                    </Grid>


                    <Grid item xs={4}>
                      <Card
                        sx={{

                          boxShadow: 1,
                          padding: 2,
                        }}
                      >
                        <video
                          component="video"
                          controls
                          src={catererDetails?.businessVideo}  // Ensure this contains the correct video URL
                          alt="Business Video"
                          style={{
                            width: '100%',  // Ensure the video takes up the full card width
                            height: '250px',  // Set the height to match the PDF card
                            objectFit: 'fill',  // Ensure the video covers the area
                            cursor: 'pointer',
                          }}
                        />
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Box>
        )}

        {tabIndex === 1 && (
          <Box p={3}>
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Caterer Packages
            </Typography>
            {loading && <CircularProgress />}
            {error && <Alert severity="error">{error}</Alert>}
            {catererPackages.length > 0 ? (
              <Grid container spacing={2}>
                {catererPackages.map((catererPackage) => (
                  <Grid item xs={12} md={6} key={catererPackage._id}>
                    <Card
                      elevation={3}
                      sx={{ borderRadius: 2, cursor: "pointer" }}
                      onClick={() => handlePackageClick(catererPackage._id)}
                    >
                      <CardContent>
                        <Typography variant="h6" fontWeight="bold">
                          {catererPackage.packageName}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography variant="body2" color="textSecondary">
                No packages available.
              </Typography>
            )}
          </Box>
        )}
      </Box>
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />

      {openPopup && (
        <PackagePopup
          open={openPopup}
          onClose={handleClosePopup}
          packageId={selectedPackageId} // Pass packageId to popup
        />
      )}

    </AdminDrawer>
  );
};

export default CatererDetailsPage;
