import React, { useState, useEffect, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { styled, Paper, TextField, useMediaQuery, IconButton, Typography, Box, Button } from '@mui/material';
import { jsonToCSV } from 'react-papaparse';
import debounce from 'lodash/debounce';

const TableContainer = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    maxHeight: 'calc(100vh - 100px)', // Adjust height to leave space for other UI elements
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[0],
    display: 'flex',
    flexDirection: 'column',
}));

const SearchContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
}));

const NoRowsMessage = styled(Typography)(({ theme }) => ({
    textAlign: 'center',
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
}));

// Debounced filtering function
const debouncedFilterRows = debounce((searchValue, rows, columns, setFilteredRows) => {
    if (searchValue === '') {
        setFilteredRows(rows);
    } else {
        const value = searchValue?.toLowerCase();
        const filteredData = rows.filter((row) =>
            columns.some((column) =>
                row[column.field]?.toString().toLowerCase().includes(value)
            )
        );
        setFilteredRows(filteredData);
    }
}, 300);

const DataTable = ({ rows, columns }) => {
    const [searchText, setSearchText] = useState('');
    const [filteredRows, setFilteredRows] = useState(rows);
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const filterRows = useCallback(
        (searchValue) => {
            debouncedFilterRows(searchValue, rows, columns, setFilteredRows);
        },
        [rows, columns]
    );

    useEffect(() => {
        filterRows(searchText);
    }, [searchText, filterRows]);

    const handleSearch = (event) => {
        setSearchText(event.target.value);
    };

    const handleDownloadCSV = () => {
        try {
            const csvData = jsonToCSV(filteredRows);
            const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.href = url;
            link.setAttribute('download', 'data.csv');
            link.click();
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error generating CSV:', error);
        }
    };

    return (
        <TableContainer>
            <SearchContainer>
                <TextField
                    variant="outlined"
                    size="small"
                    placeholder="Search..."
                    value={searchText}
                    onChange={handleSearch}
                    sx={{
                        width: isSmallScreen ? '100%' : '300px',
                        marginBottom: isSmallScreen ? '8px' : '0',
                    }}
                />
                <IconButton onClick={handleDownloadCSV} color="primary">
                    <Button variant='contained'>Export</Button>
                </IconButton>
            </SearchContainer>
            {filteredRows?.length === 0 ? (
                <NoRowsMessage>No matching rows found.</NoRowsMessage>
            ) : (
                <Box sx={{ height: '60vh', width: '90vw' }}>
                    <DataGrid
                        disableColumnResize={true}
                        columnHeaderHeight={33}
                        disableColumnSelector={true}
                        disableColumnSorting={false}
                        disableDensitySelector={true}
                        disableRowSelectionOnClick={true}
                        showCellVerticalBorder={true}
                        rows={filteredRows}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        pageSizeOptions={[10, 25, 50]}
                        getRowHeight={() => 70}
                        sx={{
                            '& .MuiDataGrid-columnHeader': {
                                fontWeight: 'bold', // Make header text bold
                                fontSize: '14px',   // Adjust font size
                            },
                            '& .MuiDataGrid-columnHeaderTitle': {
                                fontWeight: 'bold',
                            },
                            '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
                                visibility: 'visible',  // Ensure the filter icon is always visible
                                width: 'auto',
                                marginRight: '8px',
                            },
                            '& .MuiDataGrid-columnHeader .MuiDataGrid-menuIcon': {
                                display: 'block',        // Always display the filter icon (Menu icon)
                            },
                            '& .MuiDataGrid-columnHeader .MuiDataGrid-menuIcon': {
                            visibility: 'visible', // Ensure the filter icon is always visible
                            width: '24px',         // Fixed width for the icon to prevent layout shift
                            height: '24px',        // Fixed height for consistency
                            marginLeft: '8px',     // Adjust space between the header title and the icon
                            display: 'inline-block', // Display as inline block to avoid breaking layout
                            opacity: 1,            // Ensure the icon is fully visible
                            transition: 'none',     // Remove transition to prevent movement
                        },
                        '& .MuiDataGrid-columnHeader:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.04)', // Optional: Add hover effect without affecting layout
                        }
                        }}
                    />
                </Box>

            )}
        </TableContainer>
    );
};

export default DataTable;
