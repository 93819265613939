import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Download as DownloadIcon } from '@mui/icons-material';

import {
  Box,
  Tabs,
  Tab,
  Typography,
  CircularProgress,
  Alert,
  Grid,
  Card,
  CardContent,
  IconButton,
  Button,
  Dialog,
} from "@mui/material";
import AdminDrawer from "../../../components/AdminDrawer/AdminDrawer";
import { fetchRestaurantById } from "../../../api/servicePartners/RestaurantApis";
import VerifiedIcon from '../../../assets/verifyIcon.png';
import PhoneIcon from "@mui/icons-material/Phone";
import AccessTimeIcon from "@mui/icons-material/AccessTime"; // Icon for timing
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining"; // Icon for delivery timing
import CustomSnackbar from "../../../components/CustomeSnackBar/CustomSnackbar";
import baseUrl from "../../../config";

const RestaurantDetailsPage = () => {
  const location = useLocation();
  const { restaurantId } = location.state || {};
  const [tabIndex, setTabIndex] = useState(0);
  const [restaurantDetails, setRestaurantDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isVerified, setIsVerified] = useState(false);

  console.log(restaurantId);
  

  // Snackbar states
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // Default to success

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleVerify = async () => {
    if (!isVerified) {
      try {
        const response = await fetch(`${baseUrl}restaurant/verify/${restaurantId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ restaurantId }),
        });

        const data = await response.json();

        console.log('data', data);

        if (data?.restaurantDetails?.isVerified) {
          setIsVerified(true);
          setSnackbarMessage("Restaurant verified successfully!");
          setSnackbarSeverity("success");
        } else {
          setSnackbarMessage("Verification failed.");
          setSnackbarSeverity("error");
        }
      } catch (error) {
        console.error('Error verifying restaurant:', error);
        setSnackbarMessage("An error occurred while verifying.");
        setSnackbarSeverity("error");
      } finally {
        setSnackbarOpen(true); // Show the snackbar
      }
    }
  };

  useEffect(() => {
    const getRestaurantDetails = async () => {
      setLoading(true);
      setError(null);
      try {
        const details = await fetchRestaurantById(restaurantId);
        setRestaurantDetails(details?.data);
      } catch (err) {
        setError("Failed to fetch restaurant details.");
      } finally {
        setLoading(false);
      }
    };

    if (restaurantId) {
      getRestaurantDetails();
    }
  }, [restaurantId]);

  useEffect(() => {
    if (restaurantDetails?.isVerified !== undefined) {
      setIsVerified(restaurantDetails.isVerified);
    }
  }, [restaurantDetails]);

  // Snackbar close handler
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const [open, setOpen] = useState(false);  // State to control the modal

  const [selectedImage, setSelectedImage] = useState(null); // State to store the selected image

  // Handle image click to open the preview
  const handleImageClick = (image) => {
    setSelectedImage(image); // Set the clicked image URL
    setOpen(true); // Open the modal
  };

  // Handle modal close
  const handleClose = () => {
    setOpen(false); // Close the modal
    setSelectedImage(null); // Clear the selected image
  };


  const handleDownload = async () => {
    const apiUrl = `${baseUrl}downloadRestaurants/${restaurantId}`;

    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'documents.zip'); // Replace with actual file name if necessary
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  return (
    <AdminDrawer>
      <Box
        sx={{
          width: "100%",
          typography: "body1",
          bgcolor: "#f4f6f8",
          padding: 2,
          borderRadius: 2,
        }}
      >
        <Tabs value={tabIndex} onChange={handleTabChange} centered>
          <Tab label="Restaurant Details" />
          <Tab label="Menu Details" />
        </Tabs>

        {tabIndex === 0 && (
          <Box p={3}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                onClick={handleVerify}  // Trigger verification on click
                sx={{
                  backgroundColor: isVerified ? 'transparent' : '#0e0593',  // Green if verified, blue if not
                  color: 'white',  // Always keep the text white for better contrast
                  '&:hover': {
                    backgroundColor: isVerified ? 'transparent' : '#0e0593',  // Darker green or blue on hover
                  },
                  borderRadius: 20,
                }}
              >
                <Typography color='white'>
                  {isVerified ? <img src={VerifiedIcon} style={{ height: '40px', }} alt="verify" /> : 'Verify'}
                </Typography>
              </Button>
            </Box>

            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Restaurant Details:
            </Typography>

            {loading && <CircularProgress />}
            {error && <Alert severity="error">{error}</Alert>}

            {restaurantDetails ? (
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid container spacing={2}>
                  {/* Restaurant Profile Card */}
                  <Grid item xs={12} md={6}>
                    <Card elevation={3} sx={{ borderRadius: 2 }}>
                      <Box sx={{ position: 'relative' }}>
                        <img
                          onClick={() => handleImageClick(restaurantDetails?.restaurantImages?.[0])}  // Pass specific image URL
                          src={restaurantDetails?.restaurantImages?.[0]}  // Image URL
                          alt="Business Profile"
                          style={{
                            width: '100%',
                            height: '180px',
                            objectFit: 'cover',
                            cursor: 'pointer',
                          }}
                        />
                        {/* Download Icon on Top of the Image */}
                        <a href={restaurantDetails?.restaurantImages?.[0]} download>
                          <IconButton
                            sx={{
                              position: 'absolute',
                              top: 8,
                              right: 8,
                              color: 'green',
                              backgroundColor: 'rgba(255, 255, 255, 0.7)',
                              '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                              },
                            }}
                          >
                            <DownloadIcon fontSize="medium" />
                          </IconButton>
                        </a>
                      </Box>

                      {/* Dialog for full-size image */}
                      <Dialog open={open} onClose={handleClose} maxWidth="md">
                        <img src={selectedImage} alt="Documents" style={{ maxHeight: '60vh', width: '50vw', objectFit: 'fill' }} />
                      </Dialog>

                      {/* Card Content */}
                      <CardContent>
                        <Typography variant="h6" fontWeight="bold">
                          {restaurantDetails.restName}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                          <IconButton size="small">
                            <PhoneIcon fontSize="small" />
                          </IconButton>
                          <Typography color="textSecondary">
                            {restaurantDetails.managerNumber}
                          </Typography>
                        </Box>

                        {/* Owner Details */}
                        <Box sx={{ mt: 1 }}>
                          <Typography variant="h6" fontWeight="bold">
                            Owner Details
                          </Typography>
                          <Typography variant="body2">
                            Name: {restaurantDetails.serviceProvider?.spName || 'N/A'}
                          </Typography>
                          <Typography variant="body2">
                            Contact: {restaurantDetails.serviceProvider?.spPhone || 'N/A'}
                          </Typography>
                          <Typography variant="body2">
                            Email: {restaurantDetails.serviceProvider?.spEmail || 'N/A'}
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>

                  {/* Address and Additional Cards */}
                  <Grid item xs={12} md={6}>
                    <Card elevation={3} sx={{ borderRadius: 2, p: 2 }}>
                      <Typography variant="h6" fontWeight="bold">
                        Address
                      </Typography>
                      <Typography variant="body2">
                        {`${restaurantDetails.restAddress?.addressLine1}, ${restaurantDetails.restAddress?.addressLine2}, ${restaurantDetails.restAddress?.city}, ${restaurantDetails.restAddress?.postalCode}`}
                      </Typography>
                    </Card>

                    <Card elevation={3} sx={{ borderRadius: 2, p: 2, mt: 1 }}>
                      <Typography variant="h6" fontWeight="bold">
                        Cuisine
                      </Typography>
                      <Typography variant="body2">
                        {restaurantDetails?.cuisine?.join(', ') || 'N/A'}
                      </Typography>
                    </Card>

                    <Card elevation={3} sx={{ borderRadius: 2, p: 2, mt: 1 }}>
                      <Typography variant="h6" fontWeight="bold">
                        Restaurant Description
                      </Typography>
                      <Typography variant="body2">
                        {restaurantDetails.restDescription || 'N/A'}
                      </Typography>
                    </Card>
                  </Grid>
                </Grid>


                <Grid item xs={12}>
                  <Card elevation={3} sx={{ borderRadius: 2, p: 2 }}>
                    <Typography variant="h6" fontWeight="bold">
                      Restaurant Timings
                    </Typography>

                    {/* Wrap both sections in a flexbox container */}
                    <Box sx={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>

                      {/* Operational Hours Section */}
                      <Box sx={{ flex: 1, pr: 2 }}> {/* Add padding to the right for spacing */}
                        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                          <AccessTimeIcon sx={{ mr: 1 }} />
                          <Typography variant="body2" fontWeight="bold">
                            Operational Hours
                          </Typography>
                        </Box>
                        {restaurantDetails?.operationalTime?.map((time) => (
                          <Box
                            key={time._id}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mt: 0.5,
                              flexWrap: "wrap", // Ensure rows wrap if necessary
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                width: "100px",
                                minWidth: "100px",
                                fontWeight: "bold",
                                mr: 2, // Add margin to space between day and slots
                              }}
                            >
                              {time.day}
                            </Typography>
                            {/* Check if the time has slots */}
                            <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                              {time.slots ? (
                                // If slots are present, map through the slots
                                time.slots.map((slot) => (
                                  <Typography
                                    variant="body2"
                                    key={slot._id}
                                    sx={{ marginRight: 1, whiteSpace: "nowrap" }} // Ensure text stays on one line
                                  >
                                    {slot.startTime} - {slot.endTime}
                                  </Typography>
                                ))
                              ) : (
                                // If no slots, directly render startTime and endTime
                                <Typography variant="body2">
                                  {time.startTime} - {time.endTime}
                                </Typography>
                              )}
                            </Box>
                          </Box>
                        ))}
                      </Box>

                      {/* Delivery Timings Section */}
                      <Box sx={{ flex: 1, pl: 2 }}> {/* Add padding to the left for spacing */}
                        <Box sx={{ display: "flex", alignItems: "center", mb: 1, mt: 2 }}>
                          <DeliveryDiningIcon sx={{ mr: 1 }} />
                          <Typography variant="body2" fontWeight="bold">
                            Delivery Timings
                          </Typography>
                        </Box>
                        {restaurantDetails?.deliveryTimings?.map((delivery) => (
                          <Box
                            key={delivery._id}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mt: 0.5,
                              flexWrap: "wrap", // Ensure rows wrap if necessary
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                width: "100px",
                                minWidth: "100px",
                                fontWeight: "bold",
                                mr: 2, // Add margin to space between day and slots
                              }}
                            >
                              {delivery.day}
                            </Typography>
                            {/* Check if the delivery time has slots */}
                            <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                              {delivery.slots ? (
                                // If slots are present, map through the slots
                                delivery.slots.map((slot) => (
                                  <Typography
                                    variant="body2"
                                    key={slot._id}
                                    sx={{ marginRight: 1, whiteSpace: "nowrap" }} // Ensure text stays on one line
                                  >
                                    {slot.startTime} - {slot.endTime}
                                  </Typography>
                                ))
                              ) : (
                                // If no slots, directly render startTime and endTime
                                <Typography variant="body2">
                                  {delivery.startTime} - {delivery.endTime}
                                </Typography>
                              )}
                            </Box>
                          </Box>
                        ))}
                      </Box>

                    </Box>
                  </Card>
                </Grid>


                {/* Cuisine Card */}
                <Grid item xs={12}></Grid>

                {/* PAN, GST, FSSAI Card */}
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h6" fontWeight="bold" sx={{ mt: 2, alignSelf: 'flex-start' }}>
                      KYC Details
                    </Typography>
                    <Button variant="contained"
                      sx={{ borderRadius: '2rem', alignSelf: 'flex-end' }}
                      onClick={handleDownload}>
                      Download All
                    </Button>
                  </Box>
                  <Card elevation={3} sx={{ borderRadius: 2, p: 2 }}>
                    <Typography variant="h6" fontWeight="bold">
                      Legal Information
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Box sx={{ position: 'relative' }}>
                          <Typography variant="body2" fontWeight="bold">
                            PAN No:
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            {restaurantDetails.panNo}
                          </Typography>
                          <img
                            onClick={() => handleImageClick(restaurantDetails.panImage)}  // Pass specific image URL
                            src={restaurantDetails.panImage}  // Image URL
                            alt="Business Profile"
                            style={{
                              width: '100%',
                              height: '180px',
                              objectFit: 'fill',
                              cursor: 'pointer',
                            }}
                          />
                          {/* Download Icon on Top of the Image */}
                          <a href={restaurantDetails.panImage} download>
                            <IconButton
                              sx={{
                                position: 'absolute',
                                top: 20,
                                right: 8,
                                color: 'green',
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                '&:hover': {
                                  backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                },
                              }}
                            >
                              <DownloadIcon fontSize="medium" />
                            </IconButton>
                          </a>
                        </Box>
                      </Grid>

                      <Grid item xs={4}>
                        <Box sx={{ position: 'relative' }}>

                          <Typography variant="body2" fontWeight="bold">
                            GST No:
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            {restaurantDetails.gstNo}
                          </Typography>

                          <img
                            src={restaurantDetails.gstImage}  // Image URL
                            alt="GST"
                            onClick={() => handleImageClick(restaurantDetails.gstImage)}  // Pass specific image URL
                            style={{
                              width: '100%',
                              height: '180px',
                              objectFit: 'fill',
                              cursor: 'pointer',
                            }}
                          />
                          <a href={restaurantDetails.gstImage} download>
                            <IconButton
                              sx={{
                                position: 'absolute',
                                top: 8,
                                right: 8,
                                color: 'green',
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                '&:hover': {
                                  backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                },
                              }}
                            >
                              <DownloadIcon fontSize="medium" />
                            </IconButton>
                          </a>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box sx={{ position: 'relative' }}>

                          <Typography variant="body2" fontWeight="bold">
                            FSSAI No:
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            {restaurantDetails.fssaiNo}
                          </Typography>
                          <img
                            onClick={() => handleImageClick(restaurantDetails.fssaiImage)}  // Pass specific image URL
                            src={restaurantDetails.fssaiImage}  // Image URL
                            alt="FSSAI"
                            style={{
                              width: '100%',
                              height: '180px',
                              objectFit: 'fill',
                              cursor: 'pointer',
                            }}
                          />
                          <a href={restaurantDetails.fssaiImage} download>
                            <IconButton
                              sx={{
                                position: 'absolute',
                                top: 8,
                                right: 8,
                                color: 'green',
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                '&:hover': {
                                  backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                },
                              }}
                            >
                              <DownloadIcon fontSize="medium" />
                            </IconButton>
                          </a>
                        </Box>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card
                    sx={{
                      borderRadius: 2,
                      overflow: 'hidden',  // Prevent the card from scrolling
                      boxShadow: 1,
                      position: 'relative',
                      padding: 2,
                      height: 'auto',  // Ensure the card can grow to fit content if needed
                    }}
                  >
                    {/* PDF Preview */}
                    <Box sx={{ position: 'relative', height: '250px' }}>  {/* Match video height */}
                      <iframe
                        src={restaurantDetails?.contractUrl}  // PDF URL
                        title="Contract PDF"
                        style={{
                          width: '100%',  // Ensure the iframe takes up the full card width
                          height: '100%',  // Fill the container height (250px)
                          border: 'none',  // Remove default iframe borders
                        }}
                      />
                    </Box>

                    {/* Download Icon */}
                    <a href={restaurantDetails?.contractUrl} download>
                      <IconButton
                        sx={{
                          position: 'absolute',
                          top: 8,
                          right: 20,
                          color: 'green',
                          backgroundColor: 'rgba(255, 255, 255, 0.7)',
                          '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.9)',
                          },
                        }}
                      >
                        <DownloadIcon fontSize="medium" />
                      </IconButton>
                    </a>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card elevation={3} sx={{ borderRadius: 2, p: 2 }}>
                    <Typography variant="h6" fontWeight="bold">
                      Branches
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                      {restaurantDetails?.branches?.length > 0 ? (
                        restaurantDetails?.branches?.map((branch) => (
                          <Box
                            key={branch._id}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: 1,
                              p: 1,
                              borderBottom: "1px solid #e0e0e0",
                            }}
                          >
                            <Typography variant="body2" fontWeight="bold">
                              {branch.branchName}
                            </Typography>
                          </Box>
                        ))
                      ) : (
                        <Typography variant="body2">
                          No branches available
                        </Typography>
                      )}
                    </Box>
                  </Card>
                </Grid>
              </Grid>
            ) : (
              !loading && (
                <Typography variant="body2" color="textSecondary">
                  No restaurant details available.
                </Typography>
              )
            )}
          </Box>
        )}

        {tabIndex === 1 && (
          <Box p={3}>
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Menu Details
            </Typography>
            {loading && <CircularProgress />}
            {error && <Alert severity="error">{error}</Alert>}
            {restaurantDetails.menu.length > 0 ? (
              <Grid container spacing={2}>
                {restaurantDetails.menu.map((menuItem) => (
                  <Grid item xs={12} key={menuItem._id}>
                    <Card elevation={3} sx={{ borderRadius: 2, mb: 2 }}>
                      <CardContent>
                        {menuItem.subPackages &&
                          menuItem.subPackages.length > 0 && (
                            <Box sx={{ mt: 2 }}>
                              {menuItem.subPackages.map((subPackage) => (
                                <Box key={subPackage._id} sx={{ mt: 1, mb: 2 }}>
                                  <Typography variant="h6" fontWeight="bold">
                                    {subPackage.restSubPackName}
                                  </Typography>
                                  <Box sx={{ mt: 1 }}>
                                    {subPackage.items.map((item) => (
                                      <Box
                                        key={item._id}
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                          border: "1px solid #e0e0e0",
                                          borderRadius: 1,
                                          padding: 1,
                                          mb: 1,
                                        }}
                                      >
                                        <Box>
                                          <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                          >
                                            {item.restItemName}
                                          </Typography>
                                          <Typography variant="body2">
                                            {item.restItemDescription}
                                          </Typography>
                                        </Box>
                                        <Typography variant="body1">
                                          ₹{item.restItemPrice}
                                        </Typography>
                                      </Box>
                                    ))}
                                  </Box>
                                </Box>
                              ))}
                            </Box>
                          )}
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            ) : (
              !loading && (
                <Typography variant="body2" color="textSecondary">
                  No menu details available.
                </Typography>
              )
            )}
          </Box>
        )}
      </Box>
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </AdminDrawer>
  );
};

export default RestaurantDetailsPage;

