import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Download as DownloadIcon } from '@mui/icons-material';
import {
  Tabs, Tab, Typography, Box, Paper, Grid, Card, Button,
  CardMedia,
  IconButton,
  Dialog, DialogActions, DialogContent, DialogTitle, TextField
} from "@mui/material";
import AdminDrawer from "../../../../components/AdminDrawer/AdminDrawer";
import baseUrl from "../../../../config";

const FlowerDetails = () => {
  const location = useLocation();
  const { flowersId } = location.state || {};
  const [flowerData, setFlowerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);  // State to control the modal
  const [selectedImage, setSelectedImage] = useState(null); // State to store the selected image

  // Handle image click to open the preview
  const handleImageClick = (image) => {
    setSelectedImage(image); // Set the clicked image URL
    setOpen(true); // Open the modal
  };

  // Handle modal close
  const handleClose = () => {
    setOpen(false); // Close the modal
    setSelectedImage(null); // Clear the selected image
  };

  useEffect(() => {
    if (flowersId) {
      const fetchFlowerDetails = async () => {
        try {
          const response = await fetch(`${baseUrl}flowerSp/${flowersId}`);
          const data = await response.json();
          if (response.ok) {
            setFlowerData(data);
          } else {
            console.error("Failed to fetch flower details");
          }
        } catch (error) {
          console.error("Error fetching flower details:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchFlowerDetails();
    } else {
      setLoading(false);
    }
  }, [flowersId]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (loading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  const handleDownload = async () => {
    const apiUrl = `${baseUrl}downloadFessteMarts/${flowerData?.fessteMart._id}`;

    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'documents.zip'); // Replace with actual file name if necessary
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  const FlowerInfo = () => (
    <Box p={2}>
      <Typography variant="h5">Flower Information</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography>Flower ID: {flowerData?.flowersID}</Typography>
          <Typography>Created On: {new Date(flowerData?.createdAt).toLocaleString()}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography>Service Provider Name: {flowerData?.serviceProvider?.spName}</Typography>
          <Typography>Email: {flowerData?.serviceProvider?.spEmail}</Typography>
          <Typography>Contact: {flowerData?.serviceProvider?.spPhone}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Service Provider Details</Typography>
          <Typography>Flower Service Provider ID: {flowerData?.fessteMart.fmID}</Typography>
          <Typography>Business Name: {flowerData?.fessteMart?.businessName}</Typography>
          <Typography>PAN No: {flowerData?.fessteMart?.panNo}</Typography>
          <Typography>GST No: {flowerData?.fessteMart?.gstNo}</Typography>

        </Grid>


      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6" fontWeight="bold" sx={{ mt: 2, alignSelf: 'flex-start' }}>
          KYC Details
        </Typography>
        <Button variant="contained"
          sx={{ borderRadius: '2rem', alignSelf: 'flex-end' }}
          onClick={handleDownload}>
          Download All
        </Button>
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Card
            sx={{
              borderRadius: 2,
              overflow: "hidden",
              boxShadow: 1,
              position: 'relative',
              padding: 2,
            }}
          >
            <Typography variant="body2" fontWeight="bold">
              PAN No:
            </Typography>
            <Typography variant="body2">
              {flowerData?.fessteMart.panNo}
            </Typography>
            {/* Pan Image with Download Icon Overlay */}
            <Box sx={{ position: 'relative' }}>
              <img
                component="img"
                style={{
                  width: '100%',  // Ensure the image takes up the full card width
                  height: '180px',  // Set a fixed height for the image
                  objectFit: 'fill',  // Ensure the image covers the area
                  cursor: 'pointer',
                }}
                src={flowerData?.fessteMart.panImage}  // Image URL
                alt="Pan Image"
                onClick={() => handleImageClick(flowerData?.fessteMart.panImage)}  // Pass specific image URL
              />
              {/* Download Icon Overlay */}
              <a href={flowerData?.fessteMart.panImage} download>
                <IconButton
                  sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    color: 'green',
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    },
                  }}
                >
                  <DownloadIcon />
                </IconButton>
              </a>
            </Box>
          </Card>
        </Grid>
        <Dialog open={open} onClose={handleClose} maxWidth="md">
          <img src={selectedImage} alt="Documents" style={{ maxHeight: '60vh', width: '50vw' }} />
        </Dialog>
        <Grid item xs={4}>
          <Card
            sx={{
              borderRadius: 2,
              overflow: "hidden",
              boxShadow: 1,
              position: 'relative',
              padding: 2,
            }}
          >
            <Typography variant="body2" fontWeight="bold">
              GST No:
            </Typography>
            <Typography variant="body2">
              {flowerData?.fessteMart.gstNo}
            </Typography>
            {/* Gst Image with Download Icon Overlay */}
            <Box sx={{ position: 'relative' }}>
              <img
                component="img"
                src={flowerData?.fessteMart.gstImage}  // Image URL
                onClick={() => handleImageClick(flowerData?.fessteMart.gstImage)}  // Pass specific image URL
                alt="GST Image"
                style={{
                  width: '100%',  // Ensure the image takes up the full card width
                  height: '180px',  // Set a fixed height for the image
                  objectFit: 'fill',  // Ensure the image covers the area
                  cursor: 'pointer',
                }}
              />
              {/* Download Icon Overlay */}
              <a href={flowerData?.fessteMart.gstImage} download>
                <IconButton
                  sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    color: 'green',
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    },
                  }}
                >
                  <DownloadIcon fontSize="medium" />
                </IconButton>
              </a>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={4}>
          <Card
            sx={{
              overflow: "hidden",
              boxShadow: 1,
              padding: 2,
            }}
          >
            <video
              component="video"
              controls
              src={flowerData?.fessteMart?.businessVideo} // Ensure this contains the correct video URL
              alt="Business Video"
              height="200"
              style={{
                width: '100%',  // Ensure the image takes up the full card width
                height: '250px',  // Set a fixed height for the image
                objectFit: 'fill',  // Ensure the image covers the area
                cursor: 'pointer',
              }}
            />
          </Card>
        </Grid>
        <Grid item xs={4}>
        <Card
          sx={{
            borderRadius: 2,
            overflow: 'hidden',  // Prevent the card from scrolling
            boxShadow: 1,
            position: 'relative',
            padding: 2,
            height: 'auto',  // Ensure the card can grow to fit content if needed
          }}
        >
          {/* PDF Preview */}
          <Box sx={{ position: 'relative', height: '250px' }}>  {/* Match video height */}
            <iframe
              src={flowerData?.fessteMart?.contractUrl}  // PDF URL
              title="Contract PDF"
              style={{
                width: '100%',  // Ensure the iframe takes up the full card width
                height: '100%',  // Fill the container height (250px)
                border: 'none',  // Remove default iframe borders
              }}
            />
          </Box>

          {/* Download Icon */}
          <a href={flowerData?.fessteMart?.contractUrl} download>
            <IconButton
              sx={{
                position: 'absolute',
                top: 8,
                right: 20,
                color: 'green',
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.9)',
                },
              }}
            >
              <DownloadIcon fontSize="medium" />
            </IconButton>
          </a>
        </Card>
      </Grid>
      </Grid>
      {/* New Grid for Images */}
    

    </Box>
  );

  const FlowerMenu = () => (
    <Box p={2}>
      <Typography variant="h5">Flower Menu</Typography>
      {flowerData?.menu?.flowers && flowerData.menu.flowers.length > 0 ? (
        <Grid container spacing={2}>
          {flowerData.menu.flowers.map((flower) => (
            <Grid item xs={12} md={6} key={flower._id}>
              <Paper elevation={2} style={{ padding: '16px' }}>
                <Typography variant="h6">{flower.flowerType.typeName}</Typography>
                <img src={flower.flowerType.images[0]} alt={flower.flowerType.typeName} width="100" />
                <Typography>Price: ₹{flower.flowerType.price}</Typography>
                <Typography>Description: {flower.flowerType.description}</Typography>
                <Typography>Available: {flower.availability ? "Yes" : "No"}</Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography>No menu available</Typography>
      )}
    </Box>
  );



  return (
    <AdminDrawer>
      <Paper>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Flower Info" />
          <Tab label="Flower Menu" />
        </Tabs>
        {value === 0 && <FlowerInfo />}
        {value === 1 && <FlowerMenu />}
      </Paper>
    </AdminDrawer>
  );
};

export default FlowerDetails;
