import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab, Typography, Paper, Container, Card, CardContent, Grid, Dialog, DialogContent, DialogTitle, Button } from '@mui/material';
import AdminDrawer from '../../components/AdminDrawer/AdminDrawer';
import { useLocation } from 'react-router-dom';
import PayoutTable from '../../components/DataTables/PayoutTables/PayoutTable';
import { fetchCatererOrders, fetchRestaurantOrders } from '../../api/users/usersApi';
import { useDialog } from '../../hooks/useDialog';
import { renderCellWithMore } from '../../components/ResuableComponents/ViewMoreComponents/ViewMorePopup';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: { xs: 2, sm: 3 } }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const UserDetailsPage = () => {
    const { dialogContent, dialogOpen, handleOpenDialog, handleCloseDialog } = useDialog();

    const [tabIndex, setTabIndex] = useState(0);
    const location = useLocation();
    const customerDetails = location.state;
    const [catererOrderData, setCatererOrderData] = useState([]);
    const [restaurantOrderData, setRestaurantOrderData] = useState([]);


    const handleTabChange = (event, newIndex) => {
        setTabIndex(newIndex);
    };
    // Fetch caterer orders when the "Caterer Orders" tab is active
    useEffect(() => {
        if (tabIndex === 1) {
            const getCatererOrders = async () => {
                const orders = await fetchCatererOrders(location.state._id);

                // Map API response keys to match with the table columns
                const formattedCatererOrders = orders.map((order, index) => ({
                    id: order._id,
                    siNo: index + 1,
                    orderId: order?.orderID,
                    serviceProviderId: order?.catererId?.catID,
                    serviceProviderName: order?.catererId?.businessName,
                    orderDate: order?.orderDate,
                    deliveryAddress: `${order?.shippingAddress?.street}, ${order?.shippingAddress?.city}, ${order?.shippingAddress?.postalCode}`,
                    pickupAddress: `${order?.catererId?.address?.street}, ${order?.catererId?.address?.city}, ${order?.catererId?.address?.postalCode}`,
                    itemsOrdered: order.items
                        ?.flatMap(item => item?.subPackages
                            ?.flatMap(subPackage => subPackage?.selectedItems
                                ?.map(selectedItem => selectedItem?.itemName)
                            )
                        )
                        .filter(Boolean)  // Remove any undefined or null values
                        .join(', '),
                    paymentMethod: order?.paymentMethod,
                    paymentStatus: order?.paymentStatus,
                    discountObtained: order?.discountObtained,
                    couponUsed: order?.couponUsed,
                    amountDiscounted: order?.amountDiscounted,
                    orderStatus: order.status,
                    reasonIfCancelled: order.reasonIfCancelled || '',
                    refundEligibility: order.refundEligibility ? 'Yes' : 'No',
                    refundedAmountIfRefunded: order.refundedAmountIfRefunded || '',
                    suggestions: order.specialInstructions,
                    feedback: order.feedback,
                    ratings: order.ratings,
                    orderTotal: order.orderTotal,
                }));

                setCatererOrderData(formattedCatererOrders);
            };

            getCatererOrders();
        }
        if (tabIndex === 2) {
            const getRestaurantOrders = async () => {
                try {
                    const orders = await fetchRestaurantOrders(location.state._id);

                    // Map API response keys to match with the table columns
                    const formattedRestaurantOrders = orders.map((order, index) => ({
                        id: order._id,
                        siNo: index + 1,
                        orderId: order.restaurant_orderId,
                        serviceProviderId: order.restaurantId.restID,
                        serviceProviderName: order.restaurantId.restName,
                        orderDate: order.createdAt,
                        deliveryAddress: `${order?.deliveryAddress?.street}, ${order?.deliveryAddress?.city}, ${order?.deliveryAddress?.postalCode}`,
                        pickupAddress: `${order.restaurantId.restAddress.street}, ${order.restaurantId.restAddress.city}, ${order.restaurantId.restAddress.postalCode}`,
                        itemsOrdered: order.items
                            ?.map(selectedItem => selectedItem.name)
                            .filter(Boolean)  // Remove any undefined or null values
                            .join(', '),
                        paymentMethod: order.paymentMethod,
                        paymentStatus: order.paymentStatus,
                        discountObtained: order.discountObtained,
                        couponUsed: order.couponUsed,
                        amountDiscounted: order.amountDiscounted,
                        orderStatus: order.status,
                        reasonIfCancelled: order.rejectReason || '',
                        refundEligibility: order.refundEligibility ? 'Yes' : 'No',
                        refundedAmountIfRefunded: order.refundedAmountIfRefunded || '',
                        suggestions: order.specialInstructions,
                        feedback: order.feedback,
                        ratings: order.ratings,
                        orderTotal: order.totalAmount,
                    }));

                    setRestaurantOrderData(formattedRestaurantOrders);
                } catch (error) {
                    console.error("Error fetching restaurant orders:", error);
                }
            };
            getRestaurantOrders();
        }
    }, [tabIndex, location.state._id]);


    const orderColumns = [
        { field: 'siNo', headerName: 'ID', minWidth:10, sortable: false },
        { field: 'orderId', headerName: 'Order ID', minWidth: 200, },
        { field: 'serviceProviderId', headerName: 'Service Provider ID', minWidth: 250, },
        {
            field: 'serviceProviderName', headerName: 'Service Provider Name', minWidth: 250,
            sortable: true,
            renderCell: (params) => {
                const text = params.value || ''; // Ensure value is a string
                return renderCellWithMore(text, handleOpenDialog);
            },
        },
        {
            field: 'orderDate', headerName: 'Order Date', minWidth: 250,
            sortable: true,
            renderCell: (params) => {
                const text = params.value || ''; // Ensure value is a string
                return renderCellWithMore(text, handleOpenDialog);
            },
        },
        {
            field: 'deliveryAddress', headerName: 'Delivery Address', minWidth: 250,
            sortable: true,
            renderCell: (params) => {
                const text = params.value || ''; // Ensure value is a string
                return renderCellWithMore(text, handleOpenDialog);
            },
        },
        {
            field: 'pickupAddress', headerName: 'Pickup Address', minWidth: 250,
            sortable: true,
            renderCell: (params) => {
                const text = params.value || ''; // Ensure value is a string
                return renderCellWithMore(text, handleOpenDialog);
            },
        },
        {
            field: 'itemsOrdered', headerName: 'Items Ordered', minWidth: 250,
            sortable: true,
            renderCell: (params) => {
                const text = params.value || ''; // Ensure value is a string
                return renderCellWithMore(text, handleOpenDialog);
            },
        },
        { field: 'paymentMethod', headerName: 'Payment Method',  minWidth:200, },
        { field: 'paymentStatus', headerName: 'Payment Status',  minWidth:200, },
        { field: 'discountObtained', headerName: 'Discount Obtained',  minWidth:250, },
        { field: 'couponUsed', headerName: 'Coupon Used',  minWidth:250, },
        { field: 'amountDiscounted', headerName: 'Amount Discounted',  minWidth:250, },
        { field: 'orderStatus', headerName: 'Order Status',  minWidth:200, },
        { field: 'reasonIfCancelled', headerName: 'Reason If Cancelled',  minWidth:250, },
        { field: 'refundEligibility', headerName: 'Refund Eligibility',  minWidth:200, },
        { field: 'refundedAmountIfRefunded', headerName: 'Refunded Amount If Refunded',  minWidth:300, },
        { field: 'suggestions', headerName: 'Suggestions',  minWidth:200, },
        { field: 'feedback', headerName: 'Feedback',  minWidth:200, },
        { field: 'ratings', headerName: 'Ratings',  minWidth:200, },
        { field: 'orderTotal', headerName: 'Order Total',  minWidth:200, },
    ];

    return (
        <div style={{ backgroundColor: '#f2f2f2', minHeight: '100vh', minWidth: '100vw' }}>
            <AdminDrawer>
                <Box sx={{ padding: { xs: '8px', sm: '16px' } }}>
                    <Typography variant='h5' sx={{ fontWeight: 'bold', color: '#333', mb: 4 }}>
                        {customerDetails.fullName}
                    </Typography>

                    <Tabs
                        value={tabIndex}
                        onChange={handleTabChange}
                        aria-label="User detail tabs"
                        sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                            '.MuiTab-root': {
                                textTransform: 'none',
                                minWidth: '100px',
                                fontWeight: 'bold',
                                borderRadius: '4px',
                                padding: '12px',
                            },
                            '.Mui-selected': {
                                backgroundColor: '#cbf2f7',
                                color: '#000',
                                fontWeight: 'bold',
                            },
                        }}
                    >
                        <Tab label="Profile" />
                        <Tab label="Caterer Orders" />
                        <Tab label="Restaurant Orders" />
                    </Tabs>

                    <TabPanel value={tabIndex} index={0}>
                        <Paper
                            elevation={3}
                            sx={{
                                padding: { xs: '16px', sm: '32px' },
                                backgroundColor: '#f9f9ff',
                                borderRadius: '12px',
                                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
                                maxWidth: '100%',
                                overflowX: 'auto'
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: { xs: 'column', sm: 'row' },
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                    gap: '24px'
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: { xs: 'column', sm: 'row' },
                                        alignItems: 'center',
                                        gap: '24px'
                                    }}
                                >
                                    <img
                                        src="https://via.placeholder.com/100"
                                        alt="User avatar"
                                        style={{
                                            borderRadius: '50%',
                                            width: '120px',
                                            height: '120px',
                                            objectFit: 'cover',
                                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)'
                                        }}
                                    />
                                    <Box>
                                        <Typography variant='h5' sx={{ fontWeight: 'bold', color: '#333', mb: 4 }}>
                                            Username: {customerDetails.fullName}
                                        </Typography>
                                        <Typography variant='body1' sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', mb: 2 }}>
                                            Phone: <Typography variant='body2' sx={{ color: '#777', ml: 1 }}>{customerDetails.phoneNumber}</Typography>
                                        </Typography>
                                        <Typography variant='body1' sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', mb: 2 }}>
                                            Registered At: <Typography variant='body2' sx={{ color: '#777', ml: 1 }}>{customerDetails.createdAt}</Typography>
                                        </Typography>
                                        <Typography variant='body1' sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', mb: 2 }}>
                                            Email: <Typography variant='body1' sx={{ color: '#555', ml: 1 }}>{customerDetails.email}</Typography>
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Paper>
                        <Box>
                            <Typography variant='h6' mt={2} gutterBottom fontWeight='bold'>Saved Addresses</Typography>
                            <Grid container spacing={2} >
                                {customerDetails?.addresses?.length > 0 ? (
                                    customerDetails.addresses.map((address) => (
                                        <Grid item xs={12} sm={6} md={4} key={address._id}>
                                            <Card sx={{
                                                padding: 2, height: '100%', backgroundColor: '#f9f9ff',
                                                borderRadius: '12px',
                                                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
                                            }}>
                                                <CardContent>
                                                    <Typography variant="h6" fontWeight="bold">
                                                        Address Type: {address.type}
                                                    </Typography>
                                                    <Typography variant="body1" paragraph>
                                                        {address.address1} {address.address2 && `, ${address.address2}`}
                                                    </Typography>
                                                    <Typography variant="body1">
                                                        {address.city}, {address.state}, {address.postalCode}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    ))
                                ) : (
                                    <Grid item xs={12}>
                                        <Typography>No addresses available</Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                    </TabPanel>

                    <TabPanel value={tabIndex} index={1}>
                        <Paper elevation={0} sx={{ padding: '24px', backgroundColor: '#fff' }}>
                            <Container sx={{ minWidth: '100%' }}>
                                <Typography variant='h6' fontWeight='bold' sx={{ marginBottom: '16px' }}>
                                    Caterer Order History
                                </Typography>
                                <PayoutTable
                                    rows={catererOrderData}
                                    columns={orderColumns}
                                    pageSize={5}
                                    rowsPerPageOptions={[5]}
                                    disableColumnSelector
                                    disableSelectionOnClick
                                />
                            </Container>
                        </Paper>
                    </TabPanel>

                    <TabPanel value={tabIndex} index={2} sx={{ padding: '12px' }}>
                        <Paper elevation={0} sx={{ padding: '24px', backgroundColor: '#fff' }}>
                            <Container sx={{ minWidth: '100%' }}>
                                <Typography variant='h6' fontWeight='bold' sx={{ marginBottom: '8px' }}>
                                    Restaurant Order History
                                </Typography>
                                <PayoutTable
                                    rows={restaurantOrderData}
                                    columns={orderColumns}
                                />
                            </Container>
                        </Paper>
                    </TabPanel>

                </Box>
                <Dialog open={dialogOpen} onClose={handleCloseDialog}>
                    <DialogTitle>Details</DialogTitle>
                    <DialogContent>
                        <Typography>{dialogContent}</Typography>
                    </DialogContent>
                    <Button onClick={handleCloseDialog} color="primary">Close</Button>
                </Dialog>
            </AdminDrawer>
        </div>
    );
};

export default UserDetailsPage;
