import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Paper, Grid, Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import AdminDrawer from '../../../components/AdminDrawer/AdminDrawer';
import { createSubPackage, getSubPackageById, updateSubPackage } from '../../../api/catererPackages/catererSubPackages';
import { useNavigate, useParams } from 'react-router-dom';
import CustomSnackbar from '../../../components/CustomeSnackBar/CustomSnackbar';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const allocationOptions = [
    { title: 'General' },
    { title: 'Premium' },
];

const typeOptions = [{ title: "Veg" }, { title: "Non-Veg" }];


const AddSubPackages = () => {
    const [subPackageName, setSubPackageName] = useState('');
    const [allocation, setAllocation] = useState([]);
    const [images, setImages] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [selectedType, setSelectedType] = useState(null);

    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            const fetchSubPackageDetails = async () => {
                try {
                    const data = await getSubPackageById(id);
                    setSubPackageName(data.subPackageName);
                    setAllocation(data.allocatedTo.map(item => ({ title: item })));
                } catch (error) {
                    setSnackbarSeverity('error');
                    setSnackbarMessage('Failed to fetch subpackage details');
                    setSnackbarOpen(true);
                }
            };
            fetchSubPackageDetails();
        }
    }, [id]);

    const handleSubPackageNameChange = (event) => {
        setSubPackageName(event.target.value);
    };

    const handleAllocationChange = (event, newValue) => {
        setAllocation(newValue);
    };

    const handleImageUpload = (event) => {
        const files = Array.from(event.target.files);
        if (files.length + images.length > 5) {
            setSnackbarSeverity('error');
            setSnackbarMessage('You can upload a maximum of 5 images.');
            setSnackbarOpen(true);
            return;
        }
        setImages([...images, ...files]);
    };

    const handleSubmit = async () => {
        try {
            if (id) {
                await updateSubPackage(id, { subPackageName, allocation,selectedType, images });
                setSnackbarSeverity('success');
                setSnackbarMessage('Subpackage updated successfully!');
            } else {
                await createSubPackage({ subPackageName, allocation,selectedType, images });
                setSnackbarSeverity('success');
                setSnackbarMessage('Subpackage created successfully!');
            }
            setSnackbarOpen(true);
            setTimeout(() => navigate(-1), 500); // Navigate after a short delay
        } catch (error) {
            setSnackbarSeverity('error');
            setSnackbarMessage(`Failed to ${id ? 'update' : 'create'} subpackage. Please try again.`);
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <div style={{ backgroundColor: '#f2f2f2', padding: '20px', display: 'flex', flexDirection: 'column' }}>
            <AdminDrawer>
                <Paper
                    elevation={0}
                    sx={{
                        margin: '0 auto',
                        padding: '20px',
                        backgroundColor: 'white',
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Typography variant="h5" fontWeight="bold" gutterBottom mb={3}>
                        {id ? 'Edit SubPackages' : 'Add SubPackages'}
                    </Typography>

                    <div>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Typography component="legend">Subpackage Name</Typography>
                                <TextField
                                    fullWidth
                                    placeholder="Enter Subpackage Name Here"
                                    name="subPackageName"
                                    variant="outlined"
                                    size="small"
                                    value={subPackageName}
                                    onChange={handleSubPackageNameChange}
                                    sx={{ marginBottom: 2, marginTop: 1 }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography component="legend" sx={{ marginBottom: 1 }}>
                                    Allocation Type
                                </Typography>
                                <Autocomplete
                                    size="small"
                                    multiple
                                    id="allocation"
                                    options={allocationOptions}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.title}
                                    value={allocation}
                                    onChange={handleAllocationChange}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.title}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} placeholder="Select Allocations" />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography component="legend">Type</Typography>
                            <Autocomplete
                                options={typeOptions}
                                getOptionLabel={(option) => option.title}
                                value={selectedType}
                                onChange={(event, newValue) => setSelectedType(newValue)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        size="small"
                                        placeholder="Select Type"
                                    />
                                )}
                                sx={{ marginBottom: 2, marginTop: 1 }}
                            />
                        </Grid>

                        <Grid container spacing={2} mb={2}>
                            <Grid item xs={12}>
                                <Typography component="legend" sx={{ marginBottom: 1.5 }}>
                                    Upload Images (Max 5)
                                </Typography>
                                <TextField
                                    fullWidth
                                    name="uploadImages"
                                    variant="outlined"
                                    type="file"
                                    inputProps={{ multiple: true }}
                                    onChange={handleImageUpload}
                                    size="small"
                                    sx={{ marginBottom: 2, marginTop: 1 }}
                                    InputLabelProps={{ shrink: true }}
                                />
                                {images.length > 0 && (
                                    <Box mt={2}>
                                        {images.map((image, index) => (
                                            <Typography key={index}>{image.name}</Typography>
                                        ))}
                                    </Box>
                                )}
                            </Grid>
                        </Grid>

                        <Box display="flex" justifyContent="flex-end" mt={4}>
                            <Button
                                variant="contained"
                                color="success"
                                sx={{ width: '200px', padding: '10px' }}
                                onClick={handleSubmit}
                            >
                                {id ? 'Update' : 'Submit'}
                            </Button>
                        </Box>
                    </div>
                </Paper>
            </AdminDrawer>

            <CustomSnackbar
                open={snackbarOpen}
                message={snackbarMessage}
                severity={snackbarSeverity}
                onClose={handleSnackbarClose}
            />
        </div>
    );
};

export default AddSubPackages;
